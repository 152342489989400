import { Link } from "react-router-dom";
import closeIcon from "../../../assets/icons/close-icon.svg";
import trandingIcon from "../../../assets/icons/tranding-icon.svg";
import { PATH_LIST } from "../../../constants/paths";
const UpdateMessage = ({setUpdateMessage}) => {
    return (
        <>
        <div className="container">
            <div className="inner_wrap">
                <div className="messages-banner">
                    <div className="icon">
                        <img src={trandingIcon} alt="" />
                    </div>
                    <p>
                        ¡Nueva actualización disponible! <Link to={PATH_LIST.USER.UPDATES} className="highlight">Haz clic aquí</Link> para enterarte de
                        lo nuevo
                    </p>
                </div>
                <div className="btn_close">
                    <a href="#0" onClick={()=> setUpdateMessage(false)}>
                        <img className="cancel" src={closeIcon} alt=""/>
                    </a>
                </div>
            </div>
        </div>
        </>
    );
};
export default UpdateMessage;
