import { I18nextProvider } from 'react-i18next';
import RouteList from './routes/RoutesList';
import { Provider } from 'react-redux';
import { Store } from './store';
import i18n from './i18n';
import './styles/App.css';

function App() {
    return (
        <>
            <I18nextProvider i18n={i18n}>
                <Provider store={Store}>
                    <RouteList />
                </Provider>
            </I18nextProvider>
        </>
    );
}

export default App;
