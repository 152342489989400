import { Auth, FacebookProvider, GoogleProvider } from "../config/firebase";
import { ShowNotification } from ".";
import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import { PATH_LIST } from "../constants/paths";

export const StoreFirebaseUserData = async (
    user,
    msg,
) => {
    try {
        localStorage.setItem("Token", user?.accessToken);
        ShowNotification({ message: msg, type: "success" });
    } catch (error) {
        ShowNotification({
            message: "Error storing user data: " + error.message,
            type: "error",
        });
    }
};

const SendEmailVerify = (userCredential, navigate) => {
    sendEmailVerification(userCredential.user);
    ShowNotification({
        message:
            "El mensaje de verificación ha sido enviado. Por favor revisa tu bandeja de entrada y sigue las instrucciones.",
        type: "success",
    });
    navigate(PATH_LIST.USER.VERIFY_EMAIL);
};

export const OnAuthSubmit = async (
    action,
    setIsLoading,
    data,
    dispatch,
    navigate
) => {
    setIsLoading(true);
    try {
        switch (action) {
            case "login":
                const res = await signInWithEmailAndPassword(
                    Auth,
                    data.email,
                    data.password
                );
                StoreFirebaseUserData(
                    res.user,
                    "¡Inicié sesión exitosamente!",
                    {},
                    dispatch,
                );
                navigate(PATH_LIST.USER.ONBOARD);

                break;
            case "signUp":
                const userCredential = await createUserWithEmailAndPassword(
                    Auth,
                    data.email,
                    data.password
                );
                StoreFirebaseUserData(
                    userCredential.user,
                    "¡Su cuenta ha sido creada con éxito!",
                    data,
                    dispatch,
                );
                SendEmailVerify(userCredential, navigate);
                break;
            default:
                return;
        }
        setIsLoading(false);
    } catch (error) {
        setIsLoading(false);
        if (error.code === "auth/user-not-found") {
            ShowNotification({
                message: "Correo electrónico no registrado!",
                type: "error",
                duration: 2,
            });
        } else if (error.code === "auth/wrong-password") {
            ShowNotification({
                message: "¡Contraseña incorrecta!",
                type: "error",
                duration: 2,
            });
        } else if (error.code === "auth/email-already-in-use") {
            ShowNotification({
                message: "¡Ya existe una cuenta con este correo electrónico!",
                type: "error",
            });
        }
    }
};

export const SignInMethods = async (action, loading, dispatch, navigate) => {
    loading(true);
    try {
        let res;
        switch (action) {
            case "google":
                res = await signInWithPopup(Auth, GoogleProvider);
                break;
            case "facebook":
                res = await signInWithPopup(Auth, FacebookProvider);
                break;
            default:
                return;
        }

        if (res) {
            StoreFirebaseUserData(
                res.user,
                "¡Inicié sesión exitosamente!",
                {},
                dispatch,
            );
            navigate(PATH_LIST.USER.ONBOARD);
        }
    } catch (error) {
        loading(false);
        console.error("Error during sign-in:", error);
    }
};
