import check from "../../../assets/icons/right-icon.svg";
import { EllipsisOutlined } from "@ant-design/icons";
import starIcon from "../../../assets/icons/star-icon.svg";
import like_cyan from "../../../assets/icons/like-cyan.svg";
import dislike_red from "../../../assets/icons/dislike-red.svg";
import dislike_cyan from "../../../assets/icons/dislike-cyan.svg";
import { dislike_gray_outline, like_gray_outline } from "../../../assets";
import { Link } from "react-router-dom";

const EvaluationCard = ({ key, data }) => {
    return (
        <div
            className="list_details_card"
            key={key}>
            <div className="top_bar">
                <div className="user_info">
                    <div className="name">
                        <h4 className="title">{data?.fullUserName}</h4>
                        <div className="verify">
                            <img
                                src={check}
                                alt="verified"
                            />
                        </div>
                    </div>
                    <div className="course">
                        <p>Curso: {data?.course}</p>
                    </div>
                    <div className="date">
                        <p>{data?.datetime}</p>
                    </div>
                </div>

                <div className="card_widget">
                    <div className="rating_wrap">
                        <span className="rat_box">{data?.score}</span>
                        <span className="star_box">
                            <img
                                src={starIcon}
                                alt=""
                            />
                        </span>
                    </div>

                    <div className="more_option_wrap">
                        <EllipsisOutlined style={{ fontSize: 25 }} />
                    </div>
                </div>
            </div>
            <div className="card_body">
                <div className="professor_labels">
                    <div className="content">
                        {data?.labels?.map((data, index) => {
                            return (
                                <p
                                    className={`label ${data?.category === "Negative" ? "negative" : "positive"}`}
                                    key={index}>
                                    {data?.label}
                                </p>
                            );
                        })}
                    </div>
                </div>
                <div className="desc">
                    <p>{data?.opinion}</p>
                </div>
                <div className="body_widget">
                    <div className="more_less">
                        <Link>Leer más</Link>
                    </div>
                    <div className="like_dislike">
                        {data?.reactions?.map((reaction, index) => {
                            return (
                                <div
                                    className="like_box"
                                    key={index}>
                                    <div className="icon">
                                        <img
                                            src={reaction?.type === "like" ? like_cyan : dislike_cyan}
                                            alt="Like"
                                        />
                                    </div>
                                    <p className="number">{reaction?.count}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="bottom_bar">
                <Link>
                    <div className="icon">{like_gray_outline}</div>
                    <span className="text">De acuerdo</span>
                </Link>
                <Link>
                    <div className="icon">{dislike_gray_outline}</div>
                    <span className="text">En desacuerdo</span>
                </Link>
            </div>
        </div>
    );
};

export default EvaluationCard;
