import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserAbout, getUserEvaluation } from "../../store/slices/userSlice";
import check from "../../assets/icons/right-icon.svg";
import OnlyBar from "../../components/Common/ProgressBar/OnlyBar";
import { Button, Dropdown, Menu } from "antd";
import { dropdown_arrow } from "../../assets";
import EvaluationCard from "../../components/Common/EvaluationCard";

const User = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { aboutUser, userEvaluation } = useSelector((state) => state.user);

    const [selectedKey, setSelectedKey] = useState("relevantes");

    const selectedTitle = {
        relevantes: "Más relevantes",
        antiguos: "Más antiguos primero",
        recientes: "Más recientes primero",
        alta: "Calificación alta primero",
        baja: "Calificación baja primero",
        alfabetico: "Orden alfabético",
    }[selectedKey];

    const handleMenuClick = ({ key }) => {
        setSelectedKey(key);
    };

    const menu = (
        <Menu
            className="btn_dropdown"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}>
            <Menu.Item key="relevantes">Más relevantes</Menu.Item>
            <Menu.Item key="antiguos">Más antiguos primero</Menu.Item>
            <Menu.Item key="recientes">Más recientes primero</Menu.Item>
            <Menu.Item key="alta">Calificación alta primero</Menu.Item>
            <Menu.Item key="baja">Calificación baja primero</Menu.Item>
            <Menu.Item key="alfabetico">Orden alfabético</Menu.Item>
        </Menu>
    );

    useEffect(() => {
        dispatch(getUserAbout({ id: id }));
        dispatch(getUserEvaluation({ id: id }));
    }, []);

    return (
        <>
            <section className="top_semi_banner_sec"></section>
            <section className="user_prifile_sec">
                <div className="container md_container">
                    <div className="inner_wrap user_page">
                        <div className="user_profile_wrapper">
                            <div className="user_card">
                                <div className="my_info">
                                    <div className="details">
                                        <div className="my_pic">
                                            <img
                                                src={aboutUser?.image}
                                                alt="my-Pic"
                                            />
                                        </div>
                                    </div>
                                    <div className="name">
                                        <h4 className="title">{aboutUser?.fullName}</h4>
                                        <div className="verify">
                                            <img
                                                src={check}
                                                alt="verified"
                                            />
                                        </div>
                                    </div>

                                    <div className="short_name">
                                        <h6 className="title">{aboutUser?.userName}</h6>
                                    </div>
                                </div>

                                <div className="about_me">
                                    <div className="tab_badge">
                                        <p className="user_badge">Usuaio</p>
                                    </div>
                                    <div className="profession">
                                        <h6>{aboutUser?.description}</h6>
                                        <p>{aboutUser?.detailDescription}</p>
                                    </div>
                                </div>

                                {/* <div className="professor_labels">
                                    {aboutUser?.labels?.map((data, index) => {
                                        return (
                                            <p
                                                className={`label ${data?.category === "NEGATIVE" ? "negative" : "positive"}`}
                                                key={index}>
                                                {data?.name}
                                            </p>
                                        );
                                    })}
                                </div> */}

                                <div className="evaluations_wrap">
                                    <div className="evaluations_box">
                                        <div className="evaluations_head">
                                            <p className="number">{aboutUser?.evaluations?.total}</p>
                                            <h4 className="title">Evaluaciones</h4>
                                        </div>
                                        <OnlyBar />
                                    </div>
                                    <div className="approvals_list_wrap">
                                        <div className="approvals_list">
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={like_cyan}
                                                            alt="Like"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutUser?.reactions?.approval || 0}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">Aprobaciones</p>
                                                </div>
                                            </div>
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutUser?.reactions?.disapproval || 0}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">Desaprobaciones</p>
                                                </div>
                                            </div>
                                            {/* <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        />
                                                    </div>
                                                    <h3 className="title">{aboutUser?.detail?.opinions || 0}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title"> Opiniones</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="bottom_row">
                                    <small>Fecha de registro {aboutUser?.registrationDate}</small>
                                </div>
                            </div>
                        </div>

                        <div className="user_profile_listing">
                            <div className="professor-evaluation">
                                <div className="list_option_wrap">
                                    <div className="desc">
                                        <h3 className="title">Evaluaciones</h3>
                                    </div>
                                    <div className="drop_box">
                                        <div className="dropdown_box">
                                            <Dropdown overlay={menu}>
                                                <Button>
                                                    {selectedTitle}
                                                    <i className="fas fa-caret-down" />
                                                    <div className="icon_box FaAngleDown">{dropdown_arrow}</div>
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="list_details_wrapper">
                                    {userEvaluation?.evaluations?.map((data, index) => {
                                        return (
                                            <EvaluationCard
                                                key={index}
                                                data={data}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default User;
