import { sendEmailVerification } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form } from 'antd';
import { Auth } from '../../../config/firebase';
import infoIcon from '../../../assets/icons/info-icon.svg'
import { useTranslation } from 'react-i18next';
import AuthLeft from '../../../components/Common/AuthLeft';
import { PATH_LIST } from '../../../constants/paths';
import { BackButton } from '../../../components/Button';
import BenifitesModal from '../../../components/Modals/BenefitsModal';

const VerifyEmail = () => {
    const [countdown, setCountdown] = useState(600);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [benifitModal, setBenifitModal] = useState(false)
    const navigate = useNavigate()
    const { t: commonT } = useTranslation('common'); 
    const { t: verifyEmailT } = useTranslation('verifyEmail'); 

    useEffect(() => {
        let interval;
        if (countdown > 0 && isResendDisabled) {
            interval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        } else {
            setIsResendDisabled(false);
        }

        return () => clearInterval(interval);
    }, [countdown, isResendDisabled]);

    const handleResendEmail = async () => {
        setIsResendDisabled(true);
        try {
            await sendEmailVerification(Auth.currentUser);
            setCountdown(600);
        } catch (error) {
        }
    };

    return (
        <>
            <div className="auth-section verify-email-page">
                <AuthLeft />
                <div className="auth-right">
                    {
                        Auth.currentUser ?
                            Auth?.currentUser?.emailVerified ?
                                <div className='fixed-width'>
                                    <div className='header'>
                                        <h1>
                                            {verifyEmailT('EMAIL_VERIFIED')}
                                        </h1>
                                    </div>
                                    <h4>{verifyEmailT('MEMBER_MESSAGE')}</h4>

                                    <p>
                                        {verifyEmailT('COMPLETE_PROFILE_MESSAGE')}
                                        <span className="icon" onClick={() => setBenifitModal(true)}>
                                            <img src={infoIcon} alt="" />
                                        </span>
                                    </p>
                                    <div className="form">
                                        <div className="action-button">
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={() => navigate(PATH_LIST.USER.LOGIN)}
                                                >
                                                    {commonT('CONTINUE')}
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='fixed-width'>
                                    <BackButton route={PATH_LIST.USER.LOGIN} />
                                    <div className="header">
                                        <h1>{verifyEmailT('CHECK_EMAIL')}</h1>
                                    </div>
                                    <div className="message">
                                        <p>{verifyEmailT('VERIFY_EMAIL_MESSAGE')} {Auth?.currentUser?.email}</p>
                                        <span>{verifyEmailT('NOT_RECEIVED_EMAIL')}</span>
                                    </div>

                                    <div className="form">
                                        <div className="action-field">
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={() => handleResendEmail()}
                                                    disabled={isResendDisabled}
                                                >
                                                    {
                                                        isResendDisabled ?
                                                            `${verifyEmailT('TRY_AGAIN_IN')} ${Math.floor(countdown / 60)}:${countdown % 60} min`
                                                            :
                                                            commonT('SEND_AGAIN')
                                                    }
                                                </Button>
                                            </Form.Item>
                                        </div>
                                        <div className='term-condition'>
                                            <span>{verifyEmailT('YOU_CAN_ENTER.TO')} <Link className='info' to={PATH_LIST.USER.LOGIN} >{verifyEmailT('YOU_CAN_ENTER.WITH_OUT')}</Link> {verifyEmailT('YOU_CAN_ENTER.WITH_LIMIT_ACCESS')}</span>
                                        </div>
                                    </div>
                                </div>
                            :
                            <>
                                <div className="header">
                                    <h1>{verifyEmailT('REGISTER_FIRST')}</h1>
                                </div>
                                <div className="form">
                                    <div className="action-button">
                                        <Button onClick={() => navigate(PATH_LIST.USER.REGISTER)} type='primary'>
                                            {commonT('REGISTER')}
                                        </Button>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            {
                benifitModal &&
                <BenifitesModal
                    closeModal={benifitModal}
                    setCloseModal={setBenifitModal}
                />
            }
        </>
    );
}

export default VerifyEmail;
