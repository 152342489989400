import React from "react";
import ProgressBar from "../../../Common/ProgressBar";
import { eduBook, prev_arrow } from "../../../../assets";

const TopBar = ({setCancelEvolution}) => {
	return (
		<div className="top_bar_modal">
			<div className="top_bar_left">
				<div
					className="btn_back"
					onClick={() => setCancelEvolution(true)}>
					<div className="icon">{prev_arrow}</div>
					<p className="text">Salir</p>
				</div>

				<div className="select_perf_info_wrap hide_in_desktop">
					<p className="select_title">Nueva evaluación</p>
					<p className="sec_title">
						<strong>Confirma tu evaluación</strong>
					</p>
					<div className="select_perf_info">
						{/* <dov className="select_item select_profession">
                                        <div className="icon">{degree_hat}</div>
                                        <p className="select_txt">
                                            Médico y cirujano{" "}
                                            <strong>USAC</strong>
                                        </p>
                                    </dov> */}

						<div class="select_item">
							<div class="user_info">
								<span class="profile_icon">MM</span>
							</div>
							<p className="select_txt">
								<strong>Mario Menéndez</strong>
							</p>
						</div>
						<div className="select_item select_prof_name">
							<div className="icon">{eduBook}</div>
							<p className="select_txt">
								<strong>Matemática aplicada</strong>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="top_bar_r_col hide_in_desktop">
				<div className="top_bar_right hide_in_desktop">
					<ProgressBar />
				</div>
			</div>

			<div className="top_bar_right hide_in_tab">
				<ProgressBar />
			</div>
		</div>
	);
};

export default TopBar;
