import React, { useState } from 'react'
import { Button, Form, Input, notification } from 'antd';
import { sendPasswordResetEmail } from 'firebase/auth'
import { Auth } from '../../../config/firebase';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PATH_LIST } from '../../../constants/paths';
import { forgotPassword } from '../../../store/slices/authSlice';
import AuthLeft from '../../../components/Common/AuthLeft';
import { BackButton } from '../../../components/Button';

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailSend, setIsEmailSend] = useState(false)
    const dispatch =  useDispatch();
    const { t: commonT } = useTranslation('common'); 
    const { t: forgotPasswordT } = useTranslation('forgotPassword'); 


    const handleSendEmail = () => {
        setIsLoading(true);
        sendPasswordResetEmail(Auth, email)
        .then(() => {
            dispatch(forgotPassword(email))
            setIsEmailSend(true)
            setIsLoading(false);
            notification.success({ description: "¡Revise su correo electrónico para obtener un enlace de reinicio!" });
        })
        .catch((error) => {
            setIsLoading(false);
            notification.error({ description: error.message });
        });

    }

    return (
        <div className="auth-section forgot-password-page">
            <AuthLeft />
            <div className="auth-right">
                <div className='fixed-width'>
                    <BackButton route={PATH_LIST.USER.LOGIN} />
                    <div className="header">
                        {
                            !isEmailSend ?
                                <h1>{forgotPasswordT('FORGOT_PASSWORD_QUESTION')}</h1>
                                :
                                <h1>{forgotPasswordT('CHECK_EMAIL')}</h1>
                        }
                    </div>
                    <div className="message">
                        {
                            !isEmailSend ?
                                <p>{forgotPasswordT('ENTER_EMAIL_MESSAGE')}</p>
                                :
                                <p>{forgotPasswordT('EMAIL_SENT')}</p>
                        }
                    </div>
                </div>
                <div className="form">
                    <Form className="auth-form" layout="vertical" name="register-form" onFinish={handleSendEmail}>
                        <div className="field">
                            {
                                !isEmailSend ?
                                    <Form.Item
                                        label={commonT('EMAIL')}
                                        name='email'
                                        rules={[{ required: true, message: '¡correo electronico es requerido!' }, { type: "email", message : '¡El ID de correo electrónico no es válido!' }]}
                                    >
                                        <Input
                                            type="email"
                                            name='email'
                                            placeholder="tunombre@email.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            autoCapitalize='on'
                                        />
                                    </Form.Item>
                                    :
                                    <p>{forgotPasswordT('EMAIL_NOT_RECEIVED')}</p>
                            }
                        </div>
                        <div className="action-field">
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                >
                                    {
                                        !isEmailSend ?
                                            forgotPasswordT('SEND_INSTRUCTIONS')
                                            :
                                            commonT('SEND_AGAIN')
                                            
                                    }
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword