import { Option } from "antd/es/mentions";
import React, { useState } from "react";
import VerificationModal from "../../../components/Modals/VerificationModal";
import { Col, Collapse, Form, Input, Progress, Radio, Row, Select } from "antd";
import userIcon from '../../../assets/icons/user.svg';
import './style.scss';
import { dropdown_arrow } from "../../../assets";

const progressPercentage = (1 / 3) * 100;

const twoColors = {
  "0%": "#0DC9C2",
  "100%": "#5C30A1 ",
};

const FormFillPage = ({ usedata, setUserdata }) => {

  const [formData, setFormData] = useState({
    day: '',
    month: '',
    year: '',
    gender: '',
    email: '',
    number: ''
  });
  const [modalVisible, setModalVisible] = useState(false);

  const onChange = (key) => {
    console.log(key);
  };

  const generateDayOptions = () => {
    const options = [];
    for (let day = 1; day <= 31; day++) {
      options.push(
        <Option key={day} value={day < 10 ? `0${day}` : `${day}`}>
          {day < 10 ? `0${day}` : `${day}`}
        </Option>
      );
    }
    return options;
  };

  const generateMonthOptions = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return months.map((month, index) => (
      <Option key={month} value={index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}>
        {index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}
      </Option>
    ));
  };


  // const handleInputChange = (field, value) => {
  //   setFormData({
  //     ...formData,
  //     [field]: value,
  //   });
  // };

  const handleInputChange = (field, value) => {
    let updatedData = { ...usedata };
    switch (field) {
      case 'day':
        updatedData['birthday'] = `${value}/${updatedData['birthday'] ? updatedData['birthday'].split('/')[1] : '' || 'MM'}/${updatedData['birthday'] ? updatedData['birthday'].split('/')[2] : '' || 'YYYY'}`;
        break;
      case 'month':
        updatedData['birthday'] = `${updatedData['birthday'] ? updatedData['birthday'].split('/')[0] : '' || 'DD'}/${value}/${updatedData['birthday'] ? updatedData['birthday'].split('/')[2] : '' || 'YYYY'}`;
        break;
      case 'year':
        updatedData['birthday'] = `${updatedData['birthday'] ? updatedData['birthday'].split('/')[0] : '' || 'DD'}/${updatedData['birthday'] ? updatedData['birthday'].split('/')[1] : '' || 'MM'}/${value}`;
        break;
      case 'email':
        updatedData['emailUniversity'] = value;
        break;
      case 'gender':
        updatedData['gender'] = value;
        break;
      case 'number':
        updatedData['phoneNumber'] = value;
        break;
      default:
        break;
    }

    setUserdata(updatedData);
  };


  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];
    for (let year = currentYear; year >= 1900; year--) {
      options.push(
        <Option key={year} value={year}>
          {year}
        </Option>
      );
    }
    return options;
  };

  return (
    <div className="formfill">
      <div className="container">
        <div className="main-formfill">
          <h2>¡Hola, Mario!</h2>

          <Progress percent={progressPercentage} showInfo={false} strokeColor={twoColors} />

          <VerificationModal visible={modalVisible} onClose={() => setModalVisible(false)} />
          <div className="main-title">
            <img src={userIcon} alt="User" />
            Vamos a completar tu perfil
          </div>
          <div>
            <Form layout="vertical">
              <Form.Item label="Fecha de nacimiento">
                <Row gutter={16}>
                  <Col span={8}>
                    <Select
                      suffixIcon={dropdown_arrow}
                      placeholder="Día"
                      onChange={value => handleInputChange('day', value)}
                    >
                      {generateDayOptions()}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Select
                      suffixIcon={dropdown_arrow}
                      placeholder="Mes"
                      onChange={value => handleInputChange('month', value)}
                    >
                      {generateMonthOptions()}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Select
                      suffixIcon={dropdown_arrow}
                      placeholder="Año"
                      onChange={value => handleInputChange('year', value)}
                    >
                      {generateYearOptions()}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Sexo" className="radio-custm">
                <Radio.Group onChange={e => handleInputChange('gender', e.target.value)}>
                  <Radio value="male">Masculino</Radio>
                  <Radio value="female">Femenino</Radio>
                  <Radio value="other">Prefiero no especificar</Radio>
                </Radio.Group>
              </Form.Item>
              <div className='mail-content'>
                <p className='main-content'>Correo Universitario</p>
                <p className='sub-content'>Ingresa tu correo universitario para aumentar tu
                  <span onClick={() => setModalVisible(true)} style={{ textDecoration: 'underline' }}>nivel de verificación</span>
                </p>
                <Input type="email" placeholder="Correo universitario (opcional)" onChange={e => handleInputChange('email', e.target.value)} />
              </div>
              <div className='collapse-panel-main'>
                <Collapse defaultActiveKey={[]} onChange={onChange} expandIconPosition="right">
                  <Collapse.Panel key="1" header="¿No tienes correo universitario?">
                    <p>Número de teléfono</p>
                    <p>Ingresar este dato aumenta tu
                      <span onClick={() => setModalVisible(true)} style={{ textDecoration: 'underline' }} > nivel de verificación</span>
                    </p>

                    <div className="field">
                      <Form.Item name="number">
                        <Input
                          addonBefore="+502"
                          type="text"
                          name="number"
                          maxLength={10}
                          value={formData.number}
                          placeholder="Teléfono (Opcional)"
                        // onChange={(e) => {
                        // 	const numericValue = e.target.value.replace(/\D/g, "");
                        // 	Form.setFieldsValue({number: numericValue,});
                        // 	handleFormChange(numericValue, "number");
                        // }}
                        />
                      </Form.Item>
                    </div>

                    {/* <Row gutter={8} align="middle">
            <Col>
              <Input value="+91" disabled style={{ width: 70 }} />
            </Col>
            <Col flex="auto">
              <Input
                placeholder="Enter mobile number"
                onChange={(e) => handleInputChange('number', e.target.value)}
                style={{ borderLeft: 0 }}
              />
            </Col>
          </Row> */}
                  </Collapse.Panel>
                </Collapse>
              </div>
            </Form>

          </div>
        </div>

      </div>
    </div>
  );
};


export default FormFillPage;