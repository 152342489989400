import { Button, Input, Select } from "antd";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Card from "../../components/Cards";
import "./style.scss";
import AddCareerModel from "../../components/Modals/AddCareerModal";
import factor1 from "../../assets/images/facror-1.png";
import factor2 from "../../assets/images/facror-2.png";
import factor3 from "../../assets/images/facror-3.png";
import factor4 from "../../assets/images/facror-4.png";
import factor5 from "../../assets/images/facror-5.png";
import IdCard from "../../components/IdCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";
import { useSelector } from "react-redux";
import SearchPage from "../SearchPage";
import SearchItem from "../../components/Common/SearchItem";

const Home = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { showSearchPage, searchKeyword } = useSelector((state) => state.search);

	const countrySearch = (value) => {
		console.log("countrySearch", value);
	};
	const countyChange = (value) => {
		console.log("countyChange", value);
	};

	return (
		<>
			{showSearchPage ? (
				<SearchPage />
			) : (
				<>
					<section className="after_banner bg_off_white">
						<div className="container">
							<div className="sec_head">
								<h1 className="title">Hola, Mario</h1>
								<p>
									<strong> Encuentra a las clases y profesores mejor calificados de la U </strong>
								</p>
							</div>

							<div className="search_row_wrapper">
								<div className="search_row">
									<div className="select_wrap">
										<form>
											<Select
												defaultValue="lucy"
												options={[
													{
														value: "jack",
														label: "Jack",
													},
													{
														value: "lucy",
														label: "Lucy",
													},
													{
														value: "Yiminghe",
														label: "yiminghe",
													},
													{
														value: "disabled",
														label: "Disabled",
														disabled: true,
													},
												]}
											/>
										</form>
									</div>
									<div className="search_box_row">
									<SearchItem />
									</div>
								</div>

								<div className="search_btn_wrap">
									<Button type="primary">Buscar</Button>
								</div>
							</div>
						</div>
					</section>

					<section className="id_card_sec bg_off_white">
						<div className="container container2">
							<div className="sec_head">
								<h2 className="title">
									<span className="highlight2">Top 10</span> profesores{" "}
								</h2>
								<p>
									{" "}
									<strong>de la carrera de Médico Cirujano del último semestre</strong>{" "}
								</p>
							</div>

							<div className="id_card_wrapper">
								<div className="id_card_inner hide_in_mobile">
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
									<IdCard />
								</div>
								<div className="id_card_inner hide_in_desktop">
									<Swiper
										slidesPerView={1}
										grid={{
											rows: 2,
										}}
										modules={[Grid]}>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
										<SwiperSlide>
											<IdCard />
										</SwiperSlide>
									</Swiper>
								</div>
								<div className="btn_wrap">
									<Button
										className="primary_v2"
										type="primary">
										Ver todos
									</Button>
								</div>
							</div>
						</div>
					</section>

					<section className="factor_card grid_sec">
						<div className="container container2">
							<div className="sec_head">
								<p className="sec_title">
									<strong>Busca por factor de evaluación</strong>
								</p>
							</div>

							<div className="factor_card_wrapper">
								<div className="card_inner_wrapper">
									<div className="card">
										<div className="inner_card">
											<div className="img_wrapper">
												<div className="img_wrap">
													<img
														src={factor1}
														alt="FactorBG"
													/>
												</div>
												<div className="caption doodle">
													<p>
														<strong>Calidad de enseñanza</strong>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="inner_card">
											<div className="img_wrapper">
												<div className="img_wrap">
													<img
														src={factor2}
														alt="FactorBG"
													/>
												</div>
												<div className="caption doodle">
													<p>
														<strong>Calidad de enseñanza</strong>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="inner_card">
											<div className="img_wrapper">
												<div className="img_wrap">
													<img
														src={factor3}
														alt="FactorBG"
													/>
												</div>
												<div className="caption doodle">
													<p>
														<strong>Calidad de enseñanza</strong>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="inner_card">
											<div className="img_wrapper">
												<div className="img_wrap">
													<img
														src={factor4}
														alt="FactorBG"
													/>
												</div>
												<div className="caption doodle">
													<p>
														<strong>Calidad de enseñanza</strong>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="inner_card">
											<div className="img_wrapper">
												<div className="img_wrap">
													<img
														src={factor5}
														alt="FactorBG"
													/>
												</div>
												<div className="caption doodle">
													<p>
														<strong>Calidad de enseñanza</strong>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="grid_sec bg_off_white">
						<div className="container container2">
							<div className="sec_head">
								<p className="sec_title">
									<strong>Mejor calificados para clase de anatomía</strong>
								</p>
							</div>

							<div className="id_card_wrapper">
								<div className="id_card_inner">
									<div className="card_inner_wrapper no_effect">
										<Card />
										<Card />
										<Card />
										<Card />
										<Card />
										<Card />
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="grid_sec bg_off_white Best qualified_class_grid">
						<div className="container container2">
							<div className="sec_head">
								<p className="sec_title">
									<strong>Mejor calificados para clase 2</strong>
								</p>
							</div>

							<div className="id_card_wrapper">
								<div className="id_card_inner">
									<div className="card_inner_wrapper no_effect">
										<Card />
										<Card />
										<Card />
										<Card />
										<Card />
										<Card />
									</div>
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</>
	);
};
export default Home;
