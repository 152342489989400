import { Progress } from 'antd'
import React from 'react'

const twoColors = {
  "0%": "#0DC9C2",
  "100%": "#5C30A1 ",
};

function OnlyBar() {
  return (
    <div className="progress_bar_wrap">
      <Progress
        percent={80}
        strokeColor={twoColors}
        showInfo={false}
      />
    </div>
  )
}

export default OnlyBar;