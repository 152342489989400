import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import executeHttp from "../../services/api";
import { API_URL } from '../../constants/api-url';

const initialState = {
	User: null,
};

export const register = createAsyncThunk(
	"auth/register",
	async (userData) => {
		try {
			const response = await executeHttp({
				method: 'POST',
				endpoint: `${API_URL.USER.register}`,
				data: userData,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	}
);

// Create an async thunk action for user login
export const login = createAsyncThunk(
	"auth/login",
	async (userData) => {
		try {
			const response = await executeHttp({
				method: 'POST',
				endpoint: `${API_URL.USER.login}`,
				data: userData,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	}
);

export const forgotPassword = createAsyncThunk(
	"auth/forgotPassword",
	async (email) => {
		try {
			const response = await executeHttp({
				method: 'POST',
				endpoint: `${API_URL.USER.forgotPassword}`,
				data: email,
			});;
			return response.data;
		} catch (error) {
			throw error;
		}
	}
);

const authSlice = createSlice({
	name: "authSlice",
	initialState,
	reducers: {
		setLoggedInUser: (state, action) => {
			state.User = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(register.fulfilled, (state, action) => {
			state.User = action.payload;
		});
		builder.addCase(register.rejected, (state, action) => {
			console.error("Registration failed:", action.error.message);
		});
		builder.addCase(login.fulfilled, (state, action) => {
			state.User = action.payload;
			localStorage.setItem("Token", action.payload.token);
		});
		builder.addCase(login.rejected, (state, action) => {
			console.error("Login failed:", action.error.message);
		});
		builder.addCase(forgotPassword.fulfilled, (state, action) => {
			// Handle success or state changes for the forgot password action here
		});
		builder.addCase(forgotPassword.rejected, (state, action) => {
			console.error("Forgot password request failed:", action.error.message);
		});
	},
});

export const { setLoggedInUser } = authSlice.actions;
export default authSlice.reducer;
