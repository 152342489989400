import React, { useEffect, useState } from "react";
import FirstEmojiRating from "./FirstEmojiRating";
import SecondEmojiRating from "./SecondEmojiRating";
import ThirdEmojiRating from "./ThirdEmojiRating";
import ForthEmojiRating from "./ForthEmojiRating";
import FifthEmojiRating from "./FifthEmojiRating";
import { useDispatch, useSelector } from "react-redux";
import { getEvolutionFactor } from "../../../../store/slices/evolutionSlice";
import { evolutionFactorsConstants } from "../constant";

const ThirdPhase = ({ setInfoModal, infoCircle, next }) => {
	const dispatch = useDispatch();
	const { evolutionFactors } = useSelector((state) => state?.evolution);
	const factorsToUse = evolutionFactors?.data?.length > 0 ? evolutionFactors : evolutionFactorsConstants;

	const [current, setCurrent] = useState(0);

	const moveToNext = () => {
		setCurrent(current + 1);
	};

	useEffect(() => {
		dispatch(getEvolutionFactor());
	}, []);

	return (
		<div className="playarea_modal que_ans_rating">
			<div className="que_ans_rating_inner">
				{factorsToUse
					.filter((factor) => factor.id !== "6")
					.map((factor, index) => (
						<div
							key={index}
							style={{ display: index <= current ? "block" : "none" }}>
							<FirstEmojiRating
								key={index}
								factor={factor}
								moveToNext={moveToNext}
								next={next}
								setInfoModal={setInfoModal}
								infoCircle={infoCircle}
							/>
						</div>
					))}
			</div>
		</div>
	);
};

export default ThirdPhase;
