export const New_Updates = {
	main_title: "Nuevas actualizaciones",
	_id: "O9EmMileBYeCbBfrTBRWGsfCy2cP",
	data: [
		{
			_id: "7CA0BTvVNGmddR0ZzyZ8gdPYc5Rz",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "btOJ76D0ckoekZfDpDbljYxR1gbI",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "10Pezk7hDubwKRfcx1C6OGhnkua8",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "siSWhje8ZK3ek9MQPW7atAULXdqh",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "DJWVidBghbJWER2rOKkPtCxR7B1v",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "Hb6ANHVSqRggUMZkTt2U9rga1PKw",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "Qih4FzCu6TAIMaOf7DlhscAw58N9",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "QZzUqwDv7XkliqyCTFuIWxydBStN",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "DYaYiLRK1DBBWwaXIiwYNeps5egU",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "ioL6ojwv5Bglqx2GMUYTGqfMFCWQ",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "EWkNsM447XXNBHfmfb9NiA0aYQ4Q",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "usZau6oSQLeY7XoZdPehEdzEaHmC",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
	],
};

export const Saikit_Community = {
	main_title: "Comunidad Saikit",
    _id : "HQbOOnLso3k5gpPu2UFzBsEC8qOi",
	data: [
		{
            _id : "zZLWoOfzL58deqCDwMvo9wd8haDO",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "bdCaf4ntdCoGO5dAuqf22jcKa1W8",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "41nyZgMnNHAy4nk0U0CkV9SqOsJq",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "EbAap7GkGFE0gAEfohj1sX7Y47yp",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "AAdT7mFE8xHWgCt7kh68Gm8aLcjT",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "OrvbNmGlbZjYnwWWvqAMS0Npr2XV",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "U123D2ued8URT6vV7MF0FFMYG22N",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "gndrwah1kGBPLUDyW3Y0XHckwxQn",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "PjcyCaINkjRK6WfQR9sQu3rvgThu",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "aMNSziUNmjbiVIH3apsoT0Wn7DKO",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "6Bysv0EOI09BZ9mT80zhfGGV2YzH",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "gueEeb4ckPKOKr6m5DL8ySZ6KG0Z",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
	],
};

export const Intrested_Updates = {
	main_title: "Podría interesarte",
    _id : "Xzc5D2ZOtLV4DhYofOlwcj0OVNk7",
	data: [
		{
            _id : "T6gqeta23JkUiKgtApzUXzFcTIkK",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "TFUJBxgXLksbofV8CdoCcZmvsj4N",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "1EpOb1OQ2XCnoUs296wWo7WDpkhH",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "tkse6IEoxNu4kB7yP6eGX9FCWc1c",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "uWiqMXnXZt9Ky7Vw1VQA9b2WIg4E",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "APItxj3UPFTpZOowhGnVag3P46em",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "TvDYjWFiAzv6y6WgSewlI7KeJU9a",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "9WDiQkvx805328dHyVYcgyoAuUq5",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "uV0lScTH7Vh6LFGzRZK4FjT0a6Hg",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "KskCwg7sxdnl0tIq8xuGaxBfSaeq",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "EP5hGKDFkGQ99nlybbdhDthM8X3m",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
		{
            _id : "W7tGzEkLlcuvFcJR8K1ycd38VOjV",
			article_title: "Ejemplo de título de artículo largo en dos líneas",
			article_header: "Encabezado de artículo",
			article_data: "",
			author_name: "Nombre de Autor",
			publish_date: "15 mayo 2021",
			author_image: "",
		},
	],
};
