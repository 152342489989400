/* eslint-disable no-useless-escape */
import { notification } from "antd";

//empty fields validation
export function emptyField(text) {
	if ((text && String(text)?.trim() !== "")) {
		return false;
	} else {
		return true;
	}
}

//invalid email validation
export function inValidEmail(text) {
	const regex = RegExp(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
	return !regex.test(text);
}

export const ShowNotification = ({ message, type = 'info', placement = 'top', duration = 3 }) => {
    notification[type]( {message, placement, duration} );
};

export const ValidateMessages = {
	required: '¡Se requiere ${label}!',
	types: {
		email: '¡El ID de correo electrónico no es válido!',
	},
};