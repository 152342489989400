import React, { useState } from 'react';
import { Drawer, Menu } from 'antd';
import HamburgerFooter from '../HamburgerFooter';
import { NavLink } from 'react-router-dom';
import { PATH_LIST } from '../../../constants/paths';
import ContactUsFormModal from '../../Modals/ContactUsFormModal';

const FooterDrawerMenu = ({ open, onClose, setOpen }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCloseModal = () => {
        setOpen(false)
    }

    const handleContactFormOpen = () => {
        setIsModalVisible(true)
    }

    const HamburgerItems = [
        {
            label: <h3>Acerca de</h3>,
            key: "0",
        },
        {
            label: <p><NavLink to={PATH_LIST.USER.COMMON} onClick={handleCloseModal}>¿Qué es Saikit?</NavLink></p>,
            key: "2",
        },
        {
            label: <p><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.ABOUT_US}></NavLink>Nosotros</p>,
            key: "3",
        },
        {
            label: <p><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.METHOLOGY}></NavLink>Metodología</p>,
            key: "4",
        },

        {
            label: <p><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.UPDATES}></NavLink>Anuncios</p>,
            key: "5",
        },
        {
            type: "divider",
        },
        {
            label: <h3>Soporte</h3>,
            key: "6",
            disabled: true,
        },
        {
            label: <p><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.UPDATES}></NavLink>Material para profesores</p>,
            key: "8",
        },
        {
            label: <p><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.FAQ}></NavLink>Preguntas frecuentes</p>,
            key: "9",
        },
        {
            type: "divider",
        },
        {
            label: <h3>Contáctanos</h3>,
            key: "10",
        },
        {
            label: <p onClick={handleContactFormOpen}>Contáctanos</p>,
            key: "12",
        },
        {
            label: <HamburgerFooter />,
            key: "13",
        }
    ];

    return (
        <div>
            <Drawer
                className='drawer_menu_wrapper'
                placement="right"
                closable={true}
                onClose={onClose}
                open={open}
                width={417}
            >
                <div className="menu_list_wrapper">
                    <div className="nav_links_wrappper">
                        <div className="nav_links_wrap">
                            <div className="title">
                                <h3>Acerca de</h3>
                            </div>
                            <ul className="nav_link">
                                <li>
                                    <NavLink to={PATH_LIST.USER.COMMON} onClick={handleCloseModal}>¿Qué es Saikit?</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={handleCloseModal} to={PATH_LIST.USER.ABOUT_US}>Nosotros</NavLink>
                                </li>
                                <li><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.METHOLOGY}>Metodología</NavLink></li>
                                <li><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.UPDATES}>Anuncios</NavLink></li>
                            </ul>
                        </div>
                        
                        <div className="nav_links_wrap">
                            <div className="title">
                                <h3>Soporte</h3>
                            </div>
                            <ul className="nav_link">
                                <li><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.UPDATES}>Material para profesores</NavLink></li>
                                <li><NavLink onClick={handleCloseModal} to={PATH_LIST.USER.FAQ}>Preguntas frecuentes</NavLink></li>
                            </ul>
                        </div>
                        
                        <div className="nav_links_wrap">
                            <div className="title">
                                <h3>Contáctanos</h3>
                            </div>
                            <ul className="nav_link">
                                <li><NavLink className="contact_link" onClick={handleContactFormOpen}>Contáctanos</NavLink></li>
                            </ul>
                        </div>
                    </div>

                    <HamburgerFooter />
                </div>
            </Drawer>
            {isModalVisible && (
                <ContactUsFormModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                />
            )}
        </div>
    );
};

export default FooterDrawerMenu;
