import React, { useState } from "react";
import { Button, Input, Select, Collapse, Progress } from "antd";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { getCareer, getUniversities, userCareer } from "../../../store/slices/onBoardSlice";

const { Panel } = Collapse;

const CareerFormFillPage = ({ userCareerData }) => {
  const progressPercentage = (3 / 3) * 100;
  const [carreras, setCarreras] = useState([]);
  const dispatch = useDispatch();
  const universities = useSelector((state) => state.onboard?.universities) || [];
  const careers = useSelector((state) => state.onboard?.countries) || [];

  const twoColors = {
    "0%": "#0DC9C2",
    "100%": "#5C30A1 ",
  };
  const [carrera, setCarrera] = useState({
    selectValue: '',
    input1: '',
    input2: '',
    selectValue2: ''
  });

  const handleInputChange = (field, value, index) => {
    const updatedCarreras = [...carreras];
    updatedCarreras[index] = {
      ...updatedCarreras[index],
      [field]: value
    };
    setCarreras(updatedCarreras);
  };

  const handleAddCarrera = () => {
    dispatch(userCareer(userCareerData));
    setCarreras([carrera, ...carreras]);
    setCarrera({
      selectValue: '',
      input1: '',
      input2: '',
      selectValue2: ''
    });
  };

  const handleDeleteCarrera = (index) => {
    const updatedCarreras = [...carreras];
    updatedCarreras.splice(index, 1);
    setCarreras(updatedCarreras);
  };

  return (
    <div className="formfill">
      <div className="container">
        <div className="main-formfill">
          <h2>¡Hola, Mario!</h2>
          <Progress percent={progressPercentage} showInfo={false} strokeColor={twoColors} />

          {carreras.map((carreraData, index) => (
            <React.Fragment key={index}>
              <div className="main-title">
                {`Carrera ${carreras.length - index}`}
              </div>
              <Collapse accordion>
                <Panel className="carre_wrap" header={carreraData.input1} key={index}>
                  <p>País en el que estudias</p>
                  <Select
                    value={carreraData.selectValue}
                    onChange={(value) => handleInputChange('selectValue', value, index)}
                  >
                    <Select.Option value="guatemala">Guatemala</Select.Option>
                    <Select.Option value="other">Other</Select.Option>
                  </Select>
                  <p>Universidad</p>
                  <Input
                    value={carreraData.input1}
                    onFocus={() => dispatch(getUniversities())}
                    onChange={(e) => handleInputChange('input1', e.target.value, index)}
                    placeholder="Nombre de tu universidad"
                  />
                  <p>Unidad académica</p>
                  <Input
                    value={carreraData.input2}
                    onChange={(e) => handleInputChange('input2', e.target.value, index)}
                    placeholder="Tu unidad académica, ej. Facultad de Arquitectura"
                  />
                  <p>Carrera</p>
                  <Select
                    onFocus={() => dispatch(getCareer())}
                    value={carreraData.selectValue2}
                    onChange={(value) => handleInputChange('selectValue2', value, index)}
                  >
                    <Select.Option value="defaultSelectValue">Default</Select.Option>
                    <Select.Option value="otherValue">Other</Select.Option>
                  </Select>
                  {/* Move the delete button to the end */}
                  <div className="delete-button">
                    {index !== 0 && (
                      <Button onClick={() => handleDeleteCarrera(index)}>Delete</Button>
                    )}
                  </div>
                </Panel>
              </Collapse>
            </React.Fragment>
          ))}

          <div className="form_data">
            <p>País en el que estudias</p>
            <Select
              value={carrera.selectValue}
              onChange={(value) => setCarrera({ ...carrera, selectValue: value })}
            >
              <Select.Option value="guatemala">Guatemala</Select.Option>
              <Select.Option value="other">Other</Select.Option>
            </Select>
            <p>Universidad</p>
            <Input
              value={carrera.input1}
              onChange={(e) => setCarrera({ ...carrera, input1: e.target.value })}
              placeholder="Nombre de tu universidad"
            />
            <p>Unidad académica</p>
            <Input
              value={carrera.input2}
              onChange={(e) => setCarrera({ ...carrera, input2: e.target.value })}
              placeholder="Tu unidad académica, ej. Facultad de Arquitectura"
            />
            <p>Carrera</p>
            <Select
              value={carrera.selectValue2}
              onChange={(value) => setCarrera({ ...carrera, selectValue2: value })}
            >
              <Select.Option value="defaultSelectValue">Default</Select.Option>
              <Select.Option value="otherValue">Other</Select.Option>
            </Select>
            <div className="custm_agre">
              <Button onClick={handleAddCarrera}>+ Agregar otra carrera</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerFormFillPage;
