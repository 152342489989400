import React from "react";
import "./style.scss";
import MaterialDetails from "./MaterialDetails";
import { Button, FloatButton, Input, Select } from 'antd';
import videoBG from "../../assets/images/videBG.png";
import { SearchOutlined } from "@ant-design/icons";
import { dropdown_arrow } from "../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';


const TeacherMaterial = () => {
    return (
        <>
            <section className="simple_banner">
                <div className="container">
                    <div className="sec_head">
                        <h1 className="title">
                            Material para <br /> profesores
                        </h1>
                        <p>
                            Para calcular el valor de una nota Saikit a un
                            profesor, tomamos en cuenta lo siguiente{" "}
                        </p>
                    </div>
                </div>
            </section>

            <section className="grid_sec card_grid_sec video_grid_sec tab_col_3">
                    <div className="container semi_container">
                        <div className="sec_head">
                            <h2 className="title grid_title">
                                Videos de mayor utilidad
                            </h2>
                        </div>
                        <div className="card_inner_wrapper hide_in_mobile">
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="card_inner_wrapper hide_in_desktop overflow">
                            
                            <Swiper 
                                slidesPerView={1}
                                spaceBetween={20}
                            >

                                <SwiperSlide>
                                    <div className="card">
                                        <div className="inner_card">
                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    <img src={videoBG} alt="" />
                                                </div>
                                            </div>
                                            <div className="card_body">
                                                <h3 className="title">
                                                    Ejemplo de título de video largo en dos líneas
                                                </h3>

                                                <div className="collapse_wrapper">
                                                    <p className="name_date">
                                                        <strong>Nombre de Autor,</strong>
                                                        15 mayo 2021
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <div className="inner_card">
                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    <img src={videoBG} alt="" />
                                                </div>
                                            </div>
                                            <div className="card_body">
                                                <h3 className="title">
                                                    Ejemplo de título de video largo en dos líneas
                                                </h3>

                                                <div className="collapse_wrapper">
                                                    <p className="name_date">
                                                        <strong>Nombre de Autor,</strong>
                                                        15 mayo 2021
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card">
                                        <div className="inner_card">
                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    <img src={videoBG} alt="" />
                                                </div>
                                            </div>
                                            <div className="card_body">
                                                <h3 className="title">
                                                    Ejemplo de título de video largo en dos líneas
                                                </h3>

                                                <div className="collapse_wrapper">
                                                    <p className="name_date">
                                                        <strong>Nombre de Autor,</strong>
                                                        15 mayo 2021
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>


                            </Swiper>

                        </div>
                    </div>
            </section>

            <section className="simple_banner category_sec">
                <div className="container semi_container">
                    <div className="sec_head">
                        <h2 className="title">
                            Explora por categoría
                        </h2>
                    </div>

                    <div className="search_btn_wrapper">
                        <div className="btn_list">
                            <Button className="active">Todos</Button>
                            <Button>Pedagogía</Button>
                            <Button>Estudiantes</Button>
                            <Button>Categoría</Button>
                            <Button>Categoría</Button>
                        </div>

                        <div className="category_search_wrapper">
                            <div className="search-box">
                                <Input placeholder="Consulta por palabra clave" prefix={<SearchOutlined />}/>
                            </div>
                        </div>


                    </div>
                    
                    <div className="grid_sec card_grid_sec video_grid_sec tab_col_3">
                        <div className="sec_head with_element">
                            <h2 className="title grid_title">
                                Todos los videos
                            </h2>
                            <div className="dropdown_wrappper">
                                <div className="select_wrap">
                                    <form>
                                        <Select 
                                            defaultValue="Más reciente primero"
                                            options={[
                                                {
                                                    value: "jack",
                                                    label: "Jack",
                                                },
                                                {
                                                    value: "lucy",
                                                    label: "Lucy",
                                                },
                                                {
                                                    value: "Yiminghe",
                                                    label: "yiminghe",
                                                },
                                                {
                                                    value: "disabled",
                                                    label: "Disabled",
                                                    disabled: true,
                                                },
                                            ]}
                                            suffixIcon={dropdown_arrow}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="card_inner_wrapper">
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="inner_card">
                                    <div className="img_wrapper">
                                        <div className="img_wrap">
                                            <img src={videoBG} alt="" />
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <h3 className="title">
                                            Ejemplo de título de video largo en dos líneas
                                        </h3>

                                        <div className="collapse_wrapper">
                                            <p className="name_date">
                                                <strong>Nombre de Autor,</strong>
                                                15 mayo 2021
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <FloatButton.BackTop
                shape="square"
                icon={
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.01782 23.3539L17.7133 12.6584L28.4087 23.3539"
                            stroke="#5C30A1"
                            stroke-width="3.50889"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
            
        </>
        // <MaterialDetails />
    );
};

export default TeacherMaterial;
