export const Teams = [
    {
        name: 'Burbujap',
        emotion : 'Alegre - empática - observadora',
        about : 'Soy una persona introvertida, con mucha curiosidad que siempre está creando, a pesar de ser introvertida me gusta participar en espacios donde mi talento creativo pueda ser de ayuda, soy apasionada por las letras, la ilustración y la música.'
    },
    {
        name: 'Wonder Bonsi',
        emotion : 'Colocha - risueña - distraída',
        about : 'Guatemalteca interesada en la fotografía, pintura en acuarela, la programación. Ayudar a las personas a que puedan salir adelante, superarse y concretar sueños e ideas es mi motivación.'
    },
    {
        name: 'Nostalgio',
        emotion : 'Ciprés - caesura - daydream',
        about : 'Soy un emprendedor guatemalteco que aprecia la tranquilidad, la naturaleza, los pequeños detalles, y las ideas que ayudan a las personas. Mis pasatiempos son jugar ajedrez y fútbol, leer, y escribir junto con alguno de mis heterónimos.'
    },
    {
        name: 'Dr. 4heber',
        emotion : 'Persistente - resiliente - íntegro',
        about : 'Un humano curioso con la pasión por encontrar soluciones estratégicas y creativas para todos los retos de la vida. Me motiva en todo momento el dar sin restricciones, todos mis talentos, si los recibí fueron para compartirlos.'
    },
    {
        name: 'Knight 1906',
        emotion : 'Enigmático - aventura - universo',
        about : 'Soy un desarrollador guatemalteco, por naturaleza; físico, por pasión; activista juvenil, por decisión; escritor y fotógrafo, por esfuerzo; y emprendedor por dedicación.'
    },
    {
        name: 'Kesgo',
        emotion : 'Reservado - tranquilo - molestón',
        about : 'Soy una persona tranquila que prefiere actividades al aire libre que ir al cine o lugares muy concurridos, disfruto de ver caricaturas, series, historias, leer manga, el fútbol, los videojuegos y salir con amigos.'
    },
    {
        name: 'Ark B Man',
        emotion : 'Exactitud - empatía - fiabilidad',
        about : 'Como una persona confiable, sereno, pensador y honesto. Me gusta construir soluciones, dirigirme a las verdades que definen la vida y aprender cosas nuevas a profundidad.'
    },
    {
        name: 'Lu Baggins',
        emotion : 'Perspicaz - colorida - human',
        about : 'Curiosa observadora que ama estar en la naturaleza, amante de los trabajos manuales, del arte y el color. Jugar con outfits me encanta. Siempre con ganas de romper la rutina y aprender algo nuevo. No creo en las coincidencias, sino en un plan mayor.'
    },
    {
        name: '4-CPO',
        emotion : 'Imaginación - desarrollo - fe',
        about : 'Abogado y consultor de emprendimientos de profesión, servidor por vocación y buen amigo por convicción. Creo en las relaciones interpersonales como motor de la creatividad y la consecución de impacto en nuestro entorno.'
    },
    {
        name: 'Nino',
        emotion : 'Amorosa - empática - autodidáctica',
        about : 'Muy servicial, trabajadora en equipo, honesta, empática.'
    },
    {
        name: 'Kate Bishop',
        emotion : 'Perseverante - proactiva - respetuosa',
        about : 'Soy una persona de noble y buen corazón, creo firmemente que siempre hay una manera de hacer mejor las cosas, mi frase favorita es: "¡No te acuestes perdedor, levantate siempre ganador!"'
    },
]