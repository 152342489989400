import React from 'react';
import { Modal, Button } from 'antd';

const DeleteConfirmationModal = ({ visible, onOk, onCancel, deleteAllNotifications }) => {
    return (
        <Modal
            className="notify_modal comm_inner_modal"
            centered
            visible={visible}
            onCancel={onCancel}
            footer={[
                <div className="btn_wrap btn_grid_2">
                    <Button className="primary" key="cancel" type="primary" onClick={onCancel}>
                        Ahora no
                    </Button>
                    <Button className='primary_v2 btn_red' type="primary" key="ok" onClick={onOk}>
                        Eliminar notificaciones
                    </Button>
                </div>
            ]}
        >
            <div className="confirm-Modal-container">
                <h1 className="header"> ¿Estás seguro de eliminar todas tus notificaciones? </h1>
                <div className="desc">
                    <p>No podrás deshacer esta acción.</p>
                </div>
            </div>
        </Modal>

    );
};

export default DeleteConfirmationModal;
