import React, { useEffect, useState } from "react";
import { Avatar, List, Dropdown, Menu, Button, Checkbox } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import DeleteConfirmationModal from "../Modals/DeleteConfirmationModal";
import charectorImg from "../../assets/images/character-img.png";
import "./style.scss";
import { bin, eye } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllNotifications, deleteSingleNotification, getAllNotification, markAllNotificationsAsRead, markSingleNotificationAsRead } from "../../store/slices/emailNotification";

const EmailNotification = () => {
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showActions, setShowActions] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const { notifications } = useSelector((state) => state?.notifications) || [];
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllNotification());
    }, []);

    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        setShowActions(checked);
        if (checked) {
            const allTitles = notifications.map((item) => item?.notification_title);
            setSelectedItems(allTitles);
        } else {
            setSelectedItems([]);
        }
    };

    const handleItemSelection = (id, notification_title) => {
        let updatedSelectedItems = [...selectedItems];
        if (updatedSelectedItems.includes(notification_title)) {
            updatedSelectedItems = updatedSelectedItems.filter(
                (item) => item !== notification_title
            );
        } else {
            updatedSelectedItems.push(notification_title);
        }
        setSelectedItems(updatedSelectedItems);
        setSelectAll(updatedSelectedItems.length === notifications.length);
        setShowActions(selectAll || updatedSelectedItems.length > 0);
        // markAsRead(id);
    };

    const markAsRead = () => {
        dispatch(markAllNotificationsAsRead());
        const updatedData = notifications.map((item) => {
            if (selectedItems.includes(item.notification_title)) {
                return { ...item, viewed: true };
            }
            return item;
        });
        setShowActions(false);
    };

    const deleteSelectedItems = () => {
        const updatedData = notifications.filter(
            (item) => !selectedItems.includes(item.notification_title)
        );
        setShowActions(false);
    };

    const handleDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const handleDeleteConfirmationOk = () => {
        dispatch(deleteAllNotifications())
        deleteSelectedItems();
        setShowActions(false);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteConfirmationCancel = () => {
        setShowDeleteConfirmation(false);
    };

    const menu = (item) => (
        <Menu className="more_options">
            <Menu.Item key="edit" className="edit_notifi" onClick={() => dispatch(markSingleNotificationAsRead(item.id))}>
                <div className="icon">{eye}</div>
                <p className="txt">Marcar como leído</p>
            </Menu.Item>
            <Menu.Item key="delete" className="delete_notifi" onClick={() => dispatch(deleteSingleNotification(item.id))}>
                <div className="icon">{bin}</div>
                <p className="txt">Eliminar</p>
            </Menu.Item>
        </Menu>
    );

    let contentToRender;
    if (notifications.length === 0) {
        contentToRender = (
            <div className="no_data">
                <div className="img_wrapper charectorImg">
                    <div className="img_wrap">
                        <img src={charectorImg} alt="Character" />
                    </div>
                </div>
                <h3 className="title">You don't have any notifications</h3>
            </div>
        );
    } else {
        contentToRender = (
            <div className="notificaciones_list_wrap">
                <div className="notifi_inner_wrap">
                    <div className="check_all">
                        <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                        >
                            Seleccionar todo
                        </Checkbox>
                        {showActions && (
                            <div className="check_options">
                                <Button className="mark_read" onClick={()=> markAsRead()}>
                                    <div className="icon">{eye}</div>
                                    <p className="txt">Marcar como leído</p>
                                </Button>
                                <Button className="mark_del" onClick={handleDeleteConfirmation}>
                                    <div className="icon">{bin}</div>
                                    <p className="txt">Eliminar</p>
                                </Button>
                            </div>
                        )}
                    </div>
                    <DeleteConfirmationModal
                        visible={showDeleteConfirmation}
                        onOk={handleDeleteConfirmationOk}
                        onCancel={handleDeleteConfirmationCancel}
                    />
                    <List
                        itemLayout="horizontal"
                        dataSource={notifications}
                        renderItem={(item, index) => (
                            <List.Item
                                onClick={() => handleItemSelection(item.id, item.notification_title)}
                                actions={[
                                    <Dropdown
                                        overlay={menu(item)}
                                        placement="bottomRight"
                                        trigger={["click"]}
                                        key={index}
                                    >
                                        <Button
                                            style={{
                                                border: "none",
                                                padding: 0,
                                            }}
                                        >
                                        <EllipsisOutlined
                                            style={{ fontSize: 25 }}
                                        />
                                        </Button>
                                    </Dropdown>,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            src={item.image}
                                        />
                                    }
                                    title={
                                        <p>
                                            {item.notification_title}
                                        </p>
                                    }
                                    description={
                                        <p>
                                            <span>{item.notification_description}</span>
                                            <small>{item.datetime}</small>
                                        </p>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        );
    }

    return (
        <section className="notification_sec">
            <div className="container">
                <div className="sec_head">
                    <h1 className="title h1_title">Notificaciones</h1>
                </div>
                {contentToRender}
            </div>
        </section>
    );
};

export default EmailNotification;
