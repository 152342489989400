import React from "react";
import { Button, FloatButton } from "antd";
import {
    Intrested_Updates,
    New_Updates,
    Saikit_Community,
}
 from "../../constants/updates-data";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import CtaSec from "./CtaSec";

const Updates = () => {
    const navigate = useNavigate();
    return (
        <>
            <section className="cta_sec">
                <CtaSec />
            </section>

            <div className="bulk_grid_wrapper">

                <section className="grid_sec card_grid_sec tab_col_3">
                    <div className="container semi_container">
                        <div className="sec_head">
                            <h2 className="title">{New_Updates.main_title}</h2>
                        </div>
                        <div className="card_inner_wrapper">
                            {New_Updates?.data?.slice(0, 3)?.map((data, id) => {
                                return (
                                    <div className="card">
                                        <div className="inner_card">
                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    {/* <img src={} alt="" /> */}
                                                </div>
                                            </div>
                                            <div className="card_body">
                                                <h3 className="title">
                                                    {data.article_title}
                                                </h3>

                                                <div className="collapse_wrapper">
                                                    <p className="name_date">
                                                        <strong>
                                                            {data?.author_name},
                                                        </strong>{" "}
                                                        {data?.publish_date}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
						<div className="btn_wrap">
							<Button className="primary_v2" type="primary"
                                onClick={() =>
                                    navigate(`/update/${New_Updates?._id}`)
                                }
                            >
                                Ver todos
                            </Button>
						</div>
                    </div>
                </section>

                <section className="grid_sec card_grid_sec tab_col_3">
                    <div className="container semi_container">
                        <div className="sec_head">
                            <h2 className="title">
                                {Saikit_Community.main_title}
                            </h2>
                        </div>
                        <div className="card_inner_wrapper">
                            {Saikit_Community?.data
                                ?.slice(0, 3)
                                ?.map((data, id) => {
                                    return (
                                        <div className="card">
                                            <div className="inner_card">
                                                <div className="img_wrapper">
                                                    <div className="img_wrap">
                                                        {/* <img src={} alt="" /> */}
                                                    </div>
                                                </div>
                                                <div className="card_body">
                                                    <h3 className="title">
                                                        {data.article_title}
                                                    </h3>

                                                    <div className="collapse_wrapper">
                                                        <p className="name_date">
                                                            <strong>
                                                                {
                                                                    data?.author_name
                                                                }
                                                                ,
                                                            </strong>{" "}
                                                            {data?.publish_date}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
						<div className="btn_wrap">
							<Button className="primary_v2" type="primary"
                                onClick={() =>
                                    navigate(`/update/${Saikit_Community?._id}`)
                                }
                            >
                                Ver todos
                            </Button>
						</div>
                    </div>
                </section>

                <section className="grid_sec card_grid_sec tab_col_3">
                    <div className="container semi_container">
                        <div className="sec_head">
                            <h2 className="title">
                                {Intrested_Updates.main_title}
                            </h2>
                        </div>
                        <div className="card_inner_wrapper">
                            {Intrested_Updates?.data
                                ?.slice(0, 3)
                                ?.map((data, id) => {
                                    return (
                                        <div className="card">
                                            <div className="inner_card">
                                                <div className="img_wrapper">
                                                    <div className="img_wrap">
                                                        {/* <img src={} alt="" /> */}
                                                    </div>
                                                </div>
                                                <div className="card_body">
                                                    <h3 className="title">
                                                        {data.article_title}
                                                    </h3>

                                                    <div className="collapse_wrapper">
                                                        <p className="name_date">
                                                            <strong>
                                                                {
                                                                    data?.author_name
                                                                }
                                                                ,
                                                            </strong>{" "}
                                                            {data?.publish_date}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
						<div className="btn_wrap">
							<Button className="primary_v2" type="primary"
                                onClick={() =>
                                    navigate(`/update/${Intrested_Updates?._id}`)
                                }
                            >
                                Ver todos
                            </Button>
						</div>
                    </div>
                </section>
            
            </div>
            <FloatButton.BackTop
                shape="square"
                icon={
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.01782 23.3539L17.7133 12.6584L28.4087 23.3539"
                            stroke="#5C30A1"
                            stroke-width="3.50889"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
        </>
    );
};

export default Updates;
