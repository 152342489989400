import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import termsAndConditionsSlice from "./slices/termsAndConditionsSlice";
import { thunk } from "redux-thunk";
import emailNotification from "./slices/emailNotification";
import evolutionSlice from "./slices/evolutionSlice";
import searchSlice from "./slices/searchSlice";
import professorSlice from "./slices/professorSlice";
import courseSlice from "./slices/courseSlice";
import userSlice from "./slices/userSlice";
import courseProfessorSlice from "./slices/courseProfessorSlice";

export const Store = configureStore({
    reducer: {
        auth: authSlice,
        termsAndConditions: termsAndConditionsSlice,
        notifications: emailNotification,
        evolution: evolutionSlice,
        search: searchSlice,
        professor: professorSlice,
        course: courseSlice,
        user: userSlice,
        courseProfessor: courseProfessorSlice,
    },
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), thunk],
});
