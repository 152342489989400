import React, { useState } from 'react'
import { hamburger_gray, home, plus2, professor, search } from '../../assets'
import "./style.scss";
import FooterDrawerMenu from '../Header/FooterDrawerMenu';
import { useNavigate } from 'react-router-dom';
import { PATH_LIST } from '../../constants/paths';
import AddEvolutionModal from '../Modals/AddEvolutionModal';

const ResponsiveMenu = () => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="bottom_menu_wrapper">
                <div className="bottom_menu_inner">
                    <ul className="bottom_menu_list">
                        <li onClick={() => navigate(PATH_LIST.USER.HOME)} className="menu_item home">
                            <div className="menu_item_wrap">
                                <div className="icon">
                                    {home}
                                </div>
                                <div className="menu_title">
                                    <h6>Mi “U”</h6>
                                </div>
                            </div>
                        </li>
                        <li className="menu_item search">
                            <div className="menu_item_wrap">
                                <div className="icon">
                                    {search}
                                </div>
                                <div className="menu_title">
                                    <h6>Buscar</h6>
                                </div>
                            </div>
                        </li>
                        <li className="menu_item evelution">
                            <div className="menu_item_wrap">
                                <div onClick={() => setIsModalOpen(true)} className="icon">
                                    {plus2}
                                </div>
                                <div className="menu_title">
                                    <h6>Evaluar</h6>
                                </div>
                            </div>
                        </li>
                        <li onClick={() => navigate(PATH_LIST.USER.PROFESSOR_LIST)} className="menu_item professor">
                            <div className="menu_item_wrap">
                                <div className="icon">
                                    {professor}
                                </div>
                                <div className="menu_title">
                                    <h6>Profesores</h6>
                                </div>
                            </div>
                        </li>
                        <li className="menu_item menu" onClick={() => showDrawer(true)}>
                            <div className="menu_item_wrap">
                                <div className="icon">
                                    {hamburger_gray}
                                </div>
                                <div className="menu_title">
                                    <h6>Menú</h6>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <FooterDrawerMenu open={open} onClose={onClose} />
            {
                isModalOpen &&
                <AddEvolutionModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            }
        </>
    )
}

export default ResponsiveMenu