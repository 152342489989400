import { Button, Modal, Skeleton } from "antd";
import React, { useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchTermsAndConditions } from "../../../store/slices/termsAndConditionsSlice";
import { useTranslation } from "react-i18next";

const TermsConditionModal = ({ closeModal, setCloseModal }) => {
	const { termsAndConditions } = useSelector((state) => state.termsAndConditions);
	const dispatch = useDispatch();
	const { t: verifyEmailT } = useTranslation('verifyEmail');

	useEffect(() => {
		dispatch(fetchTermsAndConditions());
		// eslint-disable-next-line
	}, []);

	const parser = new DOMParser();
	const doc = parser.parseFromString(termsAndConditions, "text/html");

	const title = doc.querySelector("title");
	const body = doc.body;
	return (
		<Modal
			className="term-condition-modal"
			open={closeModal}
			onCancel={() => setCloseModal(false)}
			centered={true}
			width={826}
			footer={[
				<div key="term-condition-modal" className="modal-footer-button">
					{termsAndConditions ? (
						<Button type="primary" onClick={() => setCloseModal(false)}>
							{verifyEmailT('UNDERSTOOD')}
						</Button>
					) : (
						<Skeleton />
					)}
				</div>,
			]}
		>
			<div className="modal-text">
				{termsAndConditions ? (
					<div>
						<h3>{title ? title.textContent : "No Title"}</h3>
						<p dangerouslySetInnerHTML={{ __html: body?.innerHTML }} />
					</div>
				) : (
					<Skeleton />
				)}
			</div>
		</Modal>
	);
};

export default TermsConditionModal;
