import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import check from "../../assets/icons/right-icon.svg";
import { purple_icon } from "../../assets";
import { getCourseProfessorAbout } from "../../store/slices/courseProfessorSlice";

const CourseProfessor = () => {
    const dispatch = useDispatch();
    const { id, key } = useParams();
    const { aboutCourseProfessor } = useSelector((state) => state.courseProfessor);

    useEffect(() => {
        dispatch(getCourseProfessorAbout({ id: id, key: key }));
    }, []);

    return (
        <>
            <section className="top_semi_banner_sec"></section>
            <section className="user_prifile_sec">
                <div className="container md_container">
                    <div className="inner_wrap">
                        <div className="user_profile_wrapper">
                            <div className="user_card">
                                <div className="my_info">
                                    <div className="details">
                                        <div className="my_pic">
                                            <img
                                                src={aboutCourseProfessor?.image}
                                                alt="my-Pic"
                                            />
                                        </div>
                                        <div className="score">
                                            {aboutCourseProfessor?.score} {purple_icon}
                                        </div>
                                    </div>
                                    <div className="name">
                                        <h4 className="title">{aboutCourseProfessor?.fullName}</h4>
                                        <div className="verify">
                                            <img
                                                src={check}
                                                alt="verified"
                                            />
                                        </div>
                                    </div>
                                    <div className="short_name">
                                        <h6 className="title professor-visit">{aboutCourseProfessor?.views} visitas</h6>
                                    </div>
                                </div>

                                <div className="about_me">
                                    <div className="tab_badge">
                                        <p className="prof_badge">Profesor</p>
                                    </div>
                                    {aboutCourseProfessor?.courses?.map((data, index) => {
                                        return (
                                            <div
                                                className="profession"
                                                key={index}>
                                                <h6>{data?.career}</h6>
                                                <p>{data?.university}</p>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="professor_labels">
                                    {aboutCourseProfessor?.labels?.map((data, index) => {
                                        return (
                                            <p
                                                className={`label ${data?.category === "NEGATIVE" ? "negative" : "positive"}`}
                                                key={index}>
                                                {data?.name}
                                            </p>
                                        );
                                    })}
                                </div>

                                <div className="evaluations_wrap">
                                    <div className="approvals_list_wrap">
                                        <div className="approvals_list">
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={like_cyan}
                                                            alt="Like"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutCourseProfessor?.detail?.universities}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">Universidades</p>
                                                </div>
                                            </div>
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutCourseProfessor?.detail?.courses}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">Cursos</p>
                                                </div>
                                            </div>
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutCourseProfessor?.detail?.evaluations}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title"> Evaluaciones</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bottom_row">
                                    <small>Fecha de registro {aboutCourseProfessor?.registrationDate}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CourseProfessor;
