import React from 'react'
import ContactUsForm from './ContactUsForm.jsx'

const ContactUsSection = () => {
    return (
       <div className='container semi_container'>
            <div className='inner_wrap'>
                <div className="left-section">
                    <div className='sec_head'>
                        <h2 className='title'>Contáctanos</h2>
                        <p>Escríbenos tus consultas, nosotros te contactaremos directamente en la mayor brevedad posible.</p>
                    </div>
                </div>
                <div className="right-section">
                    <ContactUsForm />
                </div>
            </div>
       </div>
    )
}

export default ContactUsSection