import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/api-url";
import executeHttp from "../../services/api";

const initialState = {
};

// PHASE 1
export const searchProfessors = createAsyncThunk(
    "professors/searchProfessors",
    async (filteredData) => {
        try {
            const response = await executeHttp({
                method: 'POST',
                endpoint: `${API_URL.SEARCH.SEARCH}`,
                data: filteredData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

const professorFiltrationSlice = createSlice({
    name: "professors",
    initialState: {
    },
    reducers: {
    },
    extraReducers: () => {
        // builder.addCase(getUniversities.fulfilled, (state, action) => {
        //     return { ...state, universities: action.payload };
        // });
        // builder.addCase(getCareer.fulfilled, (state, action) => {
        //     return { ...state, countries: action.payload };
        // });
    },
});

export default professorFiltrationSlice.reducer;