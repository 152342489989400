import { Button, Checkbox, Form, Input } from 'antd'
import React from 'react'

const ProfessorListLeftSideBar = ({ filters, setFilters, clearFilters, applyFilters }) => {

    const defaultFilters = {
        university: '',
        academicUnit: '',
        career: '',
        checkboxes: {
            todas: false,
            calidadEnsenanza: false,
            criterioCalificacion: false,
            nivelDificultad: false,
            accesibilidad: false,
            capacidadMentoria: true,
        },
    };

    // Ensure filters object is initialized with default values if it's initially undefined
    const initialFilters = filters || defaultFilters;

    return (
        <div className="aside_side_bar">
            <div className='side_bar_inner'>
                <div className="sec_head">
                    <h3 className='title'>Filtros de búsqueda</h3>
                </div>

                <div className="field">
                    <Form.Item label="Universidad">
                        <Input
                            placeholder='Introduce el nombre de tu universidad'
                            value={initialFilters?.university}
                            onChange={e => setFilters({ ...initialFilters, university: e.target.value })}
                        />
                    </Form.Item>
                </div>

                <div className="field">
                    <Form.Item label="Unidad académica">
                        <Input
                            placeholder="Ingresa tu unidad académica"
                            value={initialFilters?.academicUnit}
                            onChange={e => setFilters({ ...initialFilters, academicUnit: e.target.value })}
                        />
                    </Form.Item>
                </div>


                <div className="field">
                    <Form.Item label="Carrera">
                        <Input
                            placeholder="Entra en tu carrera"
                            value={initialFilters?.career}
                            onChange={e => setFilters({ ...initialFilters, career: e.target.value })}
                        />
                    </Form.Item>
                </div>


                <div className="field checkbox_field">
                    <Form.Item label="Calificación alta en">
                        <Checkbox.Group
                            value={Object.keys(initialFilters?.checkboxes).filter(key => initialFilters?.checkboxes[key])}
                            onChange={checkedValues =>
                                setFilters({
                                    ...initialFilters,
                                    checkboxes: {
                                        ...initialFilters?.checkboxes,
                                        todas: checkedValues.includes('todas'),
                                        calidadEnsenanza: checkedValues.includes('calidadEnsenanza'),
                                        criterioCalificacion: checkedValues.includes('criterioCalificacion'),
                                        nivelDificultad: checkedValues.includes('nivelDificultad'),
                                        accesibilidad: checkedValues.includes('accesibilidad'),
                                        capacidadMentoria: checkedValues.includes('capacidadMentoria'),
                                    },
                                })
                            }
                        >
                            <Checkbox value="todas">Todas</Checkbox>
                            <Checkbox value="calidadEnsenanza">Calidad de enseñanza</Checkbox>
                            <Checkbox value="criterioCalificacion">Criterio de calificación</Checkbox>
                            <Checkbox value="nivelDificultad">Nivel de dificultad</Checkbox>
                            <Checkbox value="accesibilidad">Accesibilidad</Checkbox>
                            <Checkbox value="capacidadMentoria">Capacidad de mentoría e inspiración</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </div>


                <div className="field btn_wrap btn_grid_2">
                    <Button className='primary_v2' type="primary" onClick={clearFilters}>Limpiar filtros</Button>

                    <Button className='primary' type="primary" onClick={applyFilters}>
                        Aplicar filtros
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ProfessorListLeftSideBar
