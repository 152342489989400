import { Button, Modal } from "antd";
import React from "react";

const ConfirmModal = ({
    text,
    desc,
    closeFunction,
    cancelFunction,
    openState,
    okText,
    cancleText,
}) => {
    return (
        <Modal
            title=""
            className="confirm-modal comm_inner_modal"
            open={openState}
            onCancel={closeFunction}
            onOk={cancelFunction}
            centered
            footer={[
                <div className="btn_wrap btn_grid_2">
                    <Button key="back" onClick={closeFunction} className="primary_v2" type="primary">
                    Ahora no
                    </Button>
                    <Button key="submit" onClick={cancelFunction} className="primary" type="primary">
                        Verificar mis datos
                    </Button>
                </div>,
            ]}
        >
            <div className="confirm-Modal-container">
                <h1 className="header">{text}</h1>
                <div className="desc">{desc}</div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
