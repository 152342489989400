import React, { useEffect } from "react";
import { degree_hat, dropdown_arrow, eduBook } from "../../../../assets";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getEvolutionCareer } from "../../../../store/slices/evolutionSlice";
import { evolutionCareersConstant } from "../constant";

const SideBar = ({ careerSelection, setCareerSelection, current, selectedProfessor, selectedCourse }) => {
	const dispatch = useDispatch();
	const { evolutionCareers } = useSelector((state) => state?.evolution);
	const careersToUse = evolutionCareers;

	const careerOptions = careersToUse?.map((career) => ({
		value: career?.id,
		label: (
			<div>
				<div className="name">{career.name}</div>
				<div className="details">{career.detail}</div>
			</div>
		),
	}));
	careerOptions.push({
		value: "Elegir otra carrera",
		label: "Elegir otra carrera",
	});

	const handleSelectChange = (value, option) => {
		setCareerSelection(value);
	};
	useEffect(() => {
		dispatch(getEvolutionCareer());
		setTimeout(() => {
			if (careerOptions.length > 0) {
				setCareerSelection(careerOptions[0].value);
			}
		}, 1000);
	}, []);

	return (
		<div className="side_bar_modal hide_in_tab">
			<div className="side_bar_inner">
				<div className="sec_head">
					<h2 className="title">
						Nueva <br /> <span className="highlight2">evaluación</span>
					</h2>
					<p className="desc">Comienza eligiendo el curso y profesor que deseas evaluar</p>
				</div>

				{/* Phase 1 */}

				{current === 0 ? (
					<div className="dropdown_wrappper">
						<div className="select_wrap">
							<form>
								<Select
									options={careerOptions}
									suffixIcon={dropdown_arrow}
									popupClassName="cust_dropDown"
									style={{ width: 300 }}
									value={careerSelection}
									onChange={handleSelectChange}
									valueRender={(value, option) => (
										<div>
											{option?.label?.props?.children?.[0]} {option?.label?.props?.children?.[1]}
										</div>
									)}
								/>
							</form>
						</div>
					</div>
				) : (
					// {/* Phase 2 */}
					<div className="select_perf_info">
						<dov className="select_item select_profession">
							<div className="icon">{degree_hat}</div>
							<p className="select_txt">
								Médico y cirujano <strong>USAC</strong>
							</p>
						</dov>
						<div className="select_item select_prof_name">
							<div className="icon">{eduBook}</div>
							<p className="select_txt">
								<strong>{selectedCourse?.name}</strong>
							</p>
						</div>
						<div class="select_item">
							<div class="user_info">
								<span class="profile_icon">GJ</span>
							</div>
							<p className="select_txt">
								<strong>{selectedProfessor?.name}</strong>
							</p>
						</div>
					</div>
				)}

				<div></div>
			</div>
		</div>
	);
};

export default SideBar;
