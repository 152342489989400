import { useNavigate } from "react-router-dom";
import Avtar from "../../assets/icons/avatar.svg";
import starIcon from "../../assets/icons/star-icon.svg";
import universityIcon1 from "../../assets/icons/university-1.svg";
import universityIcon2 from "../../assets/icons/university-2.svg";
const IdCard = () => {
    const navigate = useNavigate()
    return (
        <div className="id_card" onClick={()=> navigate(`/professor/123`)} style={{cursor: "pointer"}}>
            <div className="card-container">
                <div className="rating_wrap">
                    <span className="rat_box">4</span>
                    <span className="star_box">
                        <img src={starIcon} alt="" />
                    </span>
                </div>
                <div className="user_info">
                    <span className="profile_icon">
                        <img src={Avtar} alt="" />
                    </span>
                    <span className="name">Mario Rafael Juárez González</span>
                </div>
                <div className="user_details">
                    <div className="info_icon">
                        <img src={universityIcon1} alt="" />
                        <img src={universityIcon2} alt="" />
                    </div>
                    <div className="user_desc">
                        <p>
                            <strong>USAC</strong>, Ciencias Médicas -{" "}
                        </p>
                        <p><strong>URL</strong>, Ciencias económicas y
                        empresariales</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default IdCard;
