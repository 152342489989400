import React from 'react';
import EmojiRating from '../../../../Common/EmojiRating';

const FirstEmojiRating = ({ infoCircle, setInfoModal, moveToNext, factor, next}) => {
    return (
        <div className="playarea_inner ">
            <div className="sec_head">
                <p className="txt_primary">
                    <span>{factor?.id}. {factor?.name}</span>
                    <span
                        className="more_info"
                        onClick={() =>
                            setInfoModal(true)
                        }
                    >
                        {infoCircle}
                    </span>
                </p>
                <h2 className="title sm_title que_txt">
                    {factor?.description}
                </h2>
            </div>

            <div className="emoji_rating_wrapper">
                <EmojiRating moveToNext={moveToNext} levels={factor?.levels} next={next} factor={factor}/>
            </div>
        </div>
    )
}

export default FirstEmojiRating;
