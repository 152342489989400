import React from "react";
import { Outlet } from "react-router-dom";
import StaticHeader from "../../components/StaticHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const StaticLayout = () => {
	const Token = localStorage.getItem("Token");
	return (
		<>
			<div className="main_wrapper">
				{Token ? <Header /> : <StaticHeader />}
				<Outlet />
			</div>
			<Footer />
		</>
	);
};

export default StaticLayout;
 