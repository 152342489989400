import { Button, Modal } from 'antd'
import React from 'react'
import "./style.scss";
import tickIcon from '../../../assets/icons/tick-icon.svg'
import { useTranslation } from 'react-i18next';

const BenifitesModal = ({closeModal, setCloseModal}) => {
    const { t: verifyEmailT } = useTranslation('verifyEmail'); 
	return (
		<Modal
			className="benifit-modal"
			open={closeModal}
			onCancel={() => setCloseModal(false)}
			centered={true}
			transitionName=""
			maskTransitionName=""
			width={826}
			footer={[
				<div key="benifit-modal" className="modal-footer-button">
					<Button
						type="primary"
						onClick={() => setCloseModal(false)}>{verifyEmailT('VERIFICATION_BENIFITS.UNDERSTOOD')}
					</Button>
				</div>
			]}
		>
			<div className="modal-text">
				<div className="modal-text">
					<h3>{verifyEmailT('VERIFICATION_BENIFITS.LEVEL')} <img src={tickIcon} alt="" /></h3>
					<h4>{verifyEmailT('VERIFICATION_BENIFITS.MESSAGE')}</h4>
					<p>{verifyEmailT('VERIFICATION_BENIFITS.LEVELS_BENIFIT')}</p>
					<p><img src={tickIcon} alt="" />  {verifyEmailT('VERIFICATION_BENIFITS.BENIFIT_1')}</p>
					<p><img src={tickIcon} alt="" />  {verifyEmailT('VERIFICATION_BENIFITS.BENIFIT_2')}</p>
				</div>
			</div>
		</Modal>
	)
}

export default BenifitesModal