import { Button } from 'antd';
import React, { useEffect } from 'react'
import '../style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getEvolutionSearch } from '../../../../store/slices/evolutionSlice';

const SecondPhase = ({ next, prev, selectedCourse, selectedProfessor, careerSelection, setSelectedCourse, setSelectedProfessor }) => {
    const { evolutionSearch } = useSelector((state) => state?.evolution);
    const dispatch = useDispatch();

    useEffect(()=> {
        const careerFilter = `ID_CAREERS=[${careerSelection}], `;
		const payload = {
			text_search: '',
			type_search: "ALL",
			filters: careerFilter,
			sort: "ASC",
			is_top: "false",
			top_size: 20,
			pagination_cursor: null,
			pagination_size: 10,
		};

		dispatch(getEvolutionSearch({ data: payload }));
    }, [])

    console.log('aearch', evolutionSearch)

    return (
        <div className="playarea_modal choose_professor">
            <div className="playarea_inner ">
                <div className="sec_head">
                    <h2 className="title sm_title">
                        Elige el profesor que deseas evaluar para
                        matemática avanzada 2
                    </h2>
                </div>

                <div className="grid_sec tab_col_2">
                    <div className="card_inner_wrapper ">
                        { selectedProfessor?.id && 
                            evolutionSearch.courses?.map((data, index)=> {
                                return (
                                    <div className="card" key={index} onClick={()=> setSelectedCourse({id: data?.id, name: data?.name}) }>
                            <div className={`card-container ${data?.id === selectedCourse.id ? 'selected': ""}`}>
                                <div class="profession_info">
                                    <div class="user_info">
                                        <span class="profile_icon">
                                            MM
                                        </span>
                                    </div>
                                    <div class="prof_info_wrap">
                                        <div class="prof_top_bar">
                                            <h6 class="title">
                                                {data?.name}
                                            </h6>
                                        </div>
                                        <div class="profession">
                                            <p>
                                                {data?.aditionalInfo}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                )
                            })
                        }

                         {
                            selectedCourse?.id && 
                            evolutionSearch.professors?.map((data, index)=> {
                                return (
                                    <div className="card" key={index} onClick={()=> setSelectedProfessor({id: data?.id, name: data?.fullName}) }>
                            <div className={`card-container ${data?.id === selectedProfessor.id ? 'selected': ""}`}>
                                <div class="profession_info">
                                    <div class="user_info">
                                        <span class="profile_icon">
                                            MM
                                        </span>
                                    </div>
                                    <div class="prof_info_wrap">
                                        <div class="prof_top_bar">
                                            <h6 class="title">
                                                {data?.fullName}
                                            </h6>
                                        </div>
                                        <div class="profession">
                                            <p>
                                                {data?.aditionalInfo}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                )
                            })
                         }

                        {/* <div className="card">
                            <div className="card-container selected">
                                <div class="profession_info">
                                    <div class="user_info">
                                        <span class="profile_icon">
                                            MM
                                        </span>
                                    </div>
                                    <div class="prof_info_wrap">
                                        <div class="prof_top_bar">
                                            <h6 class="title">
                                                Mario Menéndez
                                            </h6>
                                        </div>
                                        <div class="profession">
                                            <p>
                                                <strong>USAC</strong>,
                                                Ciencias Médicas -
                                                <strong>URL</strong>,
                                                Ciencias económicas y
                                                empresariales
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-container">
                                <div class="profession_info">
                                    <div class="user_info">
                                        <span class="profile_icon">
                                            MM
                                        </span>
                                    </div>
                                    <div class="prof_info_wrap">
                                        <div class="prof_top_bar">
                                            <h6 class="title">
                                                Mario Menéndez
                                            </h6>
                                        </div>
                                        <div class="profession">
                                            <p>
                                                <strong>USAC</strong>,
                                                Ciencias Médicas -
                                                <strong>URL</strong>,
                                                Ciencias económicas y
                                                empresariales
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-container">
                                <div class="profession_info">
                                    <div class="user_info">
                                        <span class="profile_icon">
                                            MM
                                        </span>
                                    </div>
                                    <div class="prof_info_wrap">
                                        <div class="prof_top_bar">
                                            <h6 class="title">
                                                Mario Menéndez
                                            </h6>
                                        </div>
                                        <div class="profession">
                                            <p>
                                                <strong>USAC</strong>,
                                                Ciencias Médicas -
                                                <strong>URL</strong>,
                                                Ciencias económicas y
                                                empresariales
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-container">
                                <div class="profession_info">
                                    <div class="user_info">
                                        <span class="profile_icon">
                                            MM
                                        </span>
                                    </div>
                                    <div class="prof_info_wrap">
                                        <div class="prof_top_bar">
                                            <h6 class="title">
                                                Mario Menéndez
                                            </h6>
                                        </div>
                                        <div class="profession">
                                            <p>
                                                <strong>USAC</strong>,
                                                Ciencias Médicas -
                                                <strong>URL</strong>,
                                                Ciencias económicas y
                                                empresariales
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="btn_wrap btn_grid_2">
                    <Button className="primary_v2" type="primary" onClick={()=> prev()}>
                        Atrás
                    </Button>
                    <Button onClick={() => next()} className="primary" type="primary">
                        Comenzar Evaluación
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SecondPhase;
