import { Button, Checkbox, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react'

const ForthPhase = ({ anonymous_icon, next, opinion, setOpinion }) => {
    const handleOpinionChange = (e) => {
        const text = e.target.value;
        if (text.length <= 8000) { 
            setOpinion(text); 
        }
    };
    return (
        <div className="playarea_modal que_ans_rating que_ans_form">
            <div className="playarea_inner">

                <div className="anonymous_mode_wrapper">
                    {/* to hide/show add/remove this "show_anonymous" clsss */}
                    <div className="anonymous_mode_wrap">
                        <div className="icon">
                            {anonymous_icon}
                        </div>
                        <p className="anonymous_txt">Haz activado el modo anónimo, nadie sabrá que tú eres el autor de esta evaluación.</p>
                    </div>
                </div>


                <div className="sec_head">
                    <h2 className="sm_title que_txt">
                        ¿Quieres agregar alguna opinión o recomendación
                        sobre la clase?
                        <small className="optional">(Opcional)</small>
                    </h2>
                </div>

                <Form action="">
                    <Form.Item>
                        <TextArea
                            value={opinion}
                            onChange={handleOpinionChange}
                            rows={4}
                            placeholder="Escribe tu opinión o recomendación"
                        />
                    </Form.Item>

                    <div className="inner_field">
                        <p className="max-char">{opinion.length} / 8000 caracteres</p>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Comentar de forma anónima</Checkbox>
                        </Form.Item>
                    </div>

                    <div className="btn_wrap">
                        <Button onClick={() => next()} type="primary">Continuar</Button>
                    </div>

                </Form>

            </div>
        </div>
    )
}

export default ForthPhase;
