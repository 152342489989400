import React from 'react'
import info_img from "../../../assets/images/info-img.png";
import starIcon from "../../../assets/icons/star-icon.svg";

const AssignmentSection = () => {
    return (
        <div className='container semi_container'>

            <div className='info_img_col rev_col'>
                <div className="img_col_wrap">
                    <div className='inner_box'>
                        <p>Mejor calificado para</p>
                        <h3 className='title'>Diseño Fundamental</h3>

                        <div className='img_wrapper doodle_wrap'>
                            <div className="img_wrap">
                                <img src={info_img} alt="info_img" />
                            </div>
                            <div className='doodle star_doodle'>
                                <div className="rating_wrap">
                                    <span className="rat_box">5</span>
                                    <span className="star_box">
                                        <img src={starIcon} alt="info_img" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info_col_wrap">
                    <div className='info_col sec_head'>
                        <h2 className='title'>¿Pensando en las <br></br> <span className='highlight2'>próximas</span> asignaciones?</h2>
                        <p>Ten una mejor referencia para  planificar mejor tu asignación. Deja de asumir y conoce las mejores opiniones  aquí.</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default AssignmentSection