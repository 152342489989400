import { Button, Form, Input, Select } from "antd";
import React, { useState } from "react";
import executeHttp from '../../../services/api';
import { API_URL } from '../../../constants/api-url';
import { dropdown_arrow } from "../../../assets";

const ContactUsForm = () => {
	const { TextArea } = Input;
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		number: "",
		topic: "",
		message: "",
	});

	const handleFormChange = (value, name) => {
		let changedField = { ...formData };
		changedField[name] = value;
		setFormData({ ...changedField });
	};

    const handleFormSubmit = async () => {
        setIsLoading(true)
        try {
            const response = await executeHttp({
                method: "POST",
                endpoint: `${API_URL.GENERAL.CONTACT_US}`,
                data : formData
            });
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }

    }
	return (
		<div className="form">
			<Form form={form} layout="vertical">
				<div className="field">
					<Form.Item label="Nombre completo" name="name">
						<Input
							type="text"
							name="name"
							value={formData.name}
							onChange={(e) => handleFormChange(e.target.value, "name")}
						/>
					</Form.Item>
				</div>

				<div className="col_2">
					<div className="field">
						<Form.Item label="Correo electrónico" name="email">
							<Input
								type="email"
								name="email"
								value={formData.name}
								onChange={(e) => handleFormChange(e.target.value, "email")}
							/>
						</Form.Item>
					</div>
					<div className="field">
						<Form.Item label="Número de teléfono" name="number">
							<Input
								addonBefore="+502"
								type="text"
								name="number"
								maxLength={10}
								value={formData.number}
								onChange={(e) => {
									const numericValue = e.target.value.replace(/\D/g, "");
									form.setFieldsValue({number: numericValue,});
									handleFormChange(numericValue, "number");
								}}
							/>
						</Form.Item>
					</div>
				</div>

				<div className="field">
					<Form.Item label="Tipo de consulta">
						<Select suffixIcon={dropdown_arrow}/>
					</Form.Item>
				</div>
				<div className="field">
					<Form.Item label="Tu mensaje" name="message">
						<TextArea
							placeholder="Escribe tu mensaje"
							name="message"
							autoSize={{ minRows: 5 }}
							value={formData.message}
							onChange={(e) => handleFormChange(e.target.value, "message")}
						/>
					</Form.Item>
				</div>
                <div className="action-field">
				<Form.Item>
					<Button className="primary_alt" type="primary" htmlType="submit" loading={isLoading} onClick={()=> handleFormSubmit()}>
                        Enviar mensaje
					</Button>
				</Form.Item>
			</div>
			</Form>
		</div>
	);
};

export default ContactUsForm;
