import React, { useState } from 'react';
import { Row, Col, Input, Checkbox, Button, Dropdown, Menu, Form } from 'antd';
import Card from '../Cards';
import { useDispatch } from 'react-redux';
import { searchProfessors } from '../../store/slices/professorFiltration';
import { dropdown_arrow, setting_gray } from '../../assets';
import "./style.scss";
import ProfessorListLeftSideBar from './ProfessorListLeftSideBar';
import ProfessorListSideBarModal from '../Modals/ProfessorListSideBarModal';

const initialData = [
    { id: 1, university: 'Universidad A', academicUnit: 'Unit 1', career: 'Career A', ratings: ['Calidad de enseñanza', 'Criterio de calificación'] },
    { id: 2, university: 'Universidad B', academicUnit: 'Unit 2', career: 'Career B', ratings: ['Nivel de dificultad', 'Accesibilidad'] },
    { id: 3, university: 'Universidad C', academicUnit: 'Unit 3', career: 'Career C', ratings: ['Capacidad de mentoría e inspiración'] },
    { id: 4, university: 'Universidad D', academicUnit: 'Unit 3', career: 'Career C', ratings: ['Capacidad de mentoría e inspiración'] },
    { id: 5, university: 'Universidad E', academicUnit: 'Unit 3', career: 'Career C', ratings: ['Capacidad de mentoría e inspiración'] },
    { id: 6, university: 'Universidad F', academicUnit: 'Unit 3', career: 'Career C', ratings: ['Capacidad de mentoría e inspiración'] },
];

const FilterTeacherPage = () => {
    const [teachersData, setTeachersData] = useState(initialData);
    const [filteredData, setFilteredData] = useState(initialData);
    //filter modal open for table,mobile device 
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();


    const [professorFilter, setProfessorFileter] = useState({
        TopSize: 2,
        filters: "",
        isTop: false,
        paginationCursor: 7,
        paginationSize: 2,
        sort: ["asc", "desc"],
        typeSearch: "All"
    })

    const [filters, setFilters] = useState({
        university: '',
        academicUnit: '',
        career: '',
        checkboxes: {
            todas: false,
            calidadEnsenanza: false,
            criterioCalificacion: false,
            nivelDificultad: false,
            accesibilidad: false,
            capacidadMentoria: true,
        },
        sortOrder: 'asc',
    });

    const applyFilters = () => {
        const filtered = teachersData.filter(teacher => {
            const universityCheck = !filters.university || teacher.university.toLowerCase().includes(filters.university.toLowerCase());
            const academicUnitCheck = !filters.academicUnit || teacher.academicUnit.toLowerCase().includes(filters.academicUnit.toLowerCase());
            const careerCheck = !filters.career || teacher.career.toLowerCase().includes(filters.career.toLowerCase());
            const checkboxFilters = Object.keys(filters.checkboxes).filter(key => filters.checkboxes[key]);
            const checkboxesCheck = checkboxFilters.length === 0 || checkboxFilters.some(filter => teacher.ratings.includes(filter));
            return universityCheck && academicUnitCheck && careerCheck && checkboxesCheck;
        });
        dispatch(searchProfessors(professorFilter))
        setFilteredData(filtered);
        setProfessorFileter(professorFilter)
    };

    const clearFilters = () => {
        setFilters({
            university: '',
            academicUnit: '',
            career: '',
            checkboxes: {
                todas: false,
                calidadEnsenanza: false,
                criterioCalificacion: false,
                nivelDificultad: false,
                accesibilidad: false,
                capacidadMentoria: false,
            },
            sortOrder: 'asc',
        });
        setFilteredData(teachersData);
    };

    const sortTeachers = sortOrder => {
        const sorted = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.university.localeCompare(b.university);
            } else {
                return b.university.localeCompare(a.university);
            }
        });
        setFilteredData(sorted);
        setFilters({ ...filters, sortOrder });
    };
    const [selectedKey, setSelectedKey] = useState('relevantes');

    const handleMenuClick = ({ key }) => {
        setSelectedKey(key);
        sortTeachers(key);
    };

    const selectedTitle = {
        relevantes: 'Más relevantes',
        antiguos: 'Más antiguos primero',
        recientes: 'Más recientes primero',
        alta: 'Calificación alta primero',
        baja: 'Calificación baja primero',
        alfabetico: 'Orden alfabético'
    }[selectedKey];

    //modal 


    const menu = (
        <Menu className='btn_dropdown' selectedKeys={[selectedKey]} onClick={handleMenuClick}>
            <Menu.Item key="relevantes" onClick={() => sortTeachers('relevantes')}>
                Más relevantes
            </Menu.Item>
            <Menu.Item key="antiguos" onClick={() => sortTeachers('antiguos')}>
                Más antiguos primero
            </Menu.Item>
            <Menu.Item key="recientes" onClick={() => sortTeachers('recientes')}>
                Más recientes primero
            </Menu.Item>
            <Menu.Item key="alta" onClick={() => sortTeachers('alta')}>
                Calificación alta primero
            </Menu.Item>
            <Menu.Item key="baja" onClick={() => sortTeachers('baja')}>
                Calificación baja primero
            </Menu.Item>
            <Menu.Item key="alfabetico" onClick={() => sortTeachers('asc')}>
                Orden alfabético
            </Menu.Item>
        </Menu>
    );

    const renderTeacherCards = () => {
        return (
            <div className='inner_list_playground'>
                <div className="sec_head">
                    <h2 className='title'>Lista de profesores</h2>
                </div>
                <div className="list_option_wrap">
                    <div className="desc">
                        <p>Universidad de San Carlos, Ciencias Médicas, Médico y cirujano, capacidad de mentoría e inspiración</p>
                    </div>
                    <div className="drop_box">
                        <div className="dropdown_box">
                            <Dropdown overlay={menu}>
                                <Button>
                                    {selectedTitle}
                                    <i className="fas fa-caret-down" />
                                    <div className="icon_box FaAngleDown">{dropdown_arrow}</div>
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="filter_box">
                            <div className="filter" onClick={() => setIsModalVisible(true)}>
                                <div className="icon">
                                    {setting_gray}
                                </div>
                                <p className="text">Filtros</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_sec tab_col_2">
                    <div className="card_inner_wrapper ">
                        {filteredData.map(teacher => (
                            <Card>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section className='profesor_list_sec'>
            <div className='inner_wrap'>
                {/* start left sidebar */}
                {/* <div className="aside_side_bar">
                    <div className='side_bar_inner'>
                        <div className="sec_head">
                            <h3 className='title'>Filtros de búsqueda</h3>
                        </div>

                        <div className="field">
                            <Form.Item label="Universidad">
                                <Input
                                    placeholder='Introduce el nombre de tu universidad'
                                    value={filters.university}
                                    onChange={e => setFilters({ ...filters, university: e.target.value })}
                                />
                            </Form.Item>
                        </div>

                        <div className="field">
                            <Form.Item label="Unidad académica">
                                <Input
                                    placeholder="Ingresa tu unidad académica"
                                    value={filters.academicUnit}
                                    onChange={e => setFilters({ ...filters, academicUnit: e.target.value })}
                                />
                            </Form.Item>
                        </div>


                        <div className="field">
                            <Form.Item label="Carrera">
                                <Input
                                    placeholder="Entra en tu carrera"
                                    value={filters.career}
                                    onChange={e => setFilters({ ...filters, career: e.target.value })}
                                />
                            </Form.Item>
                        </div>


                        <div className="field checkbox_field">
                            <Form.Item label="Calificación alta en">
                                <Checkbox.Group
                                    value={Object.keys(filters.checkboxes).filter(key => filters.checkboxes[key])}
                                    onChange={checkedValues =>
                                        setFilters({
                                            ...filters,
                                            checkboxes: {
                                                ...filters.checkboxes,
                                                todas: checkedValues.includes('todas'),
                                                calidadEnsenanza: checkedValues.includes('calidadEnsenanza'),
                                                criterioCalificacion: checkedValues.includes('criterioCalificacion'),
                                                nivelDificultad: checkedValues.includes('nivelDificultad'),
                                                accesibilidad: checkedValues.includes('accesibilidad'),
                                                capacidadMentoria: checkedValues.includes('capacidadMentoria'),
                                            },
                                        })
                                    }
                                >
                                    <Checkbox value="todas">Todas</Checkbox>
                                    <Checkbox value="calidadEnsenanza">Calidad de enseñanza</Checkbox>
                                    <Checkbox value="criterioCalificacion">Criterio de calificación</Checkbox>
                                    <Checkbox value="nivelDificultad">Nivel de dificultad</Checkbox>
                                    <Checkbox value="accesibilidad">Accesibilidad</Checkbox>
                                    <Checkbox value="capacidadMentoria">Capacidad de mentoría e inspiración</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                        </div>


                        <div className="field btn_wrap btn_grid_2">
                            <Button className='primary_v2' type="primary" onClick={clearFilters}>Limpiar filtros</Button>

                            <Button className='primary' type="primary" onClick={applyFilters}>
                                Aplicar filtros
                            </Button>
                        </div>
                    </div>
                </div> */}
                <ProfessorListLeftSideBar
                    filters={filters}
                    setFilters={setFilters}
                    clearFilters={clearFilters}
                    applyFilters={applyFilters}
                />
                <div className="list_playground">
                    {renderTeacherCards()}
                </div>
            </div>
            {
                isModalVisible &&
                <ProfessorListSideBarModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
            }
        </section>
    );
};

export default FilterTeacherPage;
