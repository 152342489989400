import { Modal } from "antd";
import React from "react";
import ContactUsForm from "../../../static/Home/ContactUsSection/ContactUsForm";

const ContactUsFormModal = ({ isModalVisible, setIsModalVisible }) => {
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<div>
			<Modal
				title="Contáctanos"
				open={isModalVisible}
				onCancel={handleCancel}
				footer={null}>
				<ContactUsForm />
			</Modal>
		</div>
	);
};

export default ContactUsFormModal;
