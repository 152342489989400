import React from 'react';
import { Modal, Button } from 'antd';

const VerificationModal = ({ visible, onClose }) => {
  return (
    <Modal
      title="Nivel de verificación"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          ¡Entendido!
        </Button>
      ]}
    >
      <div className='main-container'>
        <p className='verification-modal-content'>Este dato nos permite saber que tu usuario es auténtico.</p>
        <p>Tener un nivel de verificación alto te permite</p>
        <br />
        <p>Evaluaciones ilimitadas</p>
        <br />
        <p>Realizar comentarios de forma anónima</p>
      </div>
    </Modal>
  );
};

export default VerificationModal;
