import axios from "axios";

const api = axios.create({});

const defaultHeaders = {
	"Cache-Control": "no-cache",
	"Pragma": "no-cache",
	"Expires": "0",
};

const handleApiError = (error) => {
	if (error.response) {
		throw new Error(error.response.data.message || "An error occurred");
	} else if (error.request) {
		throw new Error("No response received from the server");
	} else {
		throw new Error("An error occurred while setting up the request");
	}
};

const executeHttp = async ({ method, endpoint, data = {}, headers = {}, noHeaders, ...rest }) => {
	try {
		const res = await api({
			method,
			url: endpoint,
			data,
			headers: {
				...(noHeaders ? {} : defaultHeaders),
				...headers,
			},
			...rest,
		});

		if (res.data) {
			return res.data;
		}
	} catch (err) {
		handleApiError(err);
		throw err;
	}
};

export default executeHttp;

