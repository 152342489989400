import chatIcon from "../../../assets/icons/chat-icon.svg";
import caledarIcon from "../../../assets/icons/calendar.svg";
import graphIcon from "../../../assets/icons/graph-icon.svg";
const Feature = () => {
    return (
        <div className="container">
            <div className="inner_wrap">

            <div className="infoBox_wrap">
                
                <div className="card_box">
                    <div className="card_img">
                        <div className="icon">
                            <img src={chatIcon} alt="" />
                        </div>
                    </div>
                    <div className="card_body">
                        <h3 className="title">Característica 1</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua.
                        </p>
                    </div>
                </div>
                <div className="card_box">
                    <div className="card_img">
                        <div className="icon">
                            <img src={caledarIcon} alt="" />
                        </div>
                    </div>
                    <div className="card_body">
                        <h3 className="title">Característica 2</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua.
                        </p>
                    </div>
                </div>
                <div className="card_box">
                    <div className="card_img">
                        <div className="icon">
                            <img src={graphIcon} alt="" />
                        </div>
                    </div>
                    <div className="card_body">
                        <h3 className="title">Característica 3</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua.
                        </p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};
export default Feature;
