import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCourseAbout, getCourseBestProfessor, getCourseLabel, getCourseProfessor } from "../../store/slices/courseSlice";
import check from "../../assets/icons/right-icon.svg";
import Avtar from "../../assets/icons/avatar.svg";
import starIcon from "../../assets/icons/star-icon.svg";
import universityIcon1 from "../../assets/icons/university-1.svg";
import universityIcon2 from "../../assets/icons/university-2.svg";
import "./style.scss";
import { Button, Dropdown, Menu } from "antd";
import { dropdown_arrow } from "../../assets";

const Course = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { id } = useParams();
    const { aboutcourse, courseBestProfessor, courseLabel, courseProfessor } = useSelector((state) => state.course);
    const [tab, setTab] = useState("list");

    const [selectedKey, setSelectedKey] = useState("relevantes");

    const selectedTitle = {
        relevantes: "Más relevantes",
        antiguos: "Más antiguos primero",
        recientes: "Más recientes primero",
        alta: "Calificación alta primero",
        baja: "Calificación baja primero",
        alfabetico: "Orden alfabético",
    }[selectedKey];

    const handleMenuClick = ({ key }) => {
        setSelectedKey(key);
    };

    const menu = (
        <Menu
            className="btn_dropdown"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}>
            <Menu.Item key="relevantes">Más relevantes</Menu.Item>
            <Menu.Item key="antiguos">Más antiguos primero</Menu.Item>
            <Menu.Item key="recientes">Más recientes primero</Menu.Item>
            <Menu.Item key="alta">Calificación alta primero</Menu.Item>
            <Menu.Item key="baja">Calificación baja primero</Menu.Item>
            <Menu.Item key="alfabetico">Orden alfabético</Menu.Item>
        </Menu>
    );

    useEffect(() => {
        dispatch(getCourseAbout({ id: id }));
        dispatch(getCourseBestProfessor({ id: id }));
        dispatch(getCourseProfessor({ id: id }));
        dispatch(getCourseLabel({ id: id }));
        // dispatch(getProfessoEvaluation({ id: id }));
    }, []);

    return (
        <>
            <section className="top_semi_banner_sec"></section>
            <section className="user_prifile_sec">
                <div className="container md_container">
                    <div className="inner_wrap course_page">
                        <div className="user_profile_wrapper">
                            <div className="user_card">
                                <div className="my_info">
                                    <div className="details">
                                        <div className="my_pic">
                                            <img
                                                src={aboutcourse?.imageCourse}
                                                alt="my-Pic"
                                            />
                                        </div>
                                    </div>
                                    <div className="name">
                                        <h4 className="title">{aboutcourse?.courseName}</h4>
                                        <div className="verify">
                                            <img
                                                src={check}
                                                alt="verified"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="about_me">
                                    <div className="tab_badge">
                                        <p className="course_badge">Curso</p>
                                    </div>
                                    {aboutcourse?.careers?.map((data, index) => {
                                        return (
                                            <div
                                                className="profession"
                                                key={index}>
                                                <h6>{data?.university}</h6>
                                                <p>{data?.academicUnit}</p>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="professor_labels">
                                    {aboutcourse?.labels?.map((data, index) => {
                                        return (
                                            <p
                                                className={`label ${data?.category === "NEGATIVE" ? "negative" : "positive"}`}
                                                key={index}>
                                                {data?.name}
                                            </p>
                                        );
                                    })}
                                </div>

                                <div className="evaluations_wrap">
                                    <div className="approvals_list_wrap">
                                        <div className="approvals_list">
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={like_cyan}
                                                            alt="Like"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutcourse?.detail?.profesors || 0}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">Profesores</p>
                                                </div>
                                            </div>
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutcourse?.detail?.evaluations || 0}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">Evaluaciones</p>
                                                </div>
                                            </div>
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutcourse?.detail?.opinions || 0}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title"> Opiniones</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bottom_row">
                                    <small>Fecha de registro {aboutcourse?.registrationDateCourse}</small>
                                </div>
                            </div>
                        </div>

                        <div className="user_profile_listing ">
                            <div className="professor_navigation">
                                <div
                                    className={`nav ${tab === "list" ? "active" : ""}`}
                                    onClick={() => setTab("list")}>
                                    Sobre el curso
                                </div>
                                <div
                                    className={`nav ${tab === "evel" ? "active" : ""}`}
                                    onClick={() => setTab("evel")}>
                                    Profesores
                                </div>
                            </div>
                            {tab === "list" && (
                                <>
                                    <div className="list list_option_wrap" >
                                        <h2>Profesores mejor calificados</h2>

                                        <div className="id_card_sec">
                                            <div className="id_card_inner">
                                                {courseBestProfessor?.professors?.map((data, index) => {
                                                    return (
                                                        <div
                                                            className="id_card"
                                                            key={index}>
                                                            <div className="card-container">
                                                                <div className="rating_wrap">
                                                                    <span className="rat_box">4</span>
                                                                    <span className="star_box">
                                                                        <img
                                                                            src={starIcon}
                                                                            alt=""
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="user_info">
                                                                    <span className="profile_icon">
                                                                        <img
                                                                            src={Avtar}
                                                                            alt=""
                                                                        />
                                                                    </span>
                                                                    <span className="name">Mario Rafael Juárez González</span>
                                                                </div>
                                                                <div className="user_details">
                                                                    <div className="info_icon">
                                                                        <img
                                                                            src={universityIcon1}
                                                                            alt=""
                                                                        />
                                                                        <img
                                                                            src={universityIcon2}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="user_desc">
                                                                        <p>
                                                                            <strong>USAC</strong>, Ciencias Médicas -{" "}
                                                                        </p>
                                                                        <p>
                                                                            <strong>URL</strong>, Ciencias económicas y empresariales
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="professor_labels">
                                        <h2 className="header">Etiquetas más utilizadas</h2>
                                        <div className="content">
                                            {courseLabel?.labels?.map((data, index) => {
                                                return (
                                                    <p
                                                        className={`label ${data?.category === "Negative" ? "negative" : "positive"}`}
                                                        key={index}>
                                                        {data?.label} ({data?.count})
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                            {tab === "evel" && (
                                <>
                                    <div className="list_option_wrap">
                                        <div className="desc">
                                            <h3 className="title">Profesores que lo imparten</h3>
                                        </div>
                                        <div className="drop_box">
                                            <div className="dropdown_box">
                                                <Dropdown overlay={menu}>
                                                    <Button>
                                                        {selectedTitle}
                                                        <i className="fas fa-caret-down" />
                                                        <div className="icon_box FaAngleDown">{dropdown_arrow}</div>
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="id_card_section">
                                        <div className="id_card">
                                            {courseBestProfessor?.professors?.map((data, index) => {
                                                return (
                                                    <div
                                                        className="id_card"
                                                        onClick={()=> navigate(`/course/${id}/professor/${data?.id}`)}
                                                        key={index}>
                                                        <div className="card-container">
                                                            <div className="rating_wrap">
                                                                <span className="rat_box">4</span>
                                                                <span className="star_box">
                                                                    <img
                                                                        src={starIcon}
                                                                        alt=""
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div className="user_info">
                                                                <span className="profile_icon">
                                                                    <img
                                                                        src={Avtar}
                                                                        alt=""
                                                                    />
                                                                </span>
                                                                <span className="name">Mario Rafael Juárez González</span>
                                                            </div>
                                                            <div className="user_details">
                                                                <div className="info_icon">
                                                                    <img
                                                                        src={universityIcon1}
                                                                        alt=""
                                                                    />
                                                                    <img
                                                                        src={universityIcon2}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="user_desc">
                                                                    <p>
                                                                        <strong>USAC</strong>, Ciencias Médicas -{" "}
                                                                    </p>
                                                                    <p>
                                                                        <strong>URL</strong>, Ciencias económicas y empresariales
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Course;
