import React, { useState } from 'react';
import { Button } from 'antd';
import FormFillPage from './FormFillPage';
import ProfilePicUploadPage from './ProfileUpload';
import CareerFormFillPage from './CareerFillPage';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { useDispatch } from 'react-redux';
import { submitOnboardForm, userCareer, userUploadPhoto } from '../../store/slices/onBoardSlice';

const OnboardPage = () => {

  const [usedata, setUserdata] = useState({
    birthday: '',
    emailUniversity: '',
    gender: '',
    phoneNumber: ''
  })

  const userCareerData = {
    endDate: "05/12/23",
    idCareer: 9,
    startDate: "10/12/23"
  }

  const [uploadPhoto, setUploadPhoto] = useState({
    image: ''
  })

  const steps = [
    {
      title: 'First',
      content: <FormFillPage usedata={usedata} setUserdata={setUserdata} />,
    },
    {
      title: 'Second',
      content: <ProfilePicUploadPage setUploadPhoto={setUploadPhoto} />,
    },
    {
      title: 'Last',
      content: <CareerFormFillPage userCareerData={userCareerData} />,
    },
  ];

  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const next = () => {
    if (current === 0) {
      dispatch(submitOnboardForm(usedata));

    }
    if (current === 1) {
      dispatch(userUploadPhoto(uploadPhoto))
    }
    if (current === 2) {
      dispatch(userCareer(userCareerData))
    }
    if (current === steps.length - 1) {
      afterOnboard();
    } else {
      setCurrent(current + 1);
    }
  };

  const afterOnboard = () => {
    navigate('/home');
  };

  const contentStyle = {
    lineHeight: '50px',
    textAlign: 'center',
    marginTop: 16,
  };

  return (
    <>
      <div className="profile_upload_wrap">
        <div style={contentStyle}>{steps[current].content}</div>

        <div className="footer_bottom_onboard">
          <div className="container">
            <div className="footer_bottom">
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {current !== steps.length - 1 && (
                  <Button style={{ margin: '0 8px' }} onClick={() => afterOnboard()}>
                    Ahora no
                  </Button>
                )}
                <Button
                  type="primary"
                  style={{ marginLeft: 'auto' }}
                  onClick={next}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardPage;
