import { Button, Checkbox, Form, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ValidateMessages } from "../../../helpers";
import { useTranslation } from "react-i18next";

const RegistrationForm = ({data, handleChange, isLoading, onSignUpSubmit, passValidate}) => {
    const { t: commonT } = useTranslation('common'); 
    const { t: registerPageT } = useTranslation('registerPage'); 

    const validateSpecialCharacters = (rule, value, callback) => {
        const regex = /^[A-Za-z\d#$&!*]+$/;
        if (regex.test(value)) {
            callback();
        } else {
            callback('¡Se requiere al menos un carácter especial ( #, $, !, &, *)!');
        }
    };
    return (
        <Form className="auth-form" layout="vertical" name="register-form" onFinish={onSignUpSubmit} validateMessages={ValidateMessages}>
            <div className="double-col-field">
                <div className="field">
                    <Form.Item
                        label={registerPageT('NAME')}
                        name='name'
                        rules={[{required : true}]}
                    >
                        <Input
                            type="text"
                            name="name"
                            placeholder={registerPageT('NAME_LABEL')}
                            value={data.name}
                            onChange={(e) => handleChange(e, "name")}
                        />
                    </Form.Item>
                </div>
                <div className="field">
                    <Form.Item
                        label={registerPageT('LAST_NAME')}
                        name='lastName'
                        rules={[{required : true}]}
                    >
                        <Input
                            type="text"
                            name="lastName"
                            placeholder={registerPageT('LAST_NAME_LABEL')}
                            value={data.lastName}
                            onChange={(e) => handleChange(e, "lastName")}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className="field">
                <Form.Item
                    label={commonT('EMAIL')}
                    name='email'
                    rules={[{ required: true, }, { type: "email" }]}
                >
                    <div>
                        <span className="note-text">
                            {registerPageT('EMAIL_INFO')}
                            <Link className="info" style={{textDecoration:'none', cursor:'auto'}}>{registerPageT('EMAIL_VERIFICATION_LEVEL')}</Link>
                        </span>
                        <Input
                            name="email"
                            type="email"
                            placeholder="tunombre@email.com"
                            value={data.email}
                            onChange={(e) => handleChange(e, "email")}
                        />
                    </div>
                </Form.Item>
            </div>
            <div className="field">
                <Form.Item
                    label={registerPageT('USER_NAME')}
                    name='userName'
                    rules={[{required : true}]}
                >
                    <Input
                        type="text"
                        name="userName"
                        placeholder={registerPageT('USER_NAME_LABEL')}
                        value={data.userName}
                        onChange={(e) => handleChange(e, "userName")}
                    />
                </Form.Item>
            </div>
            <div className="field">
                <Form.Item
                    label={commonT('PASSWORD')}
                    name='password'
                    rules={[
                        { required: true,},
                        { min: 8, message: '¡La longitud mínima es de 8 caracteres!' },
                        { pattern: /[a-z]/, message: '¡Se requiere al menos una letra minúscula!' },
                        { pattern: /[A-Z]/, message: '¡Se requiere al menos una letra mayúscula!' },
                        { pattern: /\d/, message: '¡Se requiere al menos un dígito!' },
                        { pattern: /[#$&!*]/, message: '¡Se requiere al menos un carácter especial ( #, $, &, !, *)!' },
                        { validator: validateSpecialCharacters  }
                    ]}
                >
                    <Input.Password
                        placeholder={commonT('PASSWORD_LABEL')}
                        name="password"
                        value={data.password}
                        onChange={(e) => handleChange(e, "password")}
                        autoComplete="new-password"
                    />
                </Form.Item>
            </div>
            <div className="password-condition">
                <div className={`condition ${passValidate.lengthValid ? "complete" : ""}`}>
                    <Checkbox checked={passValidate.lengthValid} disabled />8 {registerPageT('CHARACTERS')}
                </div>
                <div className={`condition ${passValidate.numberValid ? "complete" : ""}`}>
                    <Checkbox checked={passValidate.numberValid} disabled />1 {registerPageT('NUMBER')} (0-9)
                </div>
                <div className={`condition ${passValidate.lowercaseValid ? "complete" : ""}`} >
                    <Checkbox checked={passValidate.lowercaseValid} disabled />1 {registerPageT('LOWER_CASE_LETTER')} (a-z)
                </div>
                <div className={`condition ${passValidate.specialCharValid ? "complete" : ""}`} >
                    <Checkbox checked={passValidate.specialCharValid} disabled />1 {registerPageT('SPECIAL_CHARACTER')}(./$&*#!)
                </div>
                <div className={`condition ${passValidate.uppercaseValid ? "complete" : ""}`}>
                    <Checkbox checked={passValidate.uppercaseValid} disabled />1 {registerPageT('UPPER_CASE_LETTER')} (A-Z)
                </div>
            </div>
            <div className="action-field">
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                    >
                        {commonT('REGISTER')}
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};

export default RegistrationForm;
