import { initializeApp } from "firebase/app";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    getAuth,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FB_API_KEY,
//     authDomain: "saikit-review.firebaseapp.com",
//     projectId: "saikit-review",
//     storageBucket: "saikit-review.appspot.com",
//     messagingSenderId: process.env.REACT_APP_FB_SENDER_ID,
//     appId: process.env.REACT_APP_FB_APP_ID,
// };

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: "saikit-authentication.firebaseapp.com",
    projectId: "saikit-authentication",
    storageBucket: "saikit-authentication.appspot.com",
    messagingSenderId: process.env.REACT_APP_FB_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const Auth = getAuth(app);
export const Db = getFirestore(app);
export const GoogleProvider = new GoogleAuthProvider();
export const FacebookProvider = new FacebookAuthProvider();

export default app;
