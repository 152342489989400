import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import executeHttp from "../../services/api";
import { API_URL } from "../../constants/api-url";

const initialState = {
    aboutcourse: {},
    courseBestProfessor: {},
    courseLabel: {},
    courseProfessor: {},
    courseDetailActivity: {},
};

export const getCourseAbout = createAsyncThunk("course/getCourseAbout", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.COURSE.ABOUT}/${id}/about`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

export const getCourseBestProfessor = createAsyncThunk("course/getCourseBestProfessor", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.COURSE.BEST_PROFESSOR}/${id}/best-professor`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

export const getCourseProfessor = createAsyncThunk("course/getCourseProfessor", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.COURSE.PROFESSOR}/${id}/professor`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

export const getCourseLabel = createAsyncThunk("course/getCourseLabel", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.COURSE.LABEL}/${id}/label`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

export const getCourseEvaluation = createAsyncThunk("course/getCourseEvaluation", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.EVOLUTION.PROFESSOR_EVOLUTION}/${id}`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

const courseSlice = createSlice({
    name: "course",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCourseAbout.fulfilled, (state, action) => {
            state.aboutcourse = action.payload.data;
        });
        builder.addCase(getCourseBestProfessor.fulfilled, (state, action) => {
            state.courseBestProfessor = action.payload.data;
        });
        builder.addCase(getCourseProfessor.fulfilled, (state, action) => {
            state.courseProfessor = action.payload.data;
        });
        builder.addCase(getCourseLabel.fulfilled, (state, action) => {
            state.courseLabel = action.payload.data;
        });
        // builder.addCase(getCourseEvaluation.fulfilled, (state, action) => {
        //     state.courseEvaluation = action.payload.data;
        // });
    },
});

export const {} = courseSlice.actions;
export default courseSlice.reducer;
