import React from "react";
import { Button, FloatButton, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ContactUsForm from "../../static/Home/ContactUsSection/ContactUsForm";
import { Collapse } from "antd";
import { dropdown_arrow } from "../../assets";
import videoBG from "../../assets/images/videBG.png";
import "./style.scss";

const Feq = () => {
    return (
        <>
            <section className="simple_banner">
                <div className="container">
                    <div className="sec_head">
                        <h1 className="title">
                            Preguntas <br />{" "}
                            <span className="highlight2">Frecuentes</span>
                        </h1>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut magna
                            aliqua.
                        </p>
                    </div>
                </div>
            </section>

            <section className="simple_banner faq_tabBadge_sec">
                <div className="container semi_container">
                    <div className="search_btn_wrapper">
                        <div className="btn_list">
                            <Button className="active">Todos</Button>
                            <Button>Pedagogía</Button>
                            <Button>Estudiantes</Button>
                            <Button>Categoría</Button>
                            <Button>Categoría</Button>
                        </div>

                        <div className="category_search_wrapper">
                            <div className="search-box">
                                <Input
                                    placeholder="Consulta por palabra clave"
                                    prefix={<SearchOutlined />}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="faq_collapse collapse_wrapper">
                        <Collapse
                            items={[
                                {
                                    key: "1",
                                    label: <h4 className="title">Pregunta Frecuente 1</h4>,
                                    children: (
                                        <div className="collapse_wrap">
                                            <div className="top_collapse_row">
                                                <div className="tab_badge_wrapper">
                                                    <div class="tab_badge">
                                                        <p>Funcionalidad</p>
                                                    </div>
                                                </div>

                                                <div className="conrent">
                                                    <p>
                                                        Este factor describe la
                                                        percepción del profesor
                                                        como un mentor para los
                                                        alumnos, sea por su
                                                        trayectoria profesional
                                                        y académica, por su
                                                        metodología de
                                                        enseñanza, o por
                                                        cualquier otro elemento
                                                        que los distinga y que
                                                        inspire a los
                                                        estudiantes a ser
                                                        mejores personas y
                                                        ciudadanos.
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    <img
                                                        src={videoBG}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                
                                {
                                    key: "2",
                                    label: <h4 className="title">Pregunta Frecuente 2</h4>,
                                    children: (
                                        <div className="collapse_wrap">

                                            <div className="top_collapse_row">
                                                <div className="tab_badge_wrapper">
                                                    <div class="tab_badge">
                                                        <p>Funcionalidad</p>
                                                    </div>
                                                </div>

                                                <div className="conrent">
                                                    <p>
                                                        Este factor describe la
                                                        percepción del profesor
                                                        como un mentor para los
                                                        alumnos, sea por su
                                                        trayectoria profesional
                                                        y académica, por su
                                                        metodología de
                                                        enseñanza, o por
                                                        cualquier otro elemento
                                                        que los distinga y que
                                                        inspire a los
                                                        estudiantes a ser
                                                        mejores personas y
                                                        ciudadanos.
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    <img
                                                        src={videoBG}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                
                                {
                                    key: "3",
                                    label: <h4 className="title">Pregunta Frecuente 3</h4>,
                                    children: (
                                        <div className="collapse_wrap">

                                            <div className="top_collapse_row">
                                                <div className="tab_badge_wrapper">
                                                    <div class="tab_badge">
                                                        <p>Funcionalidad</p>
                                                    </div>
                                                </div>

                                                <div className="conrent">
                                                    <p>
                                                        Este factor describe la
                                                        percepción del profesor
                                                        como un mentor para los
                                                        alumnos, sea por su
                                                        trayectoria profesional
                                                        y académica, por su
                                                        metodología de
                                                        enseñanza, o por
                                                        cualquier otro elemento
                                                        que los distinga y que
                                                        inspire a los
                                                        estudiantes a ser
                                                        mejores personas y
                                                        ciudadanos.
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    <img
                                                        src={videoBG}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                
                                {
                                    key: "4",
                                    label: <h4 className="title">Pregunta Frecuente 4</h4>,
                                    children: (
                                        <div className="collapse_wrap">

                                            <div className="top_collapse_row">
                                                <div className="tab_badge_wrapper">
                                                    <div class="tab_badge">
                                                        <p>Funcionalidad</p>
                                                    </div>
                                                </div>

                                                <div className="conrent">
                                                    <p>
                                                        Este factor describe la
                                                        percepción del profesor
                                                        como un mentor para los
                                                        alumnos, sea por su
                                                        trayectoria profesional
                                                        y académica, por su
                                                        metodología de
                                                        enseñanza, o por
                                                        cualquier otro elemento
                                                        que los distinga y que
                                                        inspire a los
                                                        estudiantes a ser
                                                        mejores personas y
                                                        ciudadanos.
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    <img
                                                        src={videoBG}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                
                                {
                                    key: "5",
                                    label: <h4 className="title">Pregunta Frecuente 5</h4>,
                                    children: (
                                        <div className="collapse_wrap">

                                            <div className="top_collapse_row">
                                                <div className="tab_badge_wrapper">
                                                    <div class="tab_badge">
                                                        <p>Funcionalidad</p>
                                                    </div>
                                                </div>

                                                <div className="conrent">
                                                    <p>
                                                        Este factor describe la
                                                        percepción del profesor
                                                        como un mentor para los
                                                        alumnos, sea por su
                                                        trayectoria profesional
                                                        y académica, por su
                                                        metodología de
                                                        enseñanza, o por
                                                        cualquier otro elemento
                                                        que los distinga y que
                                                        inspire a los
                                                        estudiantes a ser
                                                        mejores personas y
                                                        ciudadanos.
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="img_wrapper">
                                                <div className="img_wrap">
                                                    <img
                                                        src={videoBG}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                            expandIconPosition={"end"}
                            expandIcon={({ isActive }) => dropdown_arrow}
                        />
                    </div>
                </div>
            </section>

            <section className="contact_form_sec contact-us-section">
                <div className="container">

                    <div className="sec_head">
                        <h2 className="title"> ¿Necesitas más ayuda? </h2>
                        <p>Si no encontraste la respuesta que necesitabas escríbenos puedes escribirnos, nosotros te contactaremos directamente en la mayor brevedad posible.
                        </p>
                    </div>

                    <ContactUsForm />
                </div>
            </section>

            <FloatButton.BackTop
                shape="square"
                icon={
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.01782 23.3539L17.7133 12.6584L28.4087 23.3539"
                            stroke="#5C30A1"
                            stroke-width="3.50889"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
        </>
    );
};

export default Feq;
