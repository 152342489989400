// RouteList.jsx
import { BrowserRouter, Route, Routes } from "react-router-dom";
import VerifyEmail from "../../pages/Auth/Register/VerifyEmail";
import ForgotPassword from "../../pages/Auth/ForgotPassword";
import PageNotFound from "../../pages/PageNotFound";
import Login from '../../pages/Auth/LogIn/index';
import { PATH_LIST } from "../../constants/paths";
import MainLayout from '../../layouts/MainLayout';
import StaticHome from '../../static/Home';
import Register from "../../pages/Auth/Register";
import StaticLayout from "../../layouts/StaticLayout";
import AboutUs from "../../static/AboutUs";
import Updates from "../../static/Updates";
import Update from "../../static/Updates/Update";
import Methology from "../../pages/Methology";
import EvaluationFactors from "../../pages/EvaluationFactors";
import TeacherMaterial from "../../pages/TeacherMaterial";
import Faq from "../../pages/Faq";
import OnboardPage from "../../pages/Onboard";
import ProfilePicUploadPage from "../../pages/Onboard/ProfileUpload";
import Home from "../../pages/Home";
import EmailNotification from "../../components/EmailNotification";
import FilterTeacherPage from "../../components/FilterTeacherPage";
import UserProfile from "../../components/UserProfile";
import Professor from "../../pages/Professor";
import Course from "../../pages/Course";
import User from "../../pages/User";
import CourseProfessor from "../../pages/CourseProfessor";

const RouteList = () => {
    return (
        <BrowserRouter>
        <Routes>
            <Route path={PATH_LIST.USER.LOGIN} element={<Login />} />
            <Route path={PATH_LIST.USER.REGISTER} element={<Register />} />
            <Route path={PATH_LIST.USER.VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route path={PATH_LIST.USER.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={PATH_LIST.USER.PAGE_NOT_FOUND} element={<PageNotFound />} />

            <Route path={PATH_LIST.USER.COMMON} element={<StaticLayout />}>
                <Route index element={<StaticHome />} />
                <Route path={PATH_LIST.USER.ABOUT_US} element={<AboutUs />} />
                <Route path={PATH_LIST.USER.UPDATES} element={<Updates />} />
                <Route path={PATH_LIST.USER.UPDATE_ITEM} element={<Update />} />
                <Route path={PATH_LIST.USER.METHOLOGY} element={<Methology />} />
                <Route path={PATH_LIST.USER.EVALUATION_FACTORS} element={<EvaluationFactors />} />
                <Route path={PATH_LIST.USER.TEACHER_MATERIAL} element={<TeacherMaterial />} />
                <Route path={PATH_LIST.USER.FAQ} element={<Faq />} />
                <Route path={PATH_LIST.USER.ONBOARD} element={<OnboardPage />} />
                <Route path={PATH_LIST.USER.PROFILE_UPLOADATION} element={<ProfilePicUploadPage />} />
                <Route path={PATH_LIST.USER.PROFESSOR} element={<Professor />} />
                <Route path={PATH_LIST.USER.COURSE} element={<Course />} />
                <Route path={PATH_LIST.USER.USER} element={<User />} />
                <Route path={PATH_LIST.USER.COURSE_PROFESSOR} element={<CourseProfessor />} />
            </Route>
            
            <Route path={PATH_LIST.USER.COMMON} element={<MainLayout />}>
                <Route path={PATH_LIST.USER.HOME} element={<Home />} />
                <Route path={PATH_LIST.USER.ABOUT_US} element={<AboutUs />} />
                <Route path={PATH_LIST.USER.UPDATES} element={<Updates />} />
                <Route path={PATH_LIST.USER.UPDATE_ITEM} element={<Update />} />
                <Route path={PATH_LIST.USER.METHOLOGY} element={<Methology />} />
                <Route path={PATH_LIST.USER.EVALUATION_FACTORS} element={<EvaluationFactors />} />
                <Route path={PATH_LIST.USER.TEACHER_MATERIAL} element={<TeacherMaterial />} />
                <Route path={PATH_LIST.USER.FAQ} element={<Faq />} />
                <Route path={PATH_LIST.USER.USER_PROFILE} element={<UserProfile />} />
                <Route path={PATH_LIST.USER.EMAIL_NOTIFICATION} element={<EmailNotification />} />
                <Route path={PATH_LIST.USER.PROFESSOR_LIST} element={<FilterTeacherPage />} />
                <Route path={PATH_LIST.USER.PROFESSOR} element={<Professor />} />
                <Route path={PATH_LIST.USER.COURSE} element={<Course />} />
                <Route path={PATH_LIST.USER.USER} element={<User />} />
                <Route path={PATH_LIST.USER.COURSE_PROFESSOR} element={<CourseProfessor />} />
            </Route>

           
        </Routes>
    </BrowserRouter>
    );
};

export default RouteList;
