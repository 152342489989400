import { Navigate, Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { PATH_LIST } from "../../constants/paths";
import StaticHeader from "../../components/StaticHeader";
const MainLayout = () => {
	const Token = localStorage.getItem("Token");
	return Token ? (
		<>
			<div className="main_wrapper">
				{Token ? <Header /> : <StaticHeader />}
				<Outlet />
			</div>
			<Footer />
		</>
	) : (
		<Navigate to={PATH_LIST.USER.COMMON} />
	);
};
export default MainLayout;
