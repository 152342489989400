const BASE_URL = process.env.REACT_APP_SERVICE_URL;
const API_V = "v1";

export const API_URL = {
    USER: {
        LOGIN: `${BASE_URL}/${API_V}/user/login`, //post work
        FORGOT_PASSWORD: `${BASE_URL}/${API_V}/user/forgot-password`, //post work
        FORGOT_PASSWORD_IDENTIFIER: `${BASE_URL}/${API_V}/user/forgot-password/:key`, //get work
        RESET_PASSWORD: `${BASE_URL}/${API_V}/user/reset-password`, //post work
        REGISTER: `${BASE_URL}/${API_V}/user/register`, //POST work
        VERIFY_EMAIL: `${BASE_URL}/${API_V}/user/verify-email`, //POST work 
        VERIFY_EMAIL_IDENTIFIER: `${BASE_URL}/${API_V}/user/verify-email/:key`, //GET
        PROFILE: `${BASE_URL}/${API_V}/user/profile`, //POST
        UPLOAD_PHOTO_FILE: `${BASE_URL}/${API_V}/user/upload-photo-file`, //POST
        UPLOAD_PHOTO: `${BASE_URL}/${API_V}/user/upload-photo`, //POST
        CAREER: `${BASE_URL}/${API_V}/user/career`, //POST
        PERMISSIONS: `${BASE_URL}/${API_V}/user/permissions`, //GET
        USER_ABOUT: `${BASE_URL}/${API_V}/user`, //GET
        PROFILE_STATUS: `${BASE_URL}/${API_V}/user/:id/profile/status`, //GET
        LOGOUT: `${BASE_URL}/${API_V}/user/logout`, //POST
        USER_PROFILE: `${BASE_URL}/${API_V}/:id/profile`, //PUT
        UPDATE_PASSWORD: `${BASE_URL}/${API_V}/user/account/update-password`, //POST
        USER_CARRIER: `${BASE_URL}/${API_V}/user/:id/career`, //GET
        ANONYMOUS_USER: `${BASE_URL}/${API_V}/user/:id/anonymous`, //PATCH
        USER_ACTIVITY: `${BASE_URL}/${API_V}/user/:id/activity`, //GET
        UPDATE_USER_ACTIVITY: `${BASE_URL}/${API_V}/user/:id/activity`, //POST
        USER_PHOTO: `${BASE_URL}/${API_V}/user/:id/photo`, //GET
        DISASSIOCIATE_USER: `${BASE_URL}/${API_V}/user/:id/disassociate`, //POST
        ASSOCIATED_USER: `${BASE_URL}/${API_V}/user/{idUser}/associated-account`, //GET
        DEACTIVATE_USER: `${BASE_URL}/${API_V}/user/account/{idUser}/deactivate`, //POST
        DELETE_USER: `${BASE_URL}/${API_V}/user/account/{idUser}/delete`, //POST
    },
    CAREER: {
        CAREER: `${BASE_URL}/${API_V}/career` //GET
    },
    UNIVERSITIES: {
        UNIVERSITIES: `${BASE_URL}/${API_V}/universities` //GET
    },
    SEARCH: {
        SEARCH: `${BASE_URL}/${API_V}/search`, //POST
        SERCH_RECENTS: `${BASE_URL}/${API_V}/search/recents`, //GET
        SERCH_DROPDOWN: `${BASE_URL}/${API_V}/search/dropdown-options`, //GET
        DELETR_SERCH_RECENTS: `${BASE_URL}/${API_V}/search/recents`, //DELETE
        DELETR_SERCH_RECENTS_ID: `${BASE_URL}/${API_V}/search/recents` //DELETE
    },
    EVOLUTION: {
        CAREER: `${BASE_URL}/${API_V}/evaluation/careers`, //GET
        FACTORS: `${BASE_URL}/${API_V}/evaluation/factors`, //GET
        lABEL: `${BASE_URL}/${API_V}/evaluation/label`, //GET
        EVOLUTION: `${BASE_URL}/${API_V}/evaluation`, //POST
        REPORT: `${BASE_URL}/${API_V}/evaluation/report`, //POST
        USER_EVOLUTION: `${BASE_URL}/${API_V}/evaluation/user`, //GET
        REACTION: `${BASE_URL}/${API_V}/evaluation/{idEvaluation}/reaction`, //POST
        HISTORY: `${BASE_URL}/${API_V}/evaluation/{idEvaluation}/historical`, //GET
        DETAILS: `${BASE_URL}/${API_V}/evaluation/{idEvaluation}/detail`, //GET
        GET_EVOLUTION: `${BASE_URL}/${API_V}/evaluation/{idEvaluation}`, //GET
        UPDATE_EVOLUTION: `${BASE_URL}/${API_V}/evaluation/{idEvaluation}`, //PUT
        DELETE_EVOLUTION: `${BASE_URL}/${API_V}/evaluation/{idEvaluation}`, //DELETE
        PROFESSOR_EVOLUTION: `${BASE_URL}/${API_V}/evaluation/professor`, //GET
        PROFESSOR_EVOLUTION_COURSE: `${BASE_URL}/${API_V}/evaluation/course/{idCourse}/professor/{idProfessor}`, //GET
    },
    GENERAL: {
        TNC: `${BASE_URL}/${API_V}/general/terms-and-conditions`, //GET
        VERIFICATION_LEVEL: `${BASE_URL}/${API_V}/general/verification-levels`, //GET
        AVATARS: `${BASE_URL}/${API_V}/general/avatars`, //GET
        WELCOME: `${BASE_URL}/${API_V}/general/welcome`, //GET
        NEW_FEATURE: `${BASE_URL}/${API_V}/general/new-features`, //GET
        METHOLOGY: `${BASE_URL}/${API_V}/general/methodology`, //GET
        ABOUT_US: `${BASE_URL}/${API_V}/general/about-us`, //GET
        FAQ: `${BASE_URL}/${API_V}/general/faq`, //GET
        CONTACT_US: `${BASE_URL}/${API_V}/general/contact-us`, //POST
    },
    NOTIFICATION: {
        GET: `${BASE_URL}/${API_V}/notification`, //GET
        DELETE: `${BASE_URL}/${API_V}//notification/mark-several-as-delete`, //DELETE
        DELETE_ID: `${BASE_URL}/${API_V}/notification/{idNotification}`, //DELETE
        PATCH: `${BASE_URL}/${API_V}/notification/mark-all-read`, //PATCH
        PATCH_ID: `${BASE_URL}/${API_V}/notification/mark-as-read`, //PATCH
    },
    ADVERTISEMENT_AND_ARTICLES: {
        PLATFORM_AADVERTISEMENT: `${BASE_URL}/${API_V}/platform_advertisement`, //GET
        ARTICLE_TOPIC: `${BASE_URL}/${API_V}/article/{topic}`, //GET
        ARTICLE_ID: `${BASE_URL}/${API_V}/article/{idArticle}`, //GET
    },
    PROFESSOR: {
        ABOUT: `${BASE_URL}/${API_V}/professor`, //GET
        SCORE: `${BASE_URL}/${API_V}/professor`, //GET
        DETAIL_ACTIVITY: `${BASE_URL}/${API_V}/professor`, //GET
        LABEL: `${BASE_URL}/${API_V}/professor`, //GET
        PERFORMANCE: `${BASE_URL}/${API_V}/professor/{idProffesor}/performance`, //GET
        LABEL_ID: `${BASE_URL}/${API_V}/course/{idCourse}/professor/{idProffesor}/label`, //GET
    },
    COURSE: {
        ABOUT: `${BASE_URL}/${API_V}/course`, //GET
        BEST_PROFESSOR: `${BASE_URL}/${API_V}/course`, //GET
        LABEL: `${BASE_URL}/${API_V}/course`, //GET
        PROFESSOR: `${BASE_URL}/${API_V}/course`, //GET
        ABOUT_PROFESSOR: `${BASE_URL}/${API_V}/course`, //GET
        PROFESSOR_SCORE: `${BASE_URL}/${API_V}/course/{idCourse}/professor/{idProffesor}/score`, //GET
        DETAIL_ACTIVITY: `${BASE_URL}/${API_V}/course/{idCourse}/professor/{idProffesor}/detail-activity`, //GET
        PERFORMANCE: `${BASE_URL}/${API_V}/course/{idCourse}/professor/{idProffesor}/performance`, //GET
        PROFESSOR_COURSE: `${BASE_URL}/${API_V}/user/{idUser}/course/{idCourse}/professor/{idProfessor}`, //GET
    },
    PROFESSOR_MATERIAL: {
        GET: `${BASE_URL}/${API_V}/professor-material`, //GET
        REACTIONS: `${BASE_URL}/${API_V}/professor-material/{idMaterial}/reaction`, //POST
        CATEGORY: `${BASE_URL}/${API_V}/professor-material/{category}`, //GET
    }
}

