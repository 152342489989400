import artwork from '../../assets/images/artwork.svg'
import tableartwork from '../../assets/images/tablet-artwork.svg'

const AuthLeft = ()=>{
	return(
		<div className="auth-left">
			<img className='desktop-arc' src={artwork} alt="artwork" />
			<img className='table-arc' src={tableartwork} alt="tabletartwork" />
		</div>
	)
}

export default AuthLeft;