import React, { useEffect, useState } from "react";
import logo from "../../assets/icons/logo.svg";
// import hamburger from "../../assets/icons/hamburger.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "../Header/style.scss";
import { PATH_LIST } from "../../constants/paths";
import { close_icon, hamburger, search_icon } from "../../assets";
import { useTranslation } from "react-i18next";
import SearchItem from "../Common/SearchItem";

const StaticHeader = () => {
	const navigate = useNavigate();
	const [showNav, setShowNav] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const { t: StaticHeaderT } = useTranslation("staticHeader");
	const { t: CommonT } = useTranslation("common");

	useEffect(() => {
		if (showNav || showSearch) {
			document.body.classList.add("no_scroll");
		} else {
			document.body.classList.remove("no_scroll");
		}
	}, [showNav, showSearch]);
	return (
		<header className="header-container">
			<div className="container">
				<div className="inner_wrap">
					<div className="left_col">
						<div className="hamburger hide_in_desktop" onClick={() => setShowNav(true)}>
							{hamburger}
						</div>

						<div className="site_logo">
							<img src={logo} alt="logo" />
						</div>
					</div>

					<div className="center_col">
						{/* to show nav add/remove this class "show_nav" */}
						<nav className={`${showNav ? "show_nav" : ""}`}>
							<div className="inner_nav">
								<div className="top_nav hide_in_desktop">
									<div className="site_logo">
										<img src={logo} alt="logo" />
									</div>
									<div className="close" onClick={() => setShowNav(false)}>
										{close_icon}
									</div>
								</div>
								<div className="navigation">
									<NavLink to={PATH_LIST.USER.COMMON}>
										{StaticHeaderT("WHAT_IS_SAIKIT")}
									</NavLink>
									<NavLink to={PATH_LIST.USER.ABOUT_US}>{StaticHeaderT("US")}</NavLink>
								</div>
							</div>
						</nav>

						<div className="hide_in_mobile">
							<SearchItem />
						</div>
					</div>

					<div className="right_col">
						<div className="auth-button">
							<Button
								className="hide_in_mobile"
								type="text"
								onClick={() => navigate(PATH_LIST.USER.LOGIN)}
							>
								{CommonT("LOGIN")}
							</Button>
							<Button type="primary" onClick={() => navigate(PATH_LIST.USER.REGISTER)}>
								{" "}
								{CommonT("REGISTER")}
							</Button>
						</div>
						<div className="search-box hide_in_desktop">
							<div className="search_icon" onClick={() => setShowSearch(true)}>
								{search_icon}
							</div>
							<div className={`search_wrapper ${showSearch ? "show_search" : ""}`}>
								<div className="container">
									<div className="inner_wrap">
										<div className="search-box">
											<Input
												placeholder="Busca un curso o profesor"
												prefix={<SearchOutlined />}
											/>
										</div>
										<div className="close" onClick={() => setShowSearch(false)}>
											{close_icon}
										</div>
									</div>
									<div className="search_data_wrapper">
										<div className="data_wrap">{/* searched data */}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default StaticHeader;
