import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// english words
import enCommon from './locales/en/common.json';
import enLogin from './locales/en/login.json';
import enRegister from './locales/en/register.json';
import enForgotPassword from './locales/en/forgot-password.json';
import enVerifyEmail from './locales/en/verify-email.json';
import enStaticHeader from './locales/en/static-header.json';

// spanisg words
import esCommon from './locales/es/common.json';
import esLogin from './locales/es/login.json';
import esRegister from './locales/es/register.json';
import esForgotPassword from './locales/es/forgot-password.json';
import esVerifyEmail from './locales/es/verify-email.json';
import esStaticHeader from './locales/es/static-header.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
        en: {
            common: enCommon,
            loginPage : enLogin,
            registerPage : enRegister,
            forgotPassword : enForgotPassword,
            verifyEmail : enVerifyEmail,
            staticHeader : enStaticHeader
        },
        es: {
            common: esCommon,
            loginPage : esLogin,
            registerPage : esRegister,
            forgotPassword : esForgotPassword,
            verifyEmail : esVerifyEmail,
            staticHeader : esStaticHeader
        },
        },
        lng: 'es', 
        fallbackLng: 'es', 
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
