import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Intrested_Updates, New_Updates, Saikit_Community } from "../../../constants/updates-data";
import { FloatButton } from "antd";

const Update = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [data, setData] = useState([]);

	useEffect(() => {
		const intrestedUpdatesData = Intrested_Updates.data.filter((data) => data._id === id);
		const saikitCommunityData = Saikit_Community.data.filter((data) => data._id === id);
		const newUpdatesData = New_Updates.data.filter((data) => data._id === id);

		if (newUpdatesData) {
			setData(newUpdatesData);
		} else if (saikitCommunityData) {
			setData(saikitCommunityData);
		} else {
			setData(intrestedUpdatesData);
		}
	}, [id]);

	return (

		<>
		
		
		<div className="bulk_grid_wrapper">
			<div className="details_inner_wrap">
				{New_Updates?._id === id && (
					<>
						<section className="sm_mini_banner">
							<div className="container semi_container">
								<h2 className="title">{New_Updates?.main_title}</h2>
							</div>
						</section>

						<section className="grid_sec card_grid_sec">
							<div className="container semi_container">
								<div className="card_inner_wrapper">
									{New_Updates?.data?.map((data, id) => {
										return (
											<div
												className="card"
												key={id}
												onClick={() => navigate(`/update/${data?._id}`)}
											>
												<div className="inner_card">
													<div className="img_wrapper">
														<div className="img_wrap"></div>
													</div>
													<div className="card_body">
														<h3 className="title">
															{data.article_title}
														</h3>
														<div className="collapse_wrapper">
															<p>
																<strong>
																	{data?.author_name},
																</strong>{" "}
																{data?.publish_date}
															</p>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</section>
					</>
				)}

				{Intrested_Updates?._id === id && (
					<>
						<section className="sm_mini_banner">
							<div className="container semi_container">
								<h2 className="title">{Intrested_Updates?.main_title}</h2>
							</div>
						</section>
						<section className="grid_sec card_grid_sec">
							<div className="container semi_container">
								<div className="card_inner_wrapper">
									{Intrested_Updates?.data?.map((data, id) => {
										return (
											<div
												className="card"
												key={id}
												onClick={() => navigate(`/update/${data?._id}`)}
											>
												<div className="inner_card">
													<div className="img_wrapper">
														<div className="img_wrap"></div>
													</div>
													<div className="card_body">
														<h3 className="title">
															{data.article_title}
														</h3>
														<div className="collapse_wrapper">
															<p>
																<strong>
																	{data?.author_name},
																</strong>{" "}
																{data?.publish_date}
															</p>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</section>
						
						
					</>
				)}

				{Saikit_Community?._id === id && (
					<>
						<section className="sm_mini_banner">
							<div className="container semi_container">
								<h2 className="title">{Saikit_Community?.main_title}</h2>
							</div>
						</section>
						<section className="grid_sec card_grid_sec">
							<div className="container semi_container">
								<div className="card_inner_wrapper">
									{Saikit_Community?.data?.map((data, id) => {
										return (
											<div
												className="card"
												key={id}
												onClick={() => navigate(`/update/${data?._id}`)}
											>
												<div className="inner_card">
													<div className="img_wrapper">
														<div className="img_wrap"></div>
													</div>
													<div className="card_body">
														<h3 className="title">
															{data.article_title}
														</h3>
														<div className="collapse_wrapper">
															<p>
																<strong>
																	{data?.author_name},
																</strong>{" "}
																{data?.publish_date}
															</p>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</section>
					</>
				)}

				{data &&
					data.map((data, id) => {
						return (
							<div className="card" key={id} onClick={() => navigate(`/update/${data?._id}`)}>
								<div className="inner_card">
									<div className="img_wrapper">
										<div className="img_wrap"></div>
									</div>
									<div className="card_body">
										<h3 className="title">
											{data.article_title}
										</h3>
										<div className="collapse_wrapper">
											<p>
												<strong>
													{data?.author_name},
												</strong>{" "}
												{data?.publish_date}
											</p>
										</div>
									</div>
								</div>
							</div>
						);
				})}
			</div>
		</div>
		<FloatButton.BackTop
                shape="square"
                icon={
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.01782 23.3539L17.7133 12.6584L28.4087 23.3539"
                            stroke="#5C30A1"
                            stroke-width="3.50889"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
		</>
	);
};

export default Update;
