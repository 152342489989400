import { Button, Modal } from "antd";
import React, { useEffect } from "react";
import { brandLogo, emoji_five_star_colored, emoji_four_star_colored, emoji_one_star_colored, emoji_three_star_colored, emoji_two_star_colored, energy, gray_star, outlineStar, thumbsUp } from "../../../assets";
import { useDispatch } from "react-redux";

const ThankYouModal = ({ isModalOpen, setIsModalOpen, closeFunction, cancelFunction }) => {
	const dispatch = useDispatch()
	useEffect(()=> {
		// dispatch(addEvaluation())
	})
	return (
		<Modal
			title=""
			className="thankyou_modal comm_inner_modal"
			open={isModalOpen}
			onOk={() => setIsModalOpen(false)}
			onCancel={() => setIsModalOpen(false)}
			centered
			footer={[
				<div className="btn_wrap btn_grid_2">
					<Button key="back" onClick={closeFunction} className="primary_v2" type="primary">
						Regresar a inicio
					</Button>
					<Button key="submit" onClick={cancelFunction} className="primary" type="primary">
						Nueva evaluación
					</Button>
				</div>,
			]}
		>
			<div className="doodle comm_doodle_wrap">
				<div className="doodle dlw1">{thumbsUp}</div>
				<div className="doodle dlw2">{thumbsUp}</div>
				<div className="doodle dlw3">{thumbsUp}</div>

				<div className="doodle dsw1">{outlineStar}</div>
				<div className="doodle dsw2">{outlineStar}</div>

				<div className="doodle dew1">{energy}</div>
				<div className="doodle dew2">{energy}</div>
				<div className="doodle dew3">{energy}</div>
				<div className="doodle dew4">{energy}</div>
			</div>

			<div className="confirm-Modal-container">
				<div className="brand_logo">{brandLogo}</div>
			</div>
			<div className="sec_head">
				<h2 className="title">¡Has finalizado tu evaluación!</h2>
				<p>Gracias por completar tu evaluación. Los datos se han registrado exitosamente.</p>
			</div>
		</Modal>
	);
};

export default ThankYouModal;
