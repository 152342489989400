import React from 'react'
import device from "../../../assets/images/device.png";
import star_group from "../../../assets/images/star-group.png";
import likeIcon from "../../../assets/icons/like-icon.svg";

const ExperienceSection = () => {
    return (
        <div className='container semi_container'>
            <div className='info_img_col'>
                <div className="info_col_wrap">
                    <div className='info_col sec_head'>
                        <h2 className='title'>Tu experiencia en <br></br> la U <span className='highlight2'>importa</span></h2>
                        <p>Ayuda a correr la voz para ayudar a futuros estudiantes y a tus catedráticos favoritos  a ser reconocidos por su  buena labor.</p>
                    </div>
                </div>
                <div className="img_col_wrap">
                    <div className="img_wrapper doodle_wrap">
                        <div className="img_wrap">
                            <img src={device} alt="Device" />
                        </div>
                        <div className="doodle btn_doodle">
                            <span>Profesor con vocación</span>
                        </div>
                        <div className="doodle emoji_doodle">
                            <div className="emoji_doodle_img">
                                <img src={star_group} alt="" />
                            </div>
                        </div>
                        <div className="doodle like_doodle">
                            <div className="emoji_doodle_img">
                                <img src={likeIcon} alt="" />
                            </div>
                        </div>
                        <div className="doodle sm_like_doodle">
                            <div className="emoji_doodle_img">
                                <img src={likeIcon} alt="" />
                            </div>
                        </div>
                        <div className="doodle msg_doodle">
                            <h4> ¡Excelente profesor!  </h4>
                            <p>Es inspirador, muy organizado y dispuesto a apoyar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExperienceSection