import { Tabs } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { searchDataConstant } from "../../components/Modals/AddEvolutionModal/constant";
import CustomLabel from "../../components/Common/CustomLabel";
import "./style.scss";
import { useNavigate } from "react-router-dom";
const { TabPane } = Tabs;

const SearchPage = () => {
	const { searchData, searchPagination, searchKeyword } = useSelector((state) => state.search);
	const searchToUse = searchData?.professors?.length > 0 ? searchData : searchDataConstant;
	const navigate = useNavigate()

	const prof_options = [
		...(searchToUse.professors || []).map((prof) => ({ ...prof, type: "Profesor" })),
		...(searchToUse.courses || []).map((course) => ({ ...course, type: "Curso" })),
		...(searchToUse.users || []).map((user) => ({ ...user, type: "Usuario" })),
	];

	const [activeTab, setActiveTab] = useState("ALL");

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	
    const handleRedirect = (data) => {
        if (data?.type === "Profesor") {
            navigate(`/professor/${data.id}`);
        } else if (data?.type === "Curso") {
            navigate(`/course/${data.id}`);
        } else if (data?.type === "Usuario") {
            navigate(`/user/${data.id}`);
        }
    };

	const renderDataByTab = () => {
		switch (activeTab) {
			case "ALL":
				return (
					<div className="custom_options">
						{prof_options?.map((data, index) => (
							<div
								className="profession_info"
								onClick={() => handleRedirect(data)}
								key={index}>
								<CustomLabel
									name={data.name ? data.name : data?.fullName}
									icon={data.image ? data.image : data?.profileImg}
									type={data.type}
									college={data.detail}
									data={data}
								/>
							</div>
						))}
					</div>
				);
			case "PROFESSOR":
				return (
					<div className="custom_options">
						{prof_options
							.filter((data) => data.type === "Profesor")
							.map((data, index) => (
								<div
									className="profession_info"
									onClick={() => handleRedirect(data)}
									key={index}>
									<CustomLabel
										name={data.name ? data.name : data?.fullName}
										icon={data.image ? data.image : data?.profileImg}
										type={data.type}
										college={data.detail}
										data={data}
									/>
								</div>
							))}
					</div>
				);
			case "COURSE":
				return (
					<div className="custom_options">
						{prof_options
							.filter((data) => data.type === "Curso")
							.map((data, index) => (
								<div
									className="profession_info"
									onClick={() => handleRedirect(data)}
									key={index}>
									<CustomLabel
										name={data.name ? data.name : data?.fullName}
										icon={data.image ? data.image : data?.profileImg}
										type={data.type}
										college={data.detail}
										data={data}
									/>
								</div>
							))}
					</div>
				);
			case "USER":
				return (
					<div className="custom_options">
						{prof_options
							.filter((data) => data.type === "Usuario")
							.map((data, index) => (
								<div
									className="profession_info"
									onClick={() => handleRedirect(data)}
									key={index}>
									<CustomLabel
										name={data.name ? data.name : data?.fullName}
										icon={data.image ? data.image : data?.profileImg}
										type={data.type}
										college={data.detail}
										data={data}
									/>
								</div>
							))}
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className="search-screen">
			<div className="inner-part">
				<h3>
					Hemos encontrado {prof_options?.length} resultados de “{searchKeyword}”
				</h3>
				{searchToUse && (
					<div className="recent-search-container">
						<Tabs
							activeKey={activeTab}
							onChange={handleTabChange}
							className="tabs-top">
							<TabPane
								tab="Todos"
								key="ALL"
							/>
							<TabPane
								tab="Profesor"
								key="PROFESSOR"
							/>
							<TabPane
								tab="Curso"
								key="COURSE"
							/>
							<TabPane
								tab="Usuario"
								key="USER"
							/>
						</Tabs>
						{renderDataByTab()}
					</div>
				)}
			</div>
		</div>
	);
};

export default SearchPage;
