import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import executeHttp from "../../services/api";
import { API_URL } from '../../constants/api-url';

const initialState = {
    notifications: [],
};

export const markSingleNotificationAsRead = createAsyncThunk(
    "emailnotification/emailNotificationMarkAsRead",
    async (idNotification) => {
        try {
            const response = await executeHttp({
                method: 'PATCH',
                endpoint: `${API_URL.NOTIFICATION.PATCH_ID}/${idNotification}`,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const deleteSingleNotification = createAsyncThunk(
    "emailnotification/deleteSingleNotification",
    async (idNotification) => {
        try {
            const response = await executeHttp({
                method: 'DELETE',
                endpoint: `${API_URL.NOTIFICATION.DELETE_ID}/${idNotification}`,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const markAllNotificationsAsRead = createAsyncThunk(
    "emailnotification/markAllNotificationsAsRead",
    async () => {
        try {
            const response = await executeHttp({
                method: 'PATCH',
                endpoint: `${API_URL.NOTIFICATION.PATCH}`,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const deleteAllNotifications = createAsyncThunk(
    "emailnotification/deleteAllNotifications",
    async () => {
        try {
            const response = await executeHttp({
                method: 'DELETE',
                endpoint: `${API_URL.NOTIFICATION.DELETE}`,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);


export const getAllNotification = createAsyncThunk(
    "notifications/getAllNotification",
    async () => {
        try {
            const response = await executeHttp({
                method: 'GET',
                endpoint: `${API_URL.NOTIFICATION.GET}`,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

const emailNotificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllNotification.fulfilled, (state, action) => {
            state.notifications = action.payload.notifications;
        });
    },
})


export default emailNotificationSlice.reducer;