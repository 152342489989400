import { Button } from 'antd'
import React, { useState } from 'react'
import facebookIcon from '../../assets/icons/facebook-icon.svg'
import googleIcon from '../../assets/icons/google-icon.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SignInMethods } from '../../helpers/auth'

const AnotherLoginButton = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [gLoading, setGLoading] = useState(false);
    const [fbLoading, setFbLoading] = useState(false);
    const { t: commonT } = useTranslation('common');
    
    return (
        <>
            <span>{commonT('SOCIAL_LOGIN_INFO')}</span>
            <div className="another-options">
                <div className="facebook">
                    <Button
                        className=""
                        onClick={()=> SignInMethods('facebook', setFbLoading, dispatch, navigate)}
                        loading={fbLoading}
                    >
                        <img src={facebookIcon} alt="facebookIcon" />
                            Facebook
                    </Button>
                </div>
                <div className="google">
                    <Button
                        className=""
                        onClick={()=> SignInMethods('google', setGLoading, dispatch, navigate)}
                        loading={gLoading}
                    >
                        <img src={googleIcon} alt="googleIcon" />
                            Gmail
                    </Button>
                </div>
            </div>
        </>
    )
}

export default AnotherLoginButton