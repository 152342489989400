import React from "react";
import "./style.scss";
import {
    black_star,
    emoji_five_star,
    emoji_four_star,
    emoji_one_star,
    emoji_three_star,
    emoji_two_star,
} from "../../assets";
import { FloatButton } from "antd";

const EvaluationFactors = () => {
    return (
        <>
            <section className="simple_banner">
                <div className="container">
                    <div className="sec_head">
                        <h1 className="title">
                            Factores de <br /> evaluación
                        </h1>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut magna
                            aliqua.
                        </p>
                    </div>
                </div>
            </section>

            <section className="factor_list_sec">
                <div className="container sm_semi_container">
                    <div className="inner_wrap">
                        <div className="factor_card_wrapper">
                            <div className="factor_card_wrap">
                                <div className="factor_card">
                                    <div className="factor_info">
                                        <div className="info_cal">
                                            <h3 className="title">
                                                Calidad de enseñanza
                                            </h3>
                                            <p>
                                                {" "}
                                                Este factor evalúa los
                                                conocimientos técnicos del
                                                profesor en los cursos que
                                                enseña. También toma en
                                                consideración la utilización de
                                                metodologías de enseñanza
                                                efectivas y adecuadas para los
                                                temas abordados en las clases, y
                                                la implementación de
                                                herramientas tecnológicas.{" "}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="factor_results">
                                        <div className="factor_cols">
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        1 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_one_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Muy mala calidad</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        2 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_two_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Baja calidad</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        3 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_three_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Calidad normal</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        4 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_four_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Buena calidad</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        5 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal  ">
                                                    <div className="icon">
                                                        {emoji_five_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Excelente calidad</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="factor_card">
                                    <div className="factor_info">
                                        <div className="info_cal">
                                            <h3 className="title">
                                                Criterio de calificación
                                            </h3>
                                            <p>
                                                Este factor toma en
                                                consideración el uso de
                                                criterios objetivos y claros de
                                                calificación por parte del
                                                profesor, para evaluar el
                                                desempeño de los estudiantes
                                                durante el desarrollo del curso.
                                                Asimismo, este factor incluye el
                                                cumplimiento de dichos criterios
                                                por parte del profesor, en los
                                                tiempos y formas dispuestos.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="factor_results">
                                        <div className="factor_cols">
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        1 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_one_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Muy injusto</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        2 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_two_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Injusto</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        3 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_three_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Equilibrado</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        4 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_four_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Justo</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        5 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal  ">
                                                    <div className="icon">
                                                        {emoji_five_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Justo y objetivo</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="factor_card">
                                    <div className="factor_info">
                                        <div className="info_cal">
                                            <h3 className="title">
                                                Nivel de dificultad
                                            </h3>
                                            <p>
                                                Este factor examina el nivel de
                                                dificultad que el estudiante ha
                                                experimentado durante el
                                                desarrollo del curso, y cómo el
                                                profesor, con sus metodologías y
                                                formato de enseñanza, ha
                                                facilitado o dificultado el
                                                entendimiento del material visto
                                                en los cursos,{" "}
                                                <strong>
                                                    independientemente
                                                </strong>{" "}
                                                del nivel de dificultad
                                                intrínsico de los temas
                                                abordados.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="factor_results">
                                        <div className="factor_cols">
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        1 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_one_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Muy inadecuado</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        2 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_two_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Inadecuado</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        3 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_three_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Aceptable</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        4 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_four_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Correcto</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        5 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal  ">
                                                    <div className="icon">
                                                        {emoji_five_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Muy correcto</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="factor_card">
                                    <div className="factor_info">
                                        <div className="info_cal">
                                            <h3 className="title">
                                                Accesibilidad
                                            </h3>
                                            <p>
                                                Este factor abarca la
                                                disponibilidad que el profesor
                                                ha tenido con los estudiantes,
                                                permitiendo la formulación de
                                                preguntas en clase, el uso de
                                                horas de oficina posterior al
                                                curso impartido para la
                                                consultas sobre el material
                                                enseñado, o la apertura de
                                                múltiples canales de
                                                comunicación con el
                                                estudiantado.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="factor_results">
                                        <div className="factor_cols">
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        1 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_one_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Nada accesible</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        2 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_two_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Poco accesible </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        3 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_three_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Accesible a veces</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        4 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_four_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Accesible</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        5 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal  ">
                                                    <div className="icon">
                                                        {emoji_five_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Muy accesible a ayudar</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="factor_card">
                                    <div className="factor_info">
                                        <div className="info_cal">
                                            <h3 className="title">
                                                Capacidad de inspirar
                                            </h3>
                                            <p>
                                                {" "}
                                                Este factor describe la
                                                percepción del profesor como un
                                                mentor para los alumnos, sea por
                                                su trayectoria profesional y
                                                académica, por su metodología de
                                                enseñanza, o por cualquier otro
                                                elemento que los distinga y que
                                                inspire a los estudiantes a ser
                                                mejores personas y ciudadanos.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="factor_results">
                                        <div className="factor_cols">
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        1 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_one_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Nada inspirador</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        2 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_two_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Poco inspirador </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        3 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_three_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Bueno</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        4 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal ">
                                                    <div className="icon">
                                                        {emoji_four_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Muy bueno</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating_cal">
                                                <div className="rating_wrap">
                                                    <span className="star_box">
                                                        {black_star}
                                                    </span>
                                                    <span className="rat_box">
                                                        5 estrella
                                                    </span>
                                                </div>
                                                <div className="emoji_rating_cal  ">
                                                    <div className="icon">
                                                        {emoji_five_star}
                                                    </div>
                                                    <div className="doodle_info">
                                                        <p>Es genial</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FloatButton.BackTop
                shape="square"
                icon={
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.01782 23.3539L17.7133 12.6584L28.4087 23.3539"
                            stroke="#5C30A1"
                            stroke-width="3.50889"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
        </>
    );
};

export default EvaluationFactors;
