import React from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "../../constants/paths";
import { Button, Checkbox, FloatButton, Skeleton } from "antd";
import "./style.scss";
import FormButton from "../../components/Button/FormButton";
import starIcon from "../../assets/icons/star-icon.svg";
import rightIcon from "../../assets/icons/right-icon.svg";
import {
    check_white,
    doodle_bottom_purple,
    doodle_bottom_white,
    doodle_rev_bottom_white,
    doodle_star_icon,
    edu,
    emoji_five_star,
    emoji_four_star,
    emoji_one_star,
    emoji_three_star,
    emoji_two_star,
    envelop,
    mobile,
} from "../../assets";
import CtaBanner from "../../components/CtaBanner";

const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
};

const Methology = () => {
    const navigate = useNavigate();
    return (
        <>
            <section className="simple_banner">
                <div className="container">
                    <div className="sec_head">
                        <p className="highlight no_bar">Methology</p>
                        <h1 className="title">
                            ¿Cómo calculamos el valor de una nota Saikit?
                        </h1>
                        <p>
                            Para calcular el valor de una nota Saikit a un
                            profesor, tomamos en cuenta lo siguiente{" "}
                        </p>
                    </div>
                </div>
            </section>

            <section className="number_list_sec">
                <div className="doodle doodle_bottom_white">
                    {doodle_bottom_white}
                </div>
                <div className="container semi_container">
                    <div className="list_wrapper_block">
                        <div className="list_wrapper">
                            <div className="inner_wrap">
                                <div className="info_cal">
                                    <h2 className="title">
                                        Calificación del <br /> estudiante
                                    </h2>
                                    <p>
                                        El promedio de la calificación que han
                                        dado los estudiantes al profesor en los
                                        diferentes factores de evaluación
                                    </p>
                                    <div className="btn_wrap btn_lg method-bt-desktop">
                                        <FormButton
                                            type="primary"
                                            title="Ver factores de evaluación"
                                            onClick={() =>
                                                navigate(
                                                    PATH_LIST.USER
                                                        .EVALUATION_FACTORS
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="img_col">
                                    <div className="emoji_wrapper">
                                        <div className="emoji_box">
                                            <div className="emoji_card">
                                                <div className="icon">
                                                    {emoji_one_star}
                                                </div>
                                                <div className="doodle_info">
                                                    <h6>Calificación</h6>
                                                    <p>
                                                        <span className="star_icon">
                                                            {doodle_star_icon}
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            1 Estrellas
                                                        </span>
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="emoji_box">
                                            <div className="emoji_card">
                                                <div className="icon">
                                                    {emoji_two_star}
                                                </div>
                                                <div className="doodle_info">
                                                    <h6>Calificación</h6>
                                                    <p>
                                                        <span className="star_icon">
                                                            {doodle_star_icon}
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            2 Estrellas
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="emoji_box">
                                            <div className="emoji_card">
                                                <div className="icon">
                                                    {emoji_three_star}
                                                </div>
                                                <div className="doodle_info">
                                                    <h6>Calificación</h6>
                                                    <p>
                                                        <span className="star_icon">
                                                            {doodle_star_icon}
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            3 Estrellas
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="emoji_box">
                                            <div className="emoji_card">
                                                <div className="icon">
                                                    {emoji_four_star}
                                                </div>
                                                <div className="doodle_info">
                                                    <h6>Calificación</h6>
                                                    <p>
                                                        <span className="star_icon">
                                                            {doodle_star_icon}
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            4 Estrellas
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="emoji_box">
                                            <div className="emoji_card">
                                                <div className="icon">
                                                    {emoji_five_star}
                                                </div>
                                                <div className="doodle_info">
                                                    <h6>Calificación</h6>
                                                    <p>
                                                        <span className="star_icon">
                                                            {doodle_star_icon}
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            5 Estrellas
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn_wrap btn_lg method-bt-mobile">
                                        <FormButton
                                            type="primary"
                                            title="Ver factores de evaluación"
                                            onClick={() =>
                                                navigate(
                                                    PATH_LIST.USER
                                                        .EVALUATION_FACTORS
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="list_wrapper">
                            <div className="inner_wrap">
                                <div className="info_cal">
                                    <h2 className="title">
                                        Nivel de verificación
                                    </h2>
                                    <p>
                                        ELa verificación de usuario indica la
                                        fiabilidad que se tiene de que el
                                        usuario es auténtico, es quien dice ser.
                                    </p>
                                    <div className="btn_wrap btn_lg">
                                        <FormButton
                                            type="primary"
                                            title="Ver factores de evaluación"
                                            onClick={() =>
                                                navigate(
                                                    PATH_LIST.USER
                                                        .EVALUATION_FACTORS
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="img_col">
                                    <div className="chrck_list_wrap">
                                        <p>
                                            <strong>Datos verificados:</strong>
                                        </p>
                                        <ul className="check_list">
                                            <li className="active check_item">
                                                <div className="icon">
                                                    {check_white}
                                                </div>
                                                <div className="check_txt">
                                                    <p>Correo o red social</p>
                                                </div>
                                            </li>
                                            <li className="check_item">
                                                <div className="icon">
                                                    {check_white}
                                                </div>
                                                <div className="check_txt">
                                                    <p>Teléfono</p>
                                                </div>
                                            </li>
                                            <li className="check_item">
                                                <div className="icon">
                                                    {check_white}
                                                </div>
                                                <div className="check_txt">
                                                    <p>
                                                        Correo institucional
                                                        .edu
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="graph_sec">
                                <div className="doodle doodle_bottom_purple">
                                    {doodle_bottom_purple}
                                </div>
                                <div className="graph_wrapper">
                                    <div className="inner_wrap">
                                        <div className="info_cal">
                                            <h2 className="title">
                                                Siendo de mayor valor los
                                                niveles de{" "}
                                                <span className="highlight2">
                                                    verificación
                                                </span>{" "}
                                                más altos
                                            </h2>
                                        </div>

                                        <div className="img_col">
                                            <div className="chart_wrapper">
                                                <div className="graph_wrap">
                                                    <div className="graph_box">
                                                        <div className="bar_icon_wrap">
                                                            <div className="bar_icon">
                                                                {envelop}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height: "17%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div className="graph_box">
                                                        <div className="bar_icon_wrap">
                                                            <div className="bar_icon">
                                                                {mobile}
                                                            </div>
                                                            <div className="bar_icon">
                                                                {envelop}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height: "29%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div className="graph_box">
                                                        <div className="bar_icon_wrap">
                                                            <div className="bar_icon">
                                                                {edu}
                                                            </div>
                                                            <div className="bar_icon">
                                                                {mobile}
                                                            </div>
                                                            <div className="bar_icon">
                                                                {envelop}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height: "100%",
                                                            }}
                                                        ></div>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="top_doodle_wrap btn_doodle_wrap professor-btn-mobil">
                                <div className="btn_doodle">
                                    <span>Profesor con vocación</span>
                                </div>
                            </div>
                        </div>

                        <div className="list_wrapper graph_card_wrapper">
                            <div className="doodle doodle_bottom_purple">
                                {doodle_rev_bottom_white}
                            </div>
                            <div className="inner_wrap">
                                <div className="info_cal sec_head">
                                    <h2 className="title">Tiempo</h2>
                                </div>

                                <div className="top_doodle_wrap btn_doodle_wrap professor-btn-desktop">
                                    <div className="btn_doodle">
                                        <span>Profesor con vocación</span>
                                    </div>
                                </div>

                                <div className="info_col_inner chart_card_wrappper">
                                    <div className="char_card">
                                        <div className="sec_head">
                                            <p className="sec_title"> <strong>Nuestra ecuación también asigna un mayor valor a las evaluaciones más recientes</strong> </p>
                                        </div>


                                        <div className="chart_card_wrap">
                                            <div className="chart_card_box l_wrap">
                                                <div className="title_wrap">
                                                    <h3 className="title">Hoy</h3>
                                                </div>

                                                <div className="id_card card">
                                                    <div className="card-container">

                                                        <div className="top_bar">
                                                            <div className="inner_top_bar">
                                                                <div className="user_info">
                                                                    <span className="name">Mario Menéndez</span>
                                                                    <span className="verify">
                                                                        <img src={rightIcon} alt="" />
                                                                    </span>
                                                                </div>
                                                                <p>Curso: Biología y genética I</p>
                                                            </div>
                                                            <div className="rating_wrap">
                                                                <span className="rat_box">4</span>
                                                                <span className="star_box">
                                                                    <img src={starIcon} alt="" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="bottom_row">
                                                            <Skeleton active />
                                                        </div>


                                                    </div>
                                                </div>

                                                <div className="bar"></div>
                                            </div>
                                            <div className="chart_card_box r_wrap">
                                                <div className="title_wrap">
                                                    <h3 className="title">Hoy</h3>
                                                </div>

                                                <div className="id_card card">
                                                    <div className="card-container">

                                                        <div className="top_bar">
                                                            <div className="inner_top_bar">
                                                                <div className="user_info">
                                                                    <span className="name">Mario Menéndez</span>
                                                                    <span className="verify">
                                                                        <img src={rightIcon} alt="" />
                                                                    </span>
                                                                </div>
                                                                <p>Curso: Biología y genética I</p>
                                                            </div>
                                                            <div className="rating_wrap">
                                                                <span className="rat_box">4</span>
                                                                <span className="star_box">
                                                                    <img src={starIcon} alt="" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="bottom_row">
                                                            <Skeleton active />
                                                        </div>


                                                    </div>
                                                </div>

                                                <div className="bar"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="bottom_doodle_wrap btn_doodle_wrap">
                                    <div className="btn_doodle">
                                        <span>Profesor con vocación</span>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="cta_sec">
                <CtaBanner />
            </section>
            <FloatButton.BackTop
                shape="square"
                icon={
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.01782 23.3539L17.7133 12.6584L28.4087 23.3539"
                            stroke="#5C30A1"
                            stroke-width="3.50889"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
        </>
    );
};

export default Methology;
