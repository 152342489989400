import { Modal } from 'antd'
import React from 'react'
import ProfessorListLeftSideBar from '../../FilterTeacherPage/ProfessorListLeftSideBar'

const ProfessorListSideBarModal = ({ setIsModalVisible, isModalVisible }) => {

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <Modal
                title="Contáctanos"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            ><ProfessorListLeftSideBar />
            </Modal>
        </div>
    )
}

export default ProfessorListSideBarModal
