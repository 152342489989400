import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH_LIST } from '../../constants/paths';
import FormButton from '../Button/FormButton';
import { clock, plus, userCheck } from '../../assets';

const CtaBanner = () => {
  const navigate = useNavigate()

    const handleButtonClick = ()=> {
        navigate(PATH_LIST.USER.REGISTER)
    }
    return (
        <div className='container semi_container'>
            <div className='cta_wrap without_btn_cta'>
                <div className="inner_wrap">
                    <div className='sec_head'>
                        <div className="icon_list">
                            <ul>
                                <li>
                                    {userCheck}
                                </li>
                                <li>
                                    {plus}
                                </li>
                                <li>
                                    {clock}
                                </li>
                            </ul>
                        </div>
                        <h2 className='title'>Opiniones <br />  auténticas y <br /> relevantes</h2>
                    </div>
                    <div className="info_wrap">
                        <p>De esta forma le otorgamos un mayor valor a las opiniones de usuarios que han pasado por todo el proceso de nuestra plataforma, y a las opiniones que representan una versión más actualizada de la experiencia de aprendizaje con un profesor.</p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CtaBanner