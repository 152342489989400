import React, { useState } from "react";
import "../style.scss";
import charectorImg from "../../../../assets/images/character-img.png";
import { dropdown_arrow } from "../../../../assets";
import { Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { clear_icon } from "../../../../assets";
import CustomLabel from "../../../Common/CustomLabel";
import { useDispatch, useSelector } from "react-redux";
import { searchDataConstant } from "../constant";
import { getEvolutionSearch } from "../../../../store/slices/evolutionSlice";

const FirstPage = ({ next, careerSelection, handleSearchSelect }) => {
	const dispatch = useDispatch();
	const { evolutionSearch } = useSelector((state) => state?.evolution);
	const [searchText, setSearchText] = useState("");

	const searchToUse =  evolutionSearch;

	const handleSearchChange = (value) => {
		const careerFilter = `ID_CAREERS=[${careerSelection}]`;
		setSearchText(value);
		const payload = {
			text_search: value,
			type_search: "ALL",
			filters: careerFilter,
			sort: "ASC",
			is_top: "false",
			top_size: 20,
			pagination_cursor: null,
			pagination_size: 10,
		};

		dispatch(getEvolutionSearch({ data: payload }));
	};

	const prof_options = [
		...(searchToUse.professors || []).map((prof) => ({ ...prof, type: "Profesor" })),
		...(searchToUse.courses || []).map((course) => ({ ...course, type: "Curso" })),
	];


	return (
		<div className="playarea_modal">
			<div className="playarea_inner">
				<div className="img_wrapper">
					<div className="img_wrap">
						<img
							src={charectorImg}
							alt="Character"
						/>
					</div>
				</div>

				<div className="sec_head side_head hide_in_desktop">
					<h2 className="title">Nueva evaluación</h2>
					<p className="desc">Comienza eligiendo el curso y profesor que deseas evaluar</p>
				</div>

				<div className="dropdown_wrappper hide_in_desktop">
					<div className="select_wrap w-100">
						<form>
							<Select
								defaultValue="Más reciente primero"
								options={[
									{
										value: "Maestría en salud pública con énf... URL",
										label: "Maestría en salud pública con énf... URL",
									},
									{
										value: "Médico y cirujano, USAC",
										label: "Médico y cirujano, USAC",
									},
									{
										value: "Elegir otra carrera",
										label: "Elegir otra carrera",
									},
								]}
								suffixIcon={dropdown_arrow}
								popupClassName="cust_dropDown"
							/>
						</form>
					</div>
				</div>

				<div className="sec_head hide_in_tab">
					<h3 className="title">Selecciona un curso o profesor</h3>
				</div>

				<div className="search_dropDown_wrapper">
					<div className="search-box w-100">
						<form>
							<Input
								placeholder="Curso o profesor"
								prefix={<SearchOutlined />}
								suffix={clear_icon}
								onChange={(e) => handleSearchChange(e.target.value)}
							/>
						</form>
					</div>
					{searchToUse && searchText && (
						<div className="custom_options_wrapper">
							<div className="custom_options">
								{prof_options?.map((data, index) => {
									return (
										<div
											className="profession_info"
											key={index}
											onClick={() => handleSearchSelect(data)}>
											<CustomLabel
												name={data.name ? data.name : data?.fullName}
												icon={data.image}
												type={data.type}
												college={data.aditionalInfo}
												data={data}
											/>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FirstPage;
