import React from "react";
import { doodle_star_icon, emoji_five_star, emoji_four_star, emoji_one_star, emoji_three_star, emoji_two_star } from "../../../assets";

function EmojiRating({ moveToNext, next, levels, factor }) {
	const handleClick = (index) => {
		if (index === 4) {
			next();
		} else {
			moveToNext();
		}
	};

	return (
		<div className="emoji_wrapper">
			{levels?.map((label, index) => {
				return (
					<div
						key={index}
						className="emoji_box"
						onClick={() => handleClick(index)}>
						<div className="emoji_card">
							<div className="icon">
								<img
									src={label?.img}
									alt=""
								/>
							</div>
							<div className="doodle_info">
								<h6>{label?.label}</h6>
								<p>
									<span className="star_icon">{doodle_star_icon}</span>
									<span>{label?.value} Estrellas</span>
								</p>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default EmojiRating;
