import { useNavigate } from "react-router-dom";
import { PATH_LIST } from '../../../constants/paths';
import FormButton from '../../../components/Button/FormButton';

const Proposal = () => {
    const navigate = useNavigate()

    const handleButtonClick = ()=> {
        navigate(PATH_LIST.USER.REGISTER)
    }
    return (
        <div className="container">
            <div className="sec_head">
                <h1 className="title">Propuesta de <br /> valor</h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut magna aliqua.
                </p>
            </div>
            <div className="btn_wrap center btn_lg">
                
            <FormButton
                type='primary'
                title="Empieza ahora"
                onClick={handleButtonClick}
            />
            </div>
        </div>
    );
};
export default Proposal;
