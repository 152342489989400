import { getProfessoLabel, getProfessorAbout, getProfessorDetailActivity, getProfessorScore, getProfessoEvaluation } from "../../store/slices/professorSlice";
import React, { useEffect, useState } from "react";
import myPic from "../../assets/images/my-pic.png";
import check from "../../assets/icons/right-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { dropdown_arrow, purple_icon } from "../../assets";
import "./style.scss";
import { Button, Dropdown, Menu } from "antd";
import EvaluationCard from "../../components/Common/EvaluationCard";

const Professor = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { aboutProfessor, professorScore, professorDetailActivity, professorLabel, professorEvaluation } = useSelector((state) => state.professor);
    const [tab, setTab] = useState("list");
    const [selectedKey, setSelectedKey] = useState("relevantes");

    const selectedTitle = {
        relevantes: "Más relevantes",
        antiguos: "Más antiguos primero",
        recientes: "Más recientes primero",
        alta: "Calificación alta primero",
        baja: "Calificación baja primero",
        alfabetico: "Orden alfabético",
    }[selectedKey];

    const handleMenuClick = ({ key }) => {
        setSelectedKey(key);
    };

    const menu = (
        <Menu
            className="btn_dropdown"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}>
            <Menu.Item key="relevantes">Más relevantes</Menu.Item>
            <Menu.Item key="antiguos">Más antiguos primero</Menu.Item>
            <Menu.Item key="recientes">Más recientes primero</Menu.Item>
            <Menu.Item key="alta">Calificación alta primero</Menu.Item>
            <Menu.Item key="baja">Calificación baja primero</Menu.Item>
            <Menu.Item key="alfabetico">Orden alfabético</Menu.Item>
        </Menu>
    );

    useEffect(() => {
        dispatch(getProfessorAbout({ id: id }));
        dispatch(getProfessorScore({ id: id }));
        dispatch(getProfessorDetailActivity({ id: id }));
        dispatch(getProfessoLabel({ id: id }));
        dispatch(getProfessoEvaluation({ id: id }));
    }, []);

    // console.log("professorScore", professorEvaluation);

    return (
        <>
            <section className="top_semi_banner_sec"></section>
            <section className="user_prifile_sec">
                <div className="container md_container">
                    <div className="inner_wrap">
                        <div className="user_profile_wrapper">
                            <div className="user_card">
                                <div className="my_info">
                                    <div className="details">
                                        <div className="my_pic">
                                            <img
                                                src={aboutProfessor?.image}
                                                alt="my-Pic"
                                            />
                                        </div>
                                        <div className="score">
                                            {aboutProfessor?.score} {purple_icon}
                                        </div>
                                    </div>
                                    <div className="name">
                                        <h4 className="title">{aboutProfessor?.fullName}</h4>
                                        <div className="verify">
                                            <img
                                                src={check}
                                                alt="verified"
                                            />
                                        </div>
                                    </div>
                                    <div className="short_name">
                                        <h6 className="title professor-visit">{aboutProfessor?.views} visitas</h6>
                                    </div>
                                </div>

                                <div className="about_me">
                                    <div className="tab_badge">
                                        <p className="prof_badge">Profesor</p>
                                    </div>
                                    {aboutProfessor?.courses?.map((data, index) => {
                                        return (
                                            <div
                                                className="profession"
                                                key={index}>
                                                <h6>{data?.career}</h6>
                                                <p>{data?.university}</p>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="professor_labels">
                                    {aboutProfessor?.labels?.map((data, index) => {
                                        return (
                                            <p
                                                className={`label ${data?.category === "NEGATIVE" ? "negative" : "positive"}`}
                                                key={index}>
                                                {data?.name}
                                            </p>
                                        );
                                    })}
                                </div>

                                <div className="evaluations_wrap">
                                    <div className="approvals_list_wrap">
                                        <div className="approvals_list">
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={like_cyan}
                                                            alt="Like"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutProfessor?.detail?.universities}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">Universidades</p>
                                                </div>
                                            </div>
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutProfessor?.detail?.courses}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">Cursos</p>
                                                </div>
                                            </div>
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        {/* <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        /> */}
                                                    </div>
                                                    <h3 className="title">{aboutProfessor?.detail?.evaluations}</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title"> Evaluaciones</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bottom_row">
                                    <small>Fecha de registro {aboutProfessor?.registrationDate}</small>
                                </div>
                            </div>
                        </div>

                        <div className="user_profile_listing">
                            <div className="professor_navigation">
                                <div
                                    className={`nav ${tab === "list" ? "active" : ""}`}
                                    onClick={() => setTab("list")}>
                                    Sobre el profesor
                                </div>
                                <div
                                    className={`nav ${tab === "evel" ? "active" : ""}`}
                                    onClick={() => setTab("evel")}>
                                    Evaluaciones
                                </div>
                            </div>
                            {tab === "list" && (
                                <div className="professor-other-details">
                                    <div className="puntuation-container">
                                        <div className="left-container">
                                            <h3>Puntuación</h3>
                                            <div className="score">
                                                {professorScore?.score} {purple_icon}
                                            </div>
                                            <div className="text-with-icon">
                                                <div className="icon">
                                                    <svg
                                                        width="29"
                                                        height="29"
                                                        viewBox="0 0 29 29"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M9.66732 6.04102H20.5423C21.2097 6.04102 21.7507 6.582 21.7507 7.24935V21.7493C21.7507 22.4167 21.2097 22.9577 20.5423 22.9577H9.66732C8.99997 22.9577 8.45898 22.4167 8.45898 21.7493V7.24935C8.45898 6.582 8.99997 6.04102 9.66732 6.04102Z"
                                                            stroke="#0DC9C2"
                                                            stroke-width="1.5"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M12.6875 17.5202H17.5208M12.6875 11.4785H17.5208H12.6875ZM12.6875 14.4993H17.5208H12.6875Z"
                                                            stroke="#0DC9C2"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="text">
                                                    <span>{professorScore?.evaluations}</span>
                                                </div>
                                                <p>Evaluaciones</p>
                                            </div>
                                            <div className="text-with-icon">
                                                <div className="icon">
                                                    <svg
                                                        width="29"
                                                        height="29"
                                                        viewBox="0 0 29 29"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M15.1035 6.64648C10.0984 6.64648 6.04102 9.87333 6.04102 13.8538C6.04102 15.6228 6.84234 17.2429 8.17204 18.4971L7.85352 22.3548L11.8171 20.5727C12.836 20.8881 13.9444 21.0612 15.1035 21.0612C20.1086 21.0612 24.166 17.8343 24.166 13.8538C24.166 9.87333 20.1086 6.64648 15.1035 6.64648Z"
                                                            stroke="#0DC9C2"
                                                            stroke-width="1.5"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="text">
                                                    <span>{professorScore?.opinions}</span>
                                                </div>
                                                <p>Opiniones</p>
                                            </div>
                                        </div>
                                        <div className="right-container">
                                            {professorScore?.details?.map((data, index) => {
                                                return (
                                                    <div
                                                        className="score-container"
                                                        key={index}>
                                                        <p>{data?.evaluationFactor}</p>
                                                        <div className="icon"></div>
                                                        <div className="label">{data?.scoreLabel}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="detailed-activity">
                                        <div className="header">Este año ha recibido</div>
                                        <div className="value-container">
                                            <div className="text-with-icon">
                                                <div className="icon"></div>
                                                <p className="value">{professorDetailActivity?.evaluations}</p>
                                                <div>Evaluaciones</div>
                                            </div>
                                            <div className="text-with-icon">
                                                <div className="icon"></div>
                                                <p className="value">{professorDetailActivity?.views}</p>
                                                <div>visitas</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="professor_labels">
                                        <h2 className="header">Etiquetas más utilizadas</h2>
                                        <div className="content">
                                            {professorLabel?.labels?.map((data, index) => {
                                                return (
                                                    <p
                                                        className={`label ${data?.category === "Negative" ? "negative" : "positive"}`}
                                                        key={index}>
                                                        {data?.label} ({data?.count})
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {tab === "evel" && (
                                <div className="professor-evaluation">
                                    <div className="list_option_wrap">
                                        <div className="desc">
                                            <h3 className="title">Evaluaciones</h3>
                                        </div>
                                        <div className="drop_box">
                                            <div className="dropdown_box">
                                                <Dropdown overlay={menu}>
                                                    <Button>
                                                        {selectedTitle}
                                                        <i className="fas fa-caret-down" />
                                                        <div className="icon_box FaAngleDown">{dropdown_arrow}</div>
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list_details_wrapper">
                                        {
                                            professorEvaluation?.evaluations?.map((data, index)=> {
                                                return(
                                                    <EvaluationCard key={index} data={data}/>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Professor;
