import { Button, Modal } from "antd";
import React from "react";
import {emoji_five_star_colored, emoji_four_star_colored, emoji_one_star_colored, emoji_three_star_colored, emoji_two_star_colored, gray_star } from "../../../assets";

const InfoModal = ({
    isModalOpen,
    setIsModalOpen,
    closeFunction,
    cancelFunction,
}) => {
    return (
        <Modal
            title=""
            className="info_modal comm_inner_modal"
            open={isModalOpen}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            centered
            footer={[
                <div className="btn_wrap btn_grid_2">
                    <Button
                        key="back"
                        onClick={() => setIsModalOpen(false)}
                        className="primary"
                        type="primary"
                    > ¡Entendido!
                    </Button>
                </div>
            ]}
        >
            <div className="confirm-Modal-container">
                <h1 className="header">
                    Factor de evaluación - Calidad de enseñanza{" "}
                </h1>
                <p className="desc">
                    Evalúa los conocimientos técnicos del profesor en los cursos
                    que enseña. Toma en cuenta el uso de metodologías de
                    enseñanza efectivas y adecuadas para los temas abordados en
                    las clases, y la implementación de herramientas
                    tecnológicas.
                </p>

                <div className="rating_ratio_list">
                    <h6 className="title">
                    Rangos

                    </h6>
                    <ul className="rr_list_wrap hide_in_tab">
                        <li className="rr_list">
                            <div className="emoji_title">
                                <div className="icon">
                                    {emoji_one_star_colored}
                                </div>
                            </div>
                            <div class="doodle_info">
                                <p>
                                    <span class="star_icon">
                                        {gray_star}
                                    </span>
                                    <span> 1 Estrellas</span>
                                </p>
                            </div>
                        </li>
                        <li className="rr_list">
                            <div className="emoji_title">
                                <div className="icon">
                                    {emoji_two_star_colored}
                                </div>
                            </div>
                            <div class="doodle_info">
                                <p>
                                    <span class="star_icon">
                                        {gray_star}
                                    </span>
                                    <span> 2 Estrellas</span>
                                </p>
                            </div>
                        </li>
                        <li className="rr_list">
                            <div className="emoji_title">
                                <div className="icon">
                                    {emoji_three_star_colored}
                                </div>
                            </div>
                            <div class="doodle_info">
                                <p>
                                    <span class="star_icon">
                                        {gray_star}
                                    </span>
                                    <span> 3 Estrellas</span>
                                </p>
                            </div>
                        </li>
                        <li className="rr_list">
                            <div className="emoji_title">
                                <div className="icon">
                                    {emoji_four_star_colored}
                                </div>
                            </div>
                            <div class="doodle_info">
                                <p>
                                    <span class="star_icon">
                                        {gray_star}
                                    </span>
                                    <span> 4 Estrellas</span>
                                </p>
                            </div>
                        </li>
                        <li className="rr_list">
                            <div className="emoji_title">
                                <div className="icon">
                                    {emoji_five_star_colored}
                                </div>
                            </div>
                            <div class="doodle_info">
                                <p>
                                    <span class="star_icon">
                                        {gray_star}
                                    </span>
                                    <span> 5 Estrellas</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                    
                    <ul className="rr_list_wrap hide_in_desktop number">
                        <li>Muy mala calidad</li>
                        <li>Baja calidad</li>
                        <li>Calidad normal</li>
                        <li>Buena calidad</li>
                        <li>Excelente calidad</li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

export default InfoModal;
