import { Link } from "react-router-dom"
import backIcon from "../../assets/icons/back-icon.svg"
import { useTranslation } from "react-i18next";

export const BackButton = ({route})=>{
	const { t: commonT } = useTranslation('common'); 
	return(
		<div className="back">
			<Link to={route}>
				<img src={backIcon} alt="backIcon" />
				{commonT('GO_BACK')}
			</Link>
		</div>
	)
}