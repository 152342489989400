import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import executeHttp from "../../services/api";
import { API_URL } from "../../constants/api-url";

const initialState = {
    aboutCourseProfessor: {},
    professorScore: {},
    professorLabel: {},
    professorEvaluation: {},
    professorDetailActivity: {},
};

export const getCourseProfessorAbout = createAsyncThunk("professor/getCourseProfessorAbout", async ({ id, key }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.COURSE.ABOUT_PROFESSOR}/${id}/professor/${key}/about`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

export const getProfessorScore = createAsyncThunk("professor/getProfessorScore", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.PROFESSOR.SCORE}/${id}/score`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});
export const getProfessorDetailActivity = createAsyncThunk("professor/getProfessorDetailActivity", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.PROFESSOR.DETAIL_ACTIVITY}/${id}/detail-activity`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

export const getProfessoLabel = createAsyncThunk("professor/getProfessoLabel", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.PROFESSOR.DETAIL_ACTIVITY}/${id}/label`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

export const getProfessoEvaluation = createAsyncThunk("professor/getProfessoEvaluation", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.EVOLUTION.PROFESSOR_EVOLUTION}/${id}`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

const courseProfessorSlice = createSlice({
    name: "courseProfessor",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCourseProfessorAbout.fulfilled, (state, action) => {
            state.aboutCourseProfessor = action.payload.data;
        });
        builder.addCase(getProfessorScore.fulfilled, (state, action) => {
            state.professorScore = action.payload.data;
        });
        builder.addCase(getProfessorDetailActivity.fulfilled, (state, action) => {
            state.professorDetailActivity = action.payload.data;
        });
        builder.addCase(getProfessoLabel.fulfilled, (state, action) => {
            state.professorLabel = action.payload.data;
        });
        builder.addCase(getProfessoEvaluation.fulfilled, (state, action) => {
            state.professorEvaluation = action.payload.data;
        });
        // builder.addCase(getSearchData.fulfilled, (state, action) => {
        //     state.searchData = action.payload.data;
        //     state.searchPagination = action.payload.pagination
        // });
    },
});

export const {} = courseProfessorSlice.actions;
export default courseProfessorSlice.reducer;
