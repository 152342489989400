import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/api-url";
import executeHttp from "../../services/api";

const initialState = {
	searchData: [],
	evolutionCareers: [],
	evolutionFactors: [],
	evolutionLabel: [],
	evolutionSearch: {},
};

export const getEvolutionCareer = createAsyncThunk("evolution/getEvolutionCareer", async () => {
	try {
		const response = await executeHttp({
			method: "GET",
			endpoint: API_URL.EVOLUTION.CAREER,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
});

export const getEvolutionFactor = createAsyncThunk("evolution/getEvolutionFactor", async () => {
	try {
		const response = await executeHttp({
			method: "GET",
			endpoint: API_URL.EVOLUTION.FACTORS,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
});

export const getEvolutionlabel = createAsyncThunk("evolution/getEvolutionlabel", async () => {
	try {
		const response = await executeHttp({
			method: "GET",
			endpoint: API_URL.EVOLUTION.lABEL,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
});

export const getEvolutionSearch = createAsyncThunk("evolution/getEvolutionSearch", async ({data}) => {
	try {
		const response = await executeHttp({
			method: "POST",
			endpoint: API_URL.SEARCH.SEARCH,
            data: data
		});

		return response;
	} catch (error) {
		throw error;
	}
});

export const addEvolution = createAsyncThunk("evolution/addEvolution", async ({data}) => {
	try {
		const response = await executeHttp({
			method: "POST",
			endpoint: API_URL.SEARCH.SEARCH,
            data: data
		});
		return response;
	} catch (error) {
		throw error;
	}
});


const evolutionSlice = createSlice({
	name: "evolution",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
        builder.addCase(getEvolutionCareer.fulfilled, (state, action) => {
            state.evolutionCareers = action.payload.careers;
        });
        builder.addCase(getEvolutionFactor.fulfilled, (state, action) => {
            state.evolutionFactors = action.payload.evaluationFactor;
        });
        builder.addCase(getEvolutionlabel.fulfilled, (state, action) => {
            state.evolutionLabel = action.payload.evaluationLabels;
        });
        builder.addCase(getEvolutionSearch.fulfilled, (state, action) => {
            state.evolutionSearch = action.payload.data;
        });
    },
});

export default evolutionSlice.reducer;
