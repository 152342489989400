import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import executeHttp from "../../services/api";
import { API_URL } from "../../constants/api-url";

const initialState = {
    aboutUser: {},
    userEvaluation: {},
};

export const getUserAbout = createAsyncThunk("course/getUserAbout", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.USER.USER_ABOUT}/${id}/about`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});


export const getUserEvaluation = createAsyncThunk("course/getUserEvaluation", async ({ id }) => {
    try {
        const response = await executeHttp({
            method: "GET",
            endpoint: `${API_URL.EVOLUTION.USER_EVOLUTION}/${id}`,
        });
        return response;
    } catch (error) {
        throw error;
    }
});

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserAbout.fulfilled, (state, action) => {
            state.aboutUser = action.payload.data;
        });
        builder.addCase(getUserEvaluation.fulfilled, (state, action) => {
            state.userEvaluation = action.payload.data;
        });
    },
});

export const {} = userSlice.actions;
export default userSlice.reducer;
