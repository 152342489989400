import React from 'react'
import { Button, Checkbox, Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATH_LIST } from '../../../constants/paths';
import { ValidateMessages } from '../../../helpers';

const LoginForm = ({data, handleChange, isLoading, onLoginSubmit}) => {
    const { t: commonT } = useTranslation('common'); 
    const { t: loginPageT } = useTranslation('loginPage'); 
    return (
        <Form className="auth-form" layout="vertical" name="register-form"  onFinish={onLoginSubmit} validateMessages={ValidateMessages}>
            <div className="field">
                <Form.Item
                    label={loginPageT('EMAIL_OR_USER')}
                    name='email'
                    rules={[{ required: true, message: '¡correo electronico es requerido!' }, { type: "email" }]}
                >
                    <Input
                        type="email"
                        name='email'
                        placeholder="tunombre@email.com"
                        value={data.email}
                        onChange={(e) => handleChange(e, "email")}
                    />
                </Form.Item>
            </div>
            <div className="field">
                <Form.Item
                    label={commonT('PASSWORD')}
                    name='password'
                    rules={[{required : true}]}
                >
                    <Input.Password
                        name='password'
                        placeholder={commonT('PASSWORD_LABEL')}
                        value={data.password}
                        onChange={(e) => handleChange(e, "password")}
                    />
                </Form.Item>
            </div>
            <div className="remember-me">
                <div className="remember">
                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox>{loginPageT('REMEMBER_ME')}</Checkbox>
                    </Form.Item>
                </div>
                <div className="forgot-password">
                    <Link to={PATH_LIST.USER.FORGOT_PASSWORD}>{loginPageT('FORGOT_PASSWORD')}</Link>
                </div>
            </div>
            <div className="action-field">
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                    >
                        {loginPageT('GET_INTO')}
                    </Button>
                </Form.Item>
            </div>

        </Form>
    )
}

export default LoginForm