import React from "react";

const CustomLabel = ({ name, icon, type, college, data }) => {
    return (
        <>
            <div className="user_info">
                {icon ?
                    <img src={icon} alt="" />
                    :
                    <span class="profile_icon">{name?.split(" ")?.map(word => word?.charAt(0).toUpperCase())?.join("")}</span>

                }
            </div>

            <div className="prof_info_wrap">
                <div className="prof_top_bar">
                    <h6 className="title">{name}</h6>
                    <div className=" badge_wrap">
                        <p className={type === "Profesor" ? "prof_badge" : type === "Curso" ? "course_badge" : "user_badge"}>{type}</p>
                    </div>
                </div>
                <div className="profession">
                    <p>
                        <strong>{college},</strong>
                    </p>
                </div>
            </div>
        </>
    );
};

export default CustomLabel;
