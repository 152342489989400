import { Button, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEvolutionlabel } from "../../../../store/slices/evolutionSlice";
import { evolutionLabelConstant } from "../constant";

const FifthPage = ({ onChange, next, selectedLabels, setSelectedLabels }) => {
	const dispatch = useDispatch();
	const { evolutionLabel } = useSelector((state) => state?.evolution);
	const labelToUse =  evolutionLabel;

	const handleLabelChange = (label) => {
		if (selectedLabels.includes(label)) {
			setSelectedLabels(selectedLabels.filter((selectedLabel) => selectedLabel !== label));
		} else {
			if (selectedLabels.length < 10) {
				// Assuming maximum 10 selections allowed (5 positive and 5 negative)
				setSelectedLabels([...selectedLabels, label]);
			}
		}
	};

	useEffect(() => {
		dispatch(getEvolutionlabel());
	}, []);

	return (
		<div className="playarea_modal positive_nagative_wrappper">
			<div className="playarea_inner ">
				<div className="sec_head">
					<h2 className="sm_title que_txt">
						Agrega algunas etiquetas
						<small className="optional">(Opcional, puedes elegir hasta 5 etiquetas)</small>
					</h2>
				</div>

				<div className="nagative_positive_item_wrapper">
					<div className="positive_item">
						<div className="sec_head">
							<h6 className="title"> Positivas </h6>
						</div>

						<div className="list_wrap">
							{labelToUse.map(
								(label, index) =>
									label.category === "POSITIVE" && (
										<Checkbox key={index} checked={selectedLabels.includes(label)} onChange={() => handleLabelChange(label)}>
											{label.name}
										</Checkbox>
									)
							)}
						</div>
					</div>
					<div className="nagative_item">
						<div className="sec_head">
							<h6 className="title"> Negativas </h6>
						</div>

						<div className="list_wrap">
							{labelToUse.map(
								(label, index) =>
									label.category === "NEGATIVE" && (
										<Checkbox key={index} checked={selectedLabels.includes(label)} onChange={() => handleLabelChange(label)}>
											{label.name}
										</Checkbox>
									)
							)}
						</div>
					</div>
				</div>

				<div className="r_side btn_wrap btn_grid_2">
					<Button className="primary_v2" type="primary">
						Atrás
					</Button>
					<Button onClick={() => next()} className="primary" type="primary">
						Continuar
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FifthPage;
