import { useDispatch } from "react-redux";
import "../style.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { OnAuthSubmit } from '../../../helpers/auth';
import { login } from "../../../store/slices/authSlice";
import { PATH_LIST } from "../../../constants/paths";
import AuthLeft from '../../../components/Common/AuthLeft';
import AnotherLoginButton from '../../../components/Button/AnotherLoginButton';

const Login = () => {
	const [formData, setFormData] = useState({ email: "", password: "" });
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const Token = localStorage.getItem("Token");
	const { t: commonT } = useTranslation("common");
	const { t: loginPageT } = useTranslation("loginPage");

	//handle form input change
	const handleFormChange = (e, name) => {
		let changedField = { ...formData };
		const value = e.target.value;
		changedField[name] = value;
		setFormData({ ...changedField });
	};

	const onLoginSubmit = async () => {
		OnAuthSubmit("login", setIsLoading, formData, dispatch, navigate);
		dispatch(login(formData));
	};

	if (Token) {
		return <Navigate to={PATH_LIST.USER.HOME} />;
	}

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	return (
		<div className="auth-section login-page">
			<AuthLeft />
			<div className="auth-right">
				<div className="fixed-width">
					<div>
						<button onClick={() => changeLanguage("en")}>
							English
						</button>
						<button onClick={() => changeLanguage("es")}>
							Spanish
						</button>
					</div>

					<div className="header">
						<h1>{loginPageT("GREETING")}</h1>
						<div className="already-user">
							<p>
								{loginPageT("HAVE_NO_ACCOUNT")}
								<Link to="/register">
									{commonT("REGISTER")}
								</Link>
							</p>
						</div>
					</div>
				</div>
				<div className="form">
					<LoginForm
						data={formData}
						handleChange={handleFormChange}
						isLoading={isLoading}
						onLoginSubmit={onLoginSubmit}
					/>
				</div>
				<div className="another-login-option">
					<AnotherLoginButton />
				</div>
			</div>
		</div>
	);
};

export default Login;
