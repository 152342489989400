import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import executeHttp from "../../services/api";
import { API_URL } from "../../constants/api-url";

const initialState = {
	searchData: [],
	recentSearchData: [],
    recentSearchPagination:{},
    searchPagination:{},
	showSearchPage : false,
	searchKeyword : ''
};

export const getRecentSearch = createAsyncThunk("search/getRecentSearch", async () => {
	try {
		const response = await executeHttp({
			method: "GET",
			endpoint: API_URL.SEARCH.SERCH_RECENTS,
		});
		return response;
	} catch (error) {
		throw error;
	}
});

export const getSearchLabel = createAsyncThunk("search/getSearchLabel", async () => {
	try {
		const response = await executeHttp({
			method: "GET",
			endpoint: API_URL.SEARCH.SERCH_DROPDOWN,
		});
		return response;
	} catch (error) {
		throw error;
	}
});

export const deleteRecentSearch = createAsyncThunk("search/deleteRecentSearch", async (id, { dispatch }) => {
	try {
		const response = await executeHttp({
			method: "DELETE",
			endpoint: `${API_URL.SEARCH.DELETR_SERCH_RECENTS}/${id}`,
		});
        dispatch(getRecentSearch())
		return response;
	} catch (error) {
		throw error;
	}
});

export const deleteRecentSearchAll = createAsyncThunk("search/deleteRecentSearchAll", async ({ dispatch }) => {
	try {
		const response = await executeHttp({
			method: "DELETE",
			endpoint: API_URL.SEARCH.DELETR_SERCH_RECENTS_ID,
		});
        dispatch(getRecentSearch())
		return response;
	} catch (error) {
		throw error;
	}
});

export const getSearchData = createAsyncThunk("evolution/getEvolutionSearch", async ({data}) => {
	try {
		const response = await executeHttp({
			method: "POST",
			endpoint: API_URL.SEARCH.SERCH_DROPDOWN,
            data: data
		});

		return response;
	} catch (error) {
		throw error;
	}
});

const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		hideRecentSearchContainer : (state, action)=> {
			state.showSearchPage = true;
			state.searchKeyword = action.payload
		},
		showRecentSearchContainer : (state, action)=> {
			state.showSearchPage = false;
			state.searchKeyword = action.payload
		}
	},
	extraReducers: (builder) => {
        builder.addCase(getRecentSearch.fulfilled, (state, action) => {
            state.recentSearchData = action.payload.data.recentSearch;
            state.recentSearchPagination = action.payload.pagination
        });
        builder.addCase(getSearchData.fulfilled, (state, action) => {
            state.searchData = action.payload.data;
            state.searchPagination = action.payload.pagination
        });
    },
});

export const { hideRecentSearchContainer, showRecentSearchContainer } = searchSlice.actions;
export default searchSlice.reducer;