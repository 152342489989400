import { anonymous_icon, anonymous_primary, edit_icon, emoji_five_star, emoji_four_star, infoCircle } from "../../../assets";
import { Modal } from "antd";
import React, { useState } from "react";
import "./style.scss";
import starIcon from "../../../assets/icons/star-icon.svg";
import ConfirmModal from "../ConfirmModal";
import InfoModal from "../InfoModal";
import ThankYouModal from "../ThankYouModal";
import FirstPage from "./FirstPage";
import SecondPhase from "./SecondPhase";
import ThirdPhase from "./ThirdPhase";
import ForthPhase from "./ForthPhase";
import FifthPage from "./FifthPhase";
import SixthPhase from "./SixthPhase";
import TopBar from "./TopBar";
import SideBar from "./SideBar";

const AddEvolutionModal = ({ isModalOpen, setIsModalOpen }) => {
	const [cancelEvolution, setCancelEvolution] = useState(false);
	const [infoModal, setInfoModal] = useState(false);
	const [thankYouModal, setThankYouModal] = useState(false);
	const [current, setCurrent] = useState(0);
	const [opinion, setOpinion] = useState("");
	const [selectedLabels, setSelectedLabels] = useState([]);
	const [careerSelection, setCareerSelection] = useState(null);
	const [selectedProfessor, setSelectedProfessor] = useState({});
	const [selectedCourse, setSelectedCourse] = useState({});

	const onChange = (key) => {
		console.log(key);
	};

	const next = () => {
		setCurrent(current + 1);
	};
	const prev = () => {
		setCurrent(current - 1);
	};

	const closeCancle = () => {
		setCancelEvolution(false);
	};

	const handleSearchSelect = (data) => {
		next();
		console.log("data", data);
		if (data?.type === "Profesor") {
			setSelectedProfessor({
				id: data?.id,
				name: data?.fullName,
			});
		} else if (data?.type === "Curso") {
			setSelectedCourse({
				id: data?.id,
				name: data?.name,
			});
		}
	};

	const steps = [
		{
			title: "Phase 1",
			content: <FirstPage next={next} careerSelection={careerSelection} handleSearchSelect={handleSearchSelect} />,
		},
		{
			title: "Phase 2",
			content: (
				<SecondPhase
					next={next}
					prev={prev}
					selectedProfessor={selectedProfessor}
					selectedCourse={selectedCourse}
					careerSelection={careerSelection}
					setSelectedProfessor={setSelectedProfessor}
					setSelectedCourse={setSelectedCourse}
				/>
			),
		},
		{
			title: "Phase 3",
			content: <ThirdPhase current={current} setCurrent={setCurrent} prev={prev} next={next} setInfoModal={setInfoModal} infoCircle={infoCircle} />,
		},
		{
			title: "Phase 4",
			content: <ForthPhase next={next} anonymous_icon={anonymous_icon} opinion={opinion} setOpinion={setOpinion} />,
		},
		{
			title: "Phase 5",
			content: <FifthPage next={next} onChange={onChange} selectedLabels={selectedLabels} setSelectedLabels={setSelectedLabels} />,
		},
		{
			title: "Phase 6",
			content: (
				<SixthPhase
					next={next}
					starIcon={starIcon}
					emoji_five_star={emoji_five_star}
					edit_icon={edit_icon}
					emoji_four_star={emoji_four_star}
					anonymous_primary={anonymous_primary}
					setThankYouModal={setThankYouModal}
					opinion={opinion}
					selectedLabels={selectedLabels}
					setCurrent={setCurrent}
					setSelectedLabels={setSelectedLabels}
					setOpinion={setOpinion}
				/>
			),
		},
	];

	const handleCancel = () => {
		setIsModalOpen(false);
		closeCancle();
		setThankYouModal(false);
	};

	return (
		<>
			<Modal title="" className="evolution_modal" open={isModalOpen}>
				<div className="main_modal_wrapper">
					<TopBar setCancelEvolution={setCancelEvolution} />

					<div className="content_modal">
						<SideBar careerSelection={careerSelection} setCareerSelection={setCareerSelection} current={current} selectedProfessor={selectedProfessor} selectedCourse={selectedCourse} />
						<div className="playground_modal">{steps[current].content}</div>
					</div>
				</div>
			</Modal>

			<ConfirmModal
				text="Debers verificar tu correo institucional y/o teléfono para poder evaluar"
				desc="Por favor, verifica tu correo y/o teléfono para poder realizar evaluaciones. Esto nos permitirá saber que eres un usuario auténtico."
				closeFunction={closeCancle}
				cancelFunction={handleCancel}
				openState={cancelEvolution}
			/>
			<InfoModal isModalOpen={infoModal} setIsModalOpen={setInfoModal} />
			<ThankYouModal isModalOpen={thankYouModal} setIsModalOpen={setThankYouModal} closeFunction={closeCancle} cancelFunction={handleCancel} />
		</>
	);
};

export default AddEvolutionModal;
