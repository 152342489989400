import React, { useState } from "react";
import "./style.scss";
import myPic from "../../assets/images/my-pic.png";
import check from "../../assets/icons/right-icon.svg";
import like_cyan from "../../assets/icons/like-cyan.svg";
import dislike_red from "../../assets/icons/dislike-red.svg";
import dislike_cyan from "../../assets/icons/dislike-cyan.svg";
import OnlyBar from "../Common/ProgressBar/OnlyBar";
import { Button, Dropdown, Menu } from "antd";
import { dislike_gray_outline, dropdown_arrow, like_gray, like_gray_outline } from "../../assets";
import starIcon from "../../assets/icons/star-icon.svg";
import { EllipsisOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const UserProfile = () => {
    const [selectedKey, setSelectedKey] = useState("relevantes");

    const selectedTitle = {
        relevantes: "Más relevantes",
        antiguos: "Más antiguos primero",
        recientes: "Más recientes primero",
        alta: "Calificación alta primero",
        baja: "Calificación baja primero",
        alfabetico: "Orden alfabético",
    }[selectedKey];

    const handleMenuClick = ({ key }) => {
        setSelectedKey(key);
    };

    const menu = (
        <Menu
            className="btn_dropdown"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
        >
            <Menu.Item key="relevantes">Más relevantes</Menu.Item>
            <Menu.Item key="antiguos">Más antiguos primero</Menu.Item>
            <Menu.Item key="recientes">Más recientes primero</Menu.Item>
            <Menu.Item key="alta">Calificación alta primero</Menu.Item>
            <Menu.Item key="baja">Calificación baja primero</Menu.Item>
            <Menu.Item key="alfabetico">Orden alfabético</Menu.Item>
        </Menu>
    );
    return (
        <>
            <section className="top_semi_banner_sec"></section>
            <section className="user_prifile_sec">
                <div className="container md_container">
                    <div className="inner_wrap">
                        <div className="user_profile_wrapper">
                            <div className="user_card">
                                <div className="my_info">
                                    <div className="my_pic">
                                        <img src={myPic} alt="my-Pic" />
                                    </div>
                                    <div className="name">
                                        <h4 className="title">
                                            Jorge Mendizábal
                                        </h4>
                                        <div className="verify">
                                            {/* {userCheck} */}
                                            <img src={check} alt="verified" />
                                        </div>
                                    </div>
                                    <div className="short_name">
                                        <h6 className="title">j_mendizabal</h6>
                                    </div>
                                </div>

                                <div className="about_me">
                                    <div className="tab_badge">
                                        <p>Usuario</p>
                                    </div>
                                    <div className="profession">
                                        <h6>Médico y cirujano</h6>
                                        <p>Facultad de Ciencias Médicas USAC</p>
                                    </div>
                                </div>


                                <div className="evaluations_wrap">
                                    <div className="evaluations_box">
                                        <div className="evaluations_head">
                                            <p className="number">5</p>
                                            <h4 className="title">Evaluaciones</h4>
                                        </div>
                                        <OnlyBar />
                                    </div>

                                    <div className="approvals_list_wrap">
                                        <div className="approvals_list">
                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        <img
                                                            src={like_cyan}
                                                            alt="Like"
                                                        />
                                                    </div>
                                                    <h3 className="title">45</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">
                                                        Aprobaciones
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="ap_item">
                                                <div className="icon_txt">
                                                    <div className="icon">
                                                        <img
                                                            src={dislike_red}
                                                            alt="Dislike"
                                                        />
                                                    </div>
                                                    <h3 className="title">14</h3>
                                                </div>

                                                <div className="approval_info">
                                                    <p className="title">
                                                        Desaprobaciones
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bottom_row">
                                    <small>Se unió el 09/09/2020</small>
                                </div>
                            </div>
                        </div>
                        <div className="user_profile_listing">
                            <div className="list_option_wrap">
                                <div className="desc">
                                    <h3 className="title">Evaluaciones</h3>
                                </div>
                                <div className="drop_box">
                                    <div className="dropdown_box">
                                        <Dropdown overlay={menu}>
                                            <Button>
                                                {selectedTitle}
                                                <i className="fas fa-caret-down" />
                                                <div className="icon_box FaAngleDown">
                                                    {dropdown_arrow}
                                                </div>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>

                            <div className="list_details_wrapper">
                                <div className="list_details_card">
                                    <div className="top_bar">
                                        <div className="user_info">
                                            <div className="name">
                                                <h4 className="title">
                                                    Jorge Mendizábal
                                                </h4>
                                                <div className="verify">
                                                    <img
                                                        src={check}
                                                        alt="verified"
                                                    />
                                                </div>
                                            </div>
                                            <div className="course">
                                                <p>Curso: Biología y genética I</p>
                                            </div>
                                            <div className="date">
                                                <p>11/04/2021</p>
                                            </div>
                                           
                                        </div>

                                        <div className="card_widget">
                                            <div className="rating_wrap">
                                                <span className="rat_box">4</span>
                                                <span className="star_box">
                                                    <img src={starIcon} alt="" />
                                                </span>
                                            </div>
                                            
                                            <div className="more_option_wrap">
                                                <EllipsisOutlined
                                                    style={{ fontSize: 25 }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <div className="selected_list_wrap">
                                            <p className="item">La volvería a tomar</p>
                                            <p className="item">Profesor con vocación</p>
                                            <p className="item">Desorganizado</p>
                                        </div>
                                        <div className="desc">
                                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas  praesentium voluptatum delen...</p>
                                        </div>
                                        <div className="body_widget">
                                            <div className="more_less">
                                                <Link>Leer más</Link>
                                            </div>
                                            <div className="like_dislike">
                                                <div className="like_box">
                                                    <div className="icon">
                                                        <img src={like_cyan} alt="Like" />
                                                    </div>
                                                    <p className="number">10</p>
                                                </div>
                                                <div className="dislike_box">
                                                    <div className="icon">
                                                        <img src={dislike_cyan} alt="dis Like" />
                                                    </div>
                                                    <p className="number">5</p>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="bottom_bar">
                                        <Link>
                                            <div className="icon">
                                                {like_gray_outline}
                                            </div>
                                            <span className="text">De acuerdo</span>
                                        </Link>
                                        <Link>
                                            <div className="icon">
                                                {dislike_gray_outline}
                                            </div>
                                            <span className="text">En desacuerdo</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="list_details_card">
                                    <div className="top_bar">
                                        <div className="user_info">
                                            <div className="name">
                                                <h4 className="title">
                                                    Jorge Mendizábal
                                                </h4>
                                                <div className="verify">
                                                    <img
                                                        src={check}
                                                        alt="verified"
                                                    />
                                                </div>
                                            </div>
                                            <div className="course">
                                                <p>Curso: Biología y genética I</p>
                                            </div>
                                            <div className="date">
                                                <p>11/04/2021</p>
                                            </div>
                                           
                                        </div>

                                        <div className="card_widget">
                                            <div className="rating_wrap">
                                                <span className="rat_box">4</span>
                                                <span className="star_box">
                                                    <img src={starIcon} alt="" />
                                                </span>
                                            </div>
                                            
                                            <div className="more_option_wrap">
                                                <EllipsisOutlined
                                                    style={{ fontSize: 25 }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <div className="selected_list_wrap">
                                            <p className="item">La volvería a tomar</p>
                                            <p className="item">Profesor con vocación</p>
                                            <p className="item">Desorganizado</p>
                                        </div>
                                        <div className="desc">
                                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas  praesentium voluptatum delen...</p>
                                        </div>
                                        <div className="body_widget">
                                            <div className="more_less">
                                                <Link>Leer más</Link>
                                            </div>
                                            <div className="like_dislike">
                                                <div className="like_box">
                                                    <div className="icon">
                                                        <img src={like_cyan} alt="Like" />
                                                    </div>
                                                    <p className="number">10</p>
                                                </div>
                                                <div className="dislike_box">
                                                    <div className="icon">
                                                        <img src={dislike_cyan} alt="dis Like" />
                                                    </div>
                                                    <p className="number">5</p>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="bottom_bar">
                                        <Link>
                                            <div className="icon">
                                                {like_gray_outline}
                                            </div>
                                            <span className="text">De acuerdo</span>
                                        </Link>
                                        <Link>
                                            <div className="icon">
                                                {dislike_gray_outline}
                                            </div>
                                            <span className="text">En desacuerdo</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="list_details_card">
                                    <div className="top_bar">
                                        <div className="user_info">
                                            <div className="name">
                                                <h4 className="title">
                                                    Jorge Mendizábal
                                                </h4>
                                                <div className="verify">
                                                    <img
                                                        src={check}
                                                        alt="verified"
                                                    />
                                                </div>
                                            </div>
                                            <div className="course">
                                                <p>Curso: Biología y genética I</p>
                                            </div>
                                            <div className="date">
                                                <p>11/04/2021</p>
                                            </div>
                                           
                                        </div>

                                        <div className="card_widget">
                                            <div className="rating_wrap">
                                                <span className="rat_box">4</span>
                                                <span className="star_box">
                                                    <img src={starIcon} alt="" />
                                                </span>
                                            </div>
                                            
                                            <div className="more_option_wrap">
                                                <EllipsisOutlined
                                                    style={{ fontSize: 25 }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <div className="selected_list_wrap">
                                            <p className="item">La volvería a tomar</p>
                                            <p className="item">Profesor con vocación</p>
                                            <p className="item">Desorganizado</p>
                                        </div>
                                        <div className="desc">
                                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas  praesentium voluptatum delen...</p>
                                        </div>
                                        <div className="body_widget">
                                            <div className="more_less">
                                                <Link>Leer más</Link>
                                            </div>
                                            <div className="like_dislike">
                                                <div className="like_box">
                                                    <div className="icon">
                                                        <img src={like_cyan} alt="Like" />
                                                    </div>
                                                    <p className="number">10</p>
                                                </div>
                                                <div className="dislike_box">
                                                    <div className="icon">
                                                        <img src={dislike_cyan} alt="dis Like" />
                                                    </div>
                                                    <p className="number">5</p>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="bottom_bar">
                                        <Link>
                                            <div className="icon">
                                                {like_gray_outline}
                                            </div>
                                            <span className="text">De acuerdo</span>
                                        </Link>
                                        <Link>
                                            <div className="icon">
                                                {dislike_gray_outline}
                                            </div>
                                            <span className="text">En desacuerdo</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="list_details_card">
                                    <div className="top_bar">
                                        <div className="user_info">
                                            <div className="name">
                                                <h4 className="title">
                                                    Jorge Mendizábal
                                                </h4>
                                                <div className="verify">
                                                    <img
                                                        src={check}
                                                        alt="verified"
                                                    />
                                                </div>
                                            </div>
                                            <div className="course">
                                                <p>Curso: Biología y genética I</p>
                                            </div>
                                            <div className="date">
                                                <p>11/04/2021</p>
                                            </div>
                                           
                                        </div>

                                        <div className="card_widget">
                                            <div className="rating_wrap">
                                                <span className="rat_box">4</span>
                                                <span className="star_box">
                                                    <img src={starIcon} alt="" />
                                                </span>
                                            </div>
                                            
                                            <div className="more_option_wrap">
                                                <EllipsisOutlined
                                                    style={{ fontSize: 25 }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card_body">
                                        <div className="selected_list_wrap">
                                            <p className="item">La volvería a tomar</p>
                                            <p className="item">Profesor con vocación</p>
                                            <p className="item">Desorganizado</p>
                                        </div>
                                        <div className="desc">
                                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas  praesentium voluptatum delen...</p>
                                        </div>
                                        <div className="body_widget">
                                            <div className="more_less">
                                                <Link>Leer más</Link>
                                            </div>
                                            <div className="like_dislike">
                                                <div className="like_box">
                                                    <div className="icon">
                                                        <img src={like_cyan} alt="Like" />
                                                    </div>
                                                    <p className="number">10</p>
                                                </div>
                                                <div className="dislike_box">
                                                    <div className="icon">
                                                        <img src={dislike_cyan} alt="dis Like" />
                                                    </div>
                                                    <p className="number">5</p>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="bottom_bar">
                                        <Link>
                                            <div className="icon">
                                                {like_gray_outline}
                                            </div>
                                            <span className="text">De acuerdo</span>
                                        </Link>
                                        <Link>
                                            <div className="icon">
                                                {dislike_gray_outline}
                                            </div>
                                            <span className="text">En desacuerdo</span>
                                        </Link>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserProfile;
