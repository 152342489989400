import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH_LIST } from '../../../constants/paths';
import FormButton from '../../../components/Button/FormButton';

const CommunitySection = () => {
  const navigate = useNavigate()

    const handleButtonClick = ()=> {
        navigate(PATH_LIST.USER.REGISTER)
    }
    return (
        <div className='container semi_container'>
            <div className='cta_wrap'>
                <div className='sec_head'>
                    <h2 className='title'>Únete a la comunidad Saikit</h2>
                    <p> Forma parte de la acción universitaria para hacer visible las experiencias con las materias y catedráticos de la U.</p>
                </div>
                <div className='btn_wrap'>
                    <FormButton
                    className='primary_alt'
                        type='primary'
                        title="Quiero unirme"
                        onClick={handleButtonClick}
                        />
                </div>
            </div>
        </div>
    );
}

export default CommunitySection