import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import executeHttp from "../../services/api";
import { API_URL } from '../../constants/api-url';

// const initialState = {
// };

// PHASE 1
export const submitOnboardForm = createAsyncThunk(
    "onboard/submitOnboardForm",
    async (userData) => {
        try {
            const response = await executeHttp({
                method: 'POST',
                endpoint: `${API_URL.USER.PROFILE}`,
                data: userData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const uploadPhotoFile = createAsyncThunk(
    "onboard/uploadPhotoFile",
    async (userData) => {
        try {
            const response = await executeHttp({
                method: 'POST',
                endpoint: `${API_URL.USER.UPLOAD_PHOTO_FILE}`,
                data: userData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const userUploadPhoto = createAsyncThunk(
    "onboard/userUploadPhoto",
    async (userData) => {
        try {
            const response = await executeHttp({
                method: 'POST',
                endpoint: `${API_URL.USER.UPLOAD_PHOTO}`,
                data: userData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);


// PHASE 2
export const getUniversities = createAsyncThunk(
    "onboard/getUniversities",
    async () => {
        try {
            const response = await executeHttp({
                method: 'GET',
                endpoint: `${API_URL.UNIVERSITIES.UNIVERSITIES}`,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);
export const getCareer = createAsyncThunk(
    "onboard/getCareer",
    async () => {
        try {
            const response = await executeHttp({
                method: 'GET',
                endpoint: `${API_URL.CAREER.CAREER}`,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const userCareer = createAsyncThunk(
    "onboard/userCareer",
    async (userData) => {
        try {
            const response = await executeHttp({
                method: 'POST',
                endpoint: `${API_URL.USER.CAREER}`,
                data: userData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

const onBoardSlice = createSlice({
    name: "onboard",
    initialState: {
        universities: [],
        countries: []
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getUniversities.fulfilled, (state, action) => {
            return { ...state, universities: action.payload };
        });
        builder.addCase(getCareer.fulfilled, (state, action) => {
            return { ...state, countries: action.payload };
        });
    },
});

export default onBoardSlice.reducer;