import RecommendedProfessor from "./RecommendedProfessor";
import UniversityCarousel from "./UniversityCarousel";
import ExperienceSection from "./ExperienceSection";
import AssignmentSection from "./AssignmentSection";
import TopProfessorList from "./TopProfessorList";
import CommunitySection from "./CommunitySection";
import ContactUsSection from "./ContactUsSection";
import UpdateMessage from "./UpdateMessage";
import Proposal from "./Proposal";
import { useState } from "react";
import Feature from "./Feature";
import "./style.scss";
import { FloatButton } from "antd";

const StaticHome = () => {
    const [updateMessage, setUpdateMessage] = useState(true);
    const sections = [
        { component: <Proposal />, className: "proposal-container" },
        { component: <RecommendedProfessor />, className: "recommended-professor" },
        { component: <Feature />, className: "features" },
        { component: <UniversityCarousel />, className: "university-Carausel" },
        { component: <TopProfessorList />, className: "professor-container" },
        { component: <ExperienceSection />, className: "experience-section" },
        { component: <AssignmentSection />, className: "assignment-section" },
        { component: <CommunitySection />, className: "cta_sec" },
        { component: <ContactUsSection />, className: "contact-us-section" },
    ];

    const renderSections = () => {
        return sections.map((section, index) => (
            <section
                key={index}
                className={section.className}>
                {section.component}
            </section>
        ));
    };

    return (
        <div className="static-home">
            <div className="update-message-container">{updateMessage && <UpdateMessage setUpdateMessage={setUpdateMessage} />}</div>
            {renderSections()}
            <FloatButton.BackTop
                shape="square"
                icon={
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.01782 23.3539L17.7133 12.6584L28.4087 23.3539"
                            stroke="#5C30A1"
                            stroke-width="3.50889"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
        </div>
    );
};
export default StaticHome;
