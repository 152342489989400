import university1 from "../../../assets/images/university-1.png";
import university2 from "../../../assets/images/university-2.png";
import university3 from "../../../assets/images/university-3.png";
import university4 from "../../../assets/images/university-4.png";
import university5 from "../../../assets/images/university-5.png";
import university6 from "../../../assets/images/university-6.png";
import university7 from "../../../assets/images/university-7.png";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "./style.css";

const UniversityCarousel = () => {
    const images = [university1, university2, university3, university4, university5, university6, university7]
    return (
        <div className="container">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={30}
                slidesPerView={6}
                pagination={false}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: true,
                    enabled: true
                }}
                loop={true}
                navigation={false}
            >
                {
                    images && images.length > 0 &&
                    images.map((img, id) => {
                        return (
                            <SwiperSlide key={id} className="item">
                                <div className="logo_wrap">
                                    <img src={img} alt="" />
                                </div>
                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>

            <div className="mobile_grid">
                {
                    images && images.length > 0 &&
                    images.map((img, id) => {
                        return (
                            <div className="logo_wrap">
                                <img src={img} alt="" />
                            </div>
                        )
                    })
                }
            </div>
        </div>

    );
};
export default UniversityCarousel;
