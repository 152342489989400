import { Link, useNavigate } from "react-router-dom";
import RegistrationForm from "./RegistrationForm";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import "../style.scss";
import { useTranslation } from 'react-i18next';
import { OnAuthSubmit } from "../../../helpers/auth";
import { register } from "../../../store/slices/authSlice";
import AuthLeft from "../../../components/Common/AuthLeft";
import { PATH_LIST } from "../../../constants/paths";
import AnotherLoginButton from "../../../components/Button/AnotherLoginButton";
import TermsAndConditionsModal from '../../../components/Modals/TermsAndConditionsModal';

const Register = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t: commonT } = useTranslation('common'); 
    const { t: registerPageT } = useTranslation('registerPage'); 

    //signup form state
    const [formData, setFormData] = useState({ 
        name: "", 
        lastName: "", 
        email: "", 
        userName: "", 
        password: "", 
    });

    //modal state
    const [termModal, setTermModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    //password validation
    const [passValidate, setPassValidate] = useState({
        lengthValid: false,
        numberValid: false,
        lowercaseValid: false,
        specialCharValid: false,
        uppercaseValid: false,
    });

    const handleFormChange = (e, name) => {
        let changedField = { ...formData };
        const value = e.target.value;
        changedField[name] = value;
        if (name === "password") {
            setPassValidate({
                lengthValid: value.length >= 8,
                numberValid: /\d/.test(value),
                lowercaseValid: /[a-z]/.test(value),
                specialCharValid: /[./@#$%^&!*]/.test(value),
                uppercaseValid: /[A-Z]/.test(value),
            });
        }
        setFormData({ ...changedField });
    };

    const onSignUpSubmit = async (values) => {
        OnAuthSubmit('signUp', setIsLoading, formData, dispatch, navigate)
        dispatch(register(formData))
    };

    return (
        <>
            <div className="auth-section register-page">
                <AuthLeft />
                <div className="auth-right">
                    <div className='fixed-width'>
                        <div className="header">
                            <h1>{registerPageT('WELCOME_GREETING')}</h1>
                            <div className="already-user">
                                <p>{registerPageT('ALREDY_HAVE_ACCOUNT')} <Link to={PATH_LIST.USER.LOGIN}>{commonT('LOGIN')}</Link></p>
                            </div>
                        </div>
                    </div>
                    <div className="form">
                        <RegistrationForm data={formData} handleChange={handleFormChange} isLoading={isLoading} onSignUpSubmit={onSignUpSubmit} passValidate={passValidate}/>
                        <div className="term-condition">
                            <span>
                                {registerPageT('ACCEPT_POLICY')}
                                <span className="info" onClick={() => setTermModal(true)}>
                                    {registerPageT('TERMS_AND_CONDITION')}
                                </span>
                            </span>
                        </div>
                        <div className="another-login-option">
                            <AnotherLoginButton />
                        </div>
                    </div>
                </div>
            </div>
            {termModal && (
                <TermsAndConditionsModal
                    closeModal={termModal}
                    setCloseModal={setTermModal}
                />
            )}
        </>
    );
};

export default Register;
