import React from "react";
import footerLogo from '../../assets/icons/footer-logo.svg';
import logo from "../../assets/icons/logo.svg";
import device from "../../assets/images/device.png";
import "./style.scss";
import { Teams } from "../../constants/team-data";
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Collapse, FloatButton } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { charector, charectorBoy, charectorOld, charectorSamuh, charectorStudent, dropdown_arrow, emojy_logo, graphicEmoji, graphicEmoji2, visionEmoji } from "../../assets";


const AboutUs = () => {
	return (
		<>


			<section className="mini_banner about_banner">
				<div className="container">
					<div className="sec_head">
						<h1 className="title">¿Quiénes somos?</h1>
						<p>Nuestro nombre Saikit proviene de un juego de letras de la palabra sidekick en inglés. </p>
					</div>
				</div>
			</section>

			<section className="word_animation_sec">
				<div className="container">
					<div className="inner_wrap">
						<div className="text_wrapper">
							<div className="site_logo">
								<img src={logo} alt="logo" />
							</div>
							<div className="equal_to"><p>=</p></div>
							<div className="word_wrap">
								<Swiper
									direction='vertical'
									modules={[Autoplay]}
									slidesPerView={1}
									autoplay={{
										delay: 2000,
										disableOnInteraction: true,
										enabled: true
									}}
									loop={true}
									centeredSlides={true}
								>
									<SwiperSlide >
											<div className="item"><p>Sidekick</p></div>
									</SwiperSlide>
									<SwiperSlide >
											<div className="item"><p>Apoyo</p></div>
									</SwiperSlide>
									<SwiperSlide >
											<div className="item"><p>Complicidad</p></div>
									</SwiperSlide>
								
								</Swiper>

							</div>
						</div>
						<div className="emojo_wrap">
							<div className="logo">
								{emojy_logo}
								
							</div>
						</div>
					</div>
				</div>
			</section>

		    <section className="info_graphic_sec">
				<div className="container sm_semi_container">
					<div className="info_img_col">
						<div className="info_col_wrap">
							<div className="info_col sec_head">

							<h2 className="title"><span className="highlight2">Apoyo</span> y complicidad</h2>
							<p>
								¿Reconoces a los personajes que acompañan a los héroes en sus retos y aventuras?
								Ellos son sus <strong>cómplices</strong>, les brindan <strong>apoyo</strong> y son quienes les ayudan a cumplir sus
								objetivos. Son su mano derecha y están a su lado recordándoles sus cualidades y
								propósitos.{" "}
							</p>
							</div>
						</div>
						<div className="img_col_wrap">
							<div className="img_wrapper">
								<div className="charector_wrap">
									{charectorBoy}
								</div>
								
								<div className="charector_wrap">
									{charectorSamuh}
								</div>
							</div>
						</div>
					</div>						
				</div>
			</section>
		    
			<section className="info_graphic_sec info_graphic_sec2">
				<div className="emojo_wrap doodle">
					<div className="logo">
						{graphicEmoji2}
					</div>
				</div>
				<div className="container sm_semi_container">
					<div className="info_img_col rev_col">
						
						<div className="img_col_wrap">
							<div className="img_wrapper">
								<div className="charector_wrap">
									{charectorStudent}
								</div>
								
								<div className="charector_wrap">
									{charectorOld}
								</div>
							</div>
						</div>

						<div className="info_col_wrap">
							<div className="info_col sec_head">

								<h2 className="title">Te acompañamos en tu <span className="highlight2"> crecimiento</span></h2>
								<p>
									Nos identificamos con este concepto de sidekick porque aspiramos a ser los <strong>amigos</strong>  y <strong>cómplices</strong>  que acompañarán a los heroicos estudiantes y profesores en sus aventuras de aprendizaje y crecimiento.{" "}
								</p>
							</div>
						</div>
					</div>						
				</div>
			</section>

			<section className="vision_sec">
				<div className="emojo_wrap doodle">
					<div className="logo">
						{visionEmoji}
					</div>
				</div>
				<div className="container semi_container">
					<div className="inner_wrap">
						<div className="info_col_wrap">
							<div className="info_col sec_head">
								<h2 className="title"><span className="highlight2"> Visión </span></h2>
								<p> Ser un espacio libre y seguro de opinión sobre la experiencia del aprendizaje, con un sistema de evaluación y ponderación objetivo, materializando la información en oportunidades de mejora y reconocimiento.
								</p>
							</div>
						</div>
						
						<div className="info_col_wrap">
							<div className="info_col sec_head">
								<h2 className="title"><span className="highlight2"> Misión </span></h2>
								<p> En Saikit buscamos escribir e inspirar el futuro, forjando el ciclo de aprendizaje, permitiendo acortar el camino a los sueños, celebrar los éxitos y generar criterio; con innovación tecnológica, transparencia y empatía con nuestros usuarios.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="card_slider_sec">
				<div className="container">
					<div className="sec_head">
						<h2 className="title">Nuestros Valores</h2>
					</div>

					<div className="card_slider_wrapper">
						<Swiper
							modules={[Autoplay]}
							slidesPerView={1}
							loop={true}
							centeredSlides={true}
							spaceBetween={40}
							// autoplay={{
								// 	delay: 2000,
								// 	disableOnInteraction: true,
								// 	enabled: true
								// }}
								breakpoints={{
									0: {
								  spaceBetween: 16,
								},
								768: {
								  spaceBetween: 26,
								},
								1024: {
								  spaceBetween: 40,
								},
							}}
						>
							<SwiperSlide >
									<div className="item">
										<div className="card_wrap">
											<div className="title_wrap">
												<h2 className="title">Innovación</h2>
											</div>
											<div className="info_col">
												<p>Somos partícipes de un movimiento que busca mejorar el bienestar social, mediante la innovación y el desarrollo de soluciones basadas en tecnología.</p>
											</div>
										</div>
									</div>
							</SwiperSlide>
							<SwiperSlide >
									<div className="item">
										<div className="card_wrap">
											<div className="title_wrap">
												<h2 className="title">Innovación</h2>
											</div>
											<div className="info_col">
												<p>Somos partícipes de un movimiento que busca mejorar el bienestar social, mediante la innovación y el desarrollo de soluciones basadas en tecnología.</p>
											</div>
										</div>
									</div>
							</SwiperSlide>
							<SwiperSlide >
									<div className="item">
										<div className="card_wrap">
											<div className="title_wrap">
												<h2 className="title">Innovación</h2>
											</div>
											<div className="info_col">
												<p>Somos partícipes de un movimiento que busca mejorar el bienestar social, mediante la innovación y el desarrollo de soluciones basadas en tecnología.</p>
											</div>
										</div>
									</div>
							</SwiperSlide>
							
						
						</Swiper>

					</div>
				</div>
			</section>

			<section className="info_graphic_sec emoji_info_sec">
				<div className="container sm_semi_container">
					<div className="info_img_col">
						<div className="info_col_wrap">
							<div className="info_col sec_head">
								<h2 className="title">Ella es Aly</h2>
								<p>Es nuestra heroína, amiga y confidente. Su caparazón es el traje heroico que la acompaña a todas partes para ayudar a quien lo necesite. Es <strong>divertida, honesta, alegre</strong> y está siempre atenta para responder a cualquier señal de auxilio y protegerte con su caparazón. Cuando la veas, salúdala, sus ojitos encantarán tu vida con su profundidad y gentileza.{" "}
								</p>
							</div>
						</div>
						<div className="img_col_wrap">
							<div className="img_wrapper">
								<div className="emoji_wrap">
									{graphicEmoji}
								</div>
							</div>
						</div>
					</div>						
				</div>
			</section>





			<section className="grid_sec card_grid_sec">
				<div className="container semi_container">
					<div className="sec_head">
						<h2 className="title">El equipo</h2>
					</div>
					<div className="card_inner_wrapper">
							{Teams?.map((data, id) => {
								return (
									<div className="card" key={id}>
										<div className="inner_card">
											<div className="img_wrapper">
												<div className="img_wrap">
													{/* <img src={} alt="" /> */}
												</div>
											</div>
											<div className="card_body">
												<h3 className="title">{data?.name}</h3>


												<div className="collapse_wrapper hide_in_mobile">
													<h4 className="title">{data?.emotion}</h4>
													<p>{data?.about}</p>
												</div>
												
												<div className="collapse_wrapper hide_in_desktop">
													<Collapse
														items={[
															{
																key: '1',
																label: <h4>{data?.emotion}</h4>,
																children: <p>{data?.about}</p>,
															},
														]}
														// expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}	
														expandIcon={({ isActive }) => dropdown_arrow }	
													/>
												</div>

											</div>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</section>

			<FloatButton.BackTop
                shape="square"
                icon={
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.01782 23.3539L17.7133 12.6584L28.4087 23.3539"
                            stroke="#5C30A1"
                            stroke-width="3.50889"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
		</>
	);
};

export default AboutUs;






