import React from "react";
import starIcon from "../../../assets/icons/star-icon.svg";
import rightIcon from "../../../assets/icons/right-icon.svg";
import universityIcon1 from "../../../assets/icons/university-1.svg";
import universityIcon2 from "../../../assets/icons/university-2.svg";
import { useNavigate } from "react-router-dom";
import FormButton from "../../../components/Button/FormButton";
import { PATH_LIST } from "../../../constants/paths";

const TopProfessorList = () => {
    const navigate = useNavigate()
    
	const data = [
		{
			starRating: 4,
			name: "Mario Rafael Juárez González",
			university: "USAC, Ciencias Médicas - URL, Ciencias",
			faculties: "económicas y empresariales",
			icon1: universityIcon1,
			icon2: universityIcon2,
		},
		{
			starRating: 4,
			name: "Mario Rafael Juárez González",
			university: "USAC, Ciencias Médicas - URL, Ciencias",
			faculties: "económicas y empresariales",
			icon1: universityIcon1,
			icon2: universityIcon2,
		},
		{
			starRating: 4,
			name: "Mario Rafael Juárez González",
			university: "USAC, Ciencias Médicas - URL, Ciencias",
			faculties: "económicas y empresariales",
			icon1: universityIcon1,
			icon2: universityIcon2,
		},
		{
			starRating: 4,
			name: "Mario Rafael Juárez González",
			university: "USAC, Ciencias Médicas - URL, Ciencias",
			faculties: "económicas y empresariales",
			icon1: universityIcon1,
			icon2: universityIcon2,
		},
		{
			starRating: 4,
			name: "Mario Rafael Juárez González",
			university: "USAC, Ciencias Médicas - URL, Ciencias",
			faculties: "económicas y empresariales",
			icon1: universityIcon1,
			icon2: universityIcon2,
		},
	];

    const handleButtonClick = ()=> {
        navigate(PATH_LIST.USER.REGISTER)
    }
	return (
		<div className="container semi_container">
			<div className="inner_wrap">
				<div className="professor-container-left">
					<div className="data_list">
						{data &&
							data.length > 0 &&
							data.map((data, id) => {
								return (
									<div className="card-container" key={id}>
										<div className="top_bar">
											<div className="rating_wrap">
												<span className="rat_box">{data.starRating}</span>
												<span className="star_box">
													<img src={starIcon} alt="" />
												</span>
											</div>
											<div className="user_info">
												<span className="profile_icon">MJ</span>
												<span className="name">Mario Rafael Juárez González</span>
												<span className="verify">
													<img src={rightIcon} alt="" />
												</span>
											</div>
										</div>
										<div className="bottom_bar">
											<div className="user_details">
												<div className="info_icon">
													<img src={data?.icon1} alt="" />
													<img src={data?.icon2} alt="" />
												</div>
												<div className="user_desc">
													<p>
														<strong>USAC</strong>, Ciencias Médicas - <strong>URL</strong>, Ciencias económicas y
														empresariales
													</p>
												</div>
											</div>
										</div>
									</div>
								);
							})}
							<FormButton
						className='primary_alt tablet_view'
							type='primary'
							title="Quiero ver más"
							onClick={handleButtonClick}
						/>
					</div>
				</div>
				<div className="professor-container-right">
					<div className="info_col sec_head ">
						<h2 className="title">Estos son los <span className="highlight2">top</span> catedráticos de la U</h2>
						<p>
							Conoce a los mejor votados y a los menos favoritos de tu carrera, y de tu U en
							general.
						</p>
						<FormButton
						className='primary_alt desktop_view'
							type='primary'
							title="Quiero ver más"
							onClick={handleButtonClick}
						/>

					</div>
				</div>
			</div>
		</div>

	);
};

export default TopProfessorList;
