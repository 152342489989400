import React, { useState } from "react";
import { Button, Progress, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import avt1 from '../../../assets/icons/profile-pic-avtar01.svg';
import avt2 from '../../../assets/icons/profile-pic-avtar02.svg';
import avt3 from '../../../assets/icons/profile-pic-avtar03.svg';
import avt4 from '../../../assets/icons/profile-pic-avtar04.svg';
import defaultFoto from '../../../assets/icons/foto.svg';
import './style.scss';
import { uploadPhotoFile } from "../../../store/slices/onBoardSlice";
import { useDispatch } from "react-redux";

const ProfilePicUploadPage = ({ setUploadPhoto }) => {
  const dispatch = useDispatch();
  const [uploadedPic, setUploadedPic] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const handleAvatarSelection = (avatarIndex) => {
    setSelectedAvatar(avatarIndex);
    setUploadPhoto(avatarIndex)
    setUploadedPic(null);
  };
  const progressPercentage = (2 / 3) * 100;

  const twoColors = {
    "0%": "#0DC9C2",
    "100%": "#5C30A1 ",
  };

  const avatarList = [avt1, avt2, avt3, avt4];

  const props = {
    beforeUpload: (file) => {
      if (file.size > 2097152) {
        message.error(
          'El archivo es demasiado grande. Por favor, selecciona un archivo de máximo 2MB.'
        );
        return false;
      }
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          setUploadedPic(reader.result);
          setUploadPhoto(reader.result);
          setSelectedAvatar(null);

          const response = dispatch(
            uploadPhotoFile({
              photo: reader.result,
            })
          );
          console.log('Upload successful:', response);
        } catch (error) {
          console.error('Upload failed:', error);
        }
      };
      reader.readAsDataURL(file);
      return false;
    },
  };

  return (
    <div className="profile-upload-container">
      <h1>¿Quieres agregar una foto?</h1>
      <Progress percent={progressPercentage} showInfo={false} strokeColor={twoColors} />
      <div className="uploaded-pic-container">
        {uploadedPic ? (
          <img
            src={uploadedPic}
            alt="Uploaded"
            className="uploaded-pic rounded-border"
          />
        ) : (
          <img
            src={selectedAvatar ? avatarList[selectedAvatar - 1] : defaultFoto}
            alt="Avatar"
            className="uploaded-pic rounded-border"
          />
        )}
      </div>

      {!uploadedPic && (
        <div className="upload-pic-input">
          <Upload {...props} accept="image/*">
            <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
              <span className="ant-upload" tabIndex="0">
                <div className="ant-upload-text">
                  <Button icon={<PlusOutlined />}>Sube una foto</Button>
                </div>
              </span>
            </div>
          </Upload>
          <span className="file-upload-info">(Peso máximo 2MB)</span>
        </div>
      )}

      <div>
        <p>O puedes seleccionar un Avatar</p>
        <div className="avatar-selection">
          {avatarList.map((avatar, index) => (
            <img
              key={index + 1}
              src={avatar}
              alt={`Avatar ${index + 1}`}
              className={selectedAvatar === index + 1 ? "selected" : ""}
              onClick={() => handleAvatarSelection(index + 1)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfilePicUploadPage;
