export const searchDataConstant = {
	data: {
		professors: [
			{
				id: 123,
				fullName: "Gilberto José Marroquín cc",
				profileImg: "/img.png",
				aditionalInfo: "USAC, Fac. Ciencias Médicas - URL, Facultad de la Salud",
				score: "4.3",
			},
		],
		courses: [
			{
				id: 124,
				name: "Matemática avanzada cc",
				image: "/img4.png",
				aditionalInfo: "USAC, Medico Cirujano",
			},
		],
		users: [
			{
				id: 125,
				name: "Jorge Mendizábal cc",
				image: "/img2.png",
				aditionalInfo: "USAC, Médico Cirujano",
			},
		],
	},
	pagination: {
		nextCursor: 123414,
		size: 10,
	},
};

export const evolutionCareersConstant = [
	{
		id: 123,
		name: "Médico cirujano fffff",
		image: "/img.png",
		detail: "USAC, Fac. Ciencias Médicas",
	},
	{
		id: 123,
		name: "Maestría en Salud Pública fff",
		image: "/img.png",
		detail: "URL, Fac. Ciencias Médicas",
	},
];

export const evolutionFactorsConstants = [
	{
		id: "1",
		name: "Calidad de enseñanza",
		description: "Evaluate the technical knowledge of the teacher ...",
		priority: 1,
		type: "CHECKBOX",
		levels: [
			{
				label: "Muy mala calidad",
				value: 1,
				image: "/img1.jpg",
			},
			{
				label: "Baja calidad",
				value: 2,
				image: "/img2.jpg",
			},
			{
				label: "Calidad normal",
				value: 3,
				image: "/img3.jpg",
			},
			{
				label: "Buena calidad",
				value: 4,
				image: "/img4.jpg",
			},
			{
				label: "Excelente calidad",
				value: 5,
				image: "/img5.jpg",
			},
		],
	},
	{
		id: "2",
		name: "Criterio de calificación",
		description: "Evaluate the technical knowledge of the teacher ...",
		priority: 2,
		type: "CHECKBOX",
		levels: [
			{
				label: "Muy mala",
				value: 1,
				image: "/img1.jpg",
			},
			{
				label: "Baja",
				value: 2,
				image: "/img2.jpg",
			},
			{
				label: "normal",
				value: 3,
				image: "/img3.jpg",
			},
			{
				label: "Buena",
				value: 4,
				image: "/img4.jpg",
			},
			{
				label: "Excelente",
				value: 5,
				image: "/img5.jpg",
			},
		],
	},
	{
		id: "6",
		name: "Opinión",
		description: "Leave your opinion about the teacher about ...",
		priority: 3,
		type: "TEXT",
	},
];

export const evolutionLabelConstant = [
	{
		name: "Recomendado",
		category: "POSITIVE",
	},
	{
		name: "La volvería a tomar",
		category: "POSITIVE",
	},
	{
		name: "Profesor con vocación",
		category: "POSITIVE",
	},
	{
		name: "Desorganizado",
		category: "NEGATIVE",
	},
	{
		name: "Demasiada tarea",
		category: "NEGATIVE",
	},
	{
		name: "Aburrido",
		category: "NEGATIVE",
	},
];
