import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteRecentSearch,
    deleteRecentSearchAll,
    getRecentSearch,
    getSearchData,
    hideRecentSearchContainer,
    showRecentSearchContainer,
} from "../../../store/slices/searchSlice";
import CustomLabel from "../CustomLabel";
import { Input, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
const { TabPane } = Tabs;

const SearchItem = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { recentSearchData, searchData, searchPagination } = useSelector((state) => state.search);
    const [recentSearch, setRecentSearch] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [activeTab, setActiveTab] = useState("ALL");
    const dropdownRef = useRef(null);
    const searchdropdownRef = useRef(null);

    const handleFocus = () => {
        if (searchText === "") {
            setRecentSearch(true);
            dispatch(getRecentSearch());
        }
    };

    const handleDelete = (id) => {
        dispatch(deleteRecentSearch(id));
    };

    const handleDeleteTodo = () => {
        dispatch(deleteRecentSearchAll());
    };

    const handleSearch = (value, label) => {
        dispatch(showRecentSearchContainer(value));
        setShowSearch(true);
        setRecentSearch(false);
        setSearchText(value);
        const payload = {
            text_search: value,
            type_search: label,
            filters: "",
            sort: "ASC",
            is_top: "false",
            top_size: 20,
            pagination_cursor: null,
            pagination_size: 10,
        };
        dispatch(getSearchData({ data: payload }));
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            dispatch(hideRecentSearchContainer(searchText));
            setShowSearch(false);
        }
    };

    const handleRedirect = (data) => {
        if (data?.type === "Profesor") {
            navigate(`/professor/${data.id}`);
        } else if (data?.type === "Curso") {
            navigate(`/course/${data.id}`);
        } else if (data?.type === "Usuario") {
            navigate(`/user/${data.id}`);
        }
		setSearchText("")
    };

    const renderDataByTab = () => {
        switch (activeTab) {
            case "ALL":
                return (
                    <div className="custom_options">
                        {prof_options?.map((data, index) => (
                            <div
                                className="profession_info"
                                onClick={() => handleRedirect(data)}
                                key={index}>
                                <CustomLabel
                                    name={data.name ? data.name : data?.fullName}
                                    icon={data.image ? data.image : data?.profileImg}
                                    type={data.type}
                                    college={data.detail}
                                    data={data}
                                />
                            </div>
                        ))}
                    </div>
                );
            case "PROFESSOR":
                return (
                    <div className="custom_options">
                        {prof_options
                            .filter((data) => data.type === "Profesor")
                            .map((data, index) => (
                                <div
                                    className="profession_info"
                                    onClick={() => handleRedirect(data)}
                                    key={index}>
                                    <CustomLabel
                                        name={data.name ? data.name : data?.fullName}
                                        icon={data.image ? data.image : data?.profileImg}
                                        type={data.type}
                                        college={data.detail}
                                        data={data}
                                    />
                                </div>
                            ))}
                    </div>
                );
            case "COURSE":
                return (
                    <div className="custom_options">
                        {prof_options
                            .filter((data) => data.type === "Curso")
                            .map((data, index) => (
                                <div
                                    className="profession_info"
                                    onClick={() => handleRedirect(data)}
                                    key={index}>
                                    <CustomLabel
                                        name={data.name ? data.name : data?.fullName}
                                        icon={data.image ? data.image : data?.profileImg}
                                        type={data.type}
                                        college={data.detail}
                                        data={data}
                                    />
                                </div>
                            ))}
                    </div>
                );
            case "USER":
                return (
                    <div className="custom_options">
                        {prof_options
                            .filter((data) => data.type === "Usuario")
                            .map((data, index) => (
                                <div
                                    className="profession_info"
                                    onClick={() => handleRedirect(data)}
                                    key={index}>
                                    <CustomLabel
                                        name={data.name ? data.name : data?.fullName}
                                        icon={data.image ? data.image : data?.profileImg}
                                        type={data.type}
                                        college={data.detail}
                                        data={data}
                                    />
                                </div>
                            ))}
                    </div>
                );
            default:
                return null;
        }
    };

    const prof_options = [
        ...(searchData?.professors || []).map((prof) => ({ ...prof, type: "Profesor" })),
        ...(searchData?.courses || []).map((course) => ({ ...course, type: "Curso" })),
        ...(searchData?.users || []).map((user) => ({ ...user, type: "Usuario" })),
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
                setRecentSearch(false);
            }
        };

		const handleScroll = () => {
			console.log('Scrolling...');
		  };

        document?.addEventListener("mousedown", handleClickOutside);
		searchdropdownRef?.current?.addEventListener('scroll', handleScroll);

        return () => {
            document?.removeEventListener("mousedown", handleClickOutside);
			searchdropdownRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleDropdownClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div className="search-box home">
            <Input
                placeholder="Busca un curso o profesor"
				value={searchText}
                prefix={<SearchOutlined />}
                onFocus={handleFocus}
                onChange={(e) => handleSearch(e.target.value, "ALL")}
                onKeyPress={handleKeyPress}
            />
            {recentSearch &&
                (recentSearchData.length > 0 ? (
                    <div
                        className="recent-search-container"
                        ref={dropdownRef}
                        onClick={handleDropdownClick}>
                        <div className="top-bar">
                            <p className="heading">Recientes</p>
                            <div
                                className="delete"
                                onClick={handleDeleteTodo}>
                                Eliminar todo
                            </div>
                        </div>
                        {recentSearchData.map((item) => (
                            <div
                                key={item.id}
                                className="response-item">
                                <div className="icon">
                                    <svg
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1026_19043)">
                                            <path
                                                d="M13.3008 19C17.1668 19 20.3008 15.866 20.3008 12C20.3008 8.13401 17.1668 5 13.3008 5C9.43479 5 6.30078 8.13401 6.30078 12C6.30078 15.866 9.43479 19 13.3008 19Z"
                                                stroke="#292929"
                                                stroke-opacity="0.4"
                                                stroke-width="1.5"
                                            />
                                            <path
                                                d="M13.3008 8L13.3008 12L15.9307 13.2906"
                                                stroke="#292929"
                                                stroke-opacity="0.4"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1026_19043">
                                                <rect
                                                    width="24"
                                                    height="24"
                                                    fill="white"
                                                    transform="translate(0.800781)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {item.course && <div className="item">{item.course}</div>}
                                    {item.professor && <div className="item">{item.professor}</div>}
                                    {item.user && <div className="item">{item.user}</div>}
                                </div>
                                <div
                                    className="delete"
                                    onClick={() => handleDelete(item.id)}>
                                    Eliminar
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    ""
                ))}
            {searchData && searchText && showSearch && (
                <div className="recent-search-container" ref={dropdownRef}
                onClick={handleDropdownClick}>
                    <Tabs
                        activeKey={activeTab}
                        onChange={handleTabChange}
                        className="tabs-top">
                        <TabPane
                            tab="Todos"
                            key="ALL"
                        />
                        <TabPane
                            tab="Profesor"
                            key="PROFESSOR"
                        />
                        <TabPane
                            tab="Curso"
                            key="COURSE"
                        />
                        <TabPane
                            tab="Usuario"
                            key="USER"
                        />
                    </Tabs>
                    {renderDataByTab()}
                </div>
            )}
        </div>
    );
};

export default SearchItem;
