import Avtar from "../../assets/icons/avatar.svg";
import starIcon from "../../assets/icons/star-icon.svg";
import universityIcon1 from "../../assets/icons/university-1.svg";
import universityIcon2 from "../../assets/icons/university-2.svg";
import rightIcon from "../../assets/icons/right-icon.svg";
const Card = () => {
    return (
        <div className="card">
            <div className="card-container">

                <div className="top_bar">
                    <div className="rating_wrap">
                        <span className="rat_box">4</span>
                        <span className="star_box">
                            <img src={starIcon} alt="" />
                        </span>
                    </div>
                    <div className="user_info">
                        <span className="profile_icon">
                            <img src={Avtar} alt="" />
                        </span>
                        <span className="name">Mario Rafael Juárez González</span>
                        <span className="verify">
                            <img src={rightIcon} alt="" />
                        </span>
                    </div>
                </div>

                <div className="bottom_bar">
                    <div className="user_details">
                        <div className="info_icon">
                            <img src={universityIcon1} alt="" />
                            <img src={universityIcon2} alt="" />
                        </div>
                        <div className="user_desc">
                            <p>
                                <strong>USAC</strong>, Ciencias Médicas -{" "}
                                <strong>URL</strong>, Ciencias económicas y
                                empresariales
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Card;
