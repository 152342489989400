import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import './style.scss'

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="page-not-found">
            <h1>404</h1>
            <h3>Page Not Found</h3>
            <span>The resource requested could not be found on this server!</span>
            <Button type="primary" onClick={()=>navigate(-1)}>Go Back To Previous Page</Button>
        </div>
    )
}

export default PageNotFound;