import { Link, NavLink, useNavigate } from "react-router-dom";
import profile from "../../assets/icons/profile.svg";
import { PlusOutlined } from "@ant-design/icons";
import { ShowNotification } from "../../helpers";
import logo from "../../assets/icons/logo.svg";
import { Auth } from "../../config/firebase";
import { Button, Dropdown } from "antd";
import { signOut } from "firebase/auth";
import "./style.scss";
import { PATH_LIST } from "../../constants/paths";
import { dropdown_arrow, hamburger } from "../../assets";
import { useState } from "react";
import AddEvolutionModal from "../Modals/AddEvolutionModal";
import profile_icon from '../../assets/icons/profile-icon.svg';
import bellNotification_icon from '../../assets/icons/bellNotification.svg';
import shield_icon from '../../assets/icons/shield.svg';
import clear_session_icon from '../../assets/icons/clearSession.svg';
import ContactUsFormModal from "../Modals/ContactUsFormModal";
import HamburgerFooter from "../Common/HamburgerFooter";
import { useDispatch } from "react-redux";
import { getAllNotification } from "../../store/slices/emailNotification";
import SearchItem from "../Common/SearchItem";

const Header = () => {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const dispatch = useDispatch();

	const handleSignOut = () => {
		try {
			signOut(Auth);
			localStorage.clear();
			navigate(PATH_LIST.USER.COMMON);
			ShowNotification({
				message: "Signout successfully",
				type: "success",
			});
		} catch (error) {
			console.error("Error occurred during signout:", error);
		}
	};

	const items = [
		{
			label: (
				<div className="user_menu_item">
					<div className="icon">
						<img src={profile_icon} alt="profile_icon" />
					</div>
					<p>Ver mi perfil</p>
				</div>
			),
			key: "0",
			onClick: () => navigate(PATH_LIST.USER.USER_PROFILE)
		},

		{
			label: (
				<div className="user_menu_item">
					<div className="icon">
						<img src={bellNotification_icon} alt="notification_icon" />
					</div>
					<p>Notificaciones</p>
				</div>
			),
			key: "2",
			onClick: () => {
				navigate(PATH_LIST.USER.EMAIL_NOTIFICATION);
				dispatch(getAllNotification());
			}
		},

		{
			label: (
				<div className="user_menu_item">
					<div className="icon">
						<img src={shield_icon} alt="shield_icon" />
					</div>
					<p>Seguridad y privacidad</p>
				</div>
			),
			key: "4",
		},

		{
			label: (
				<div className="user_menu_item">
					<div className="icon">
						<img src={clear_session_icon} alt="clear_session_icon" />
					</div>
					<p onClick={() => handleSignOut()}>Cerrar sesión</p>
				</div>
			),
			key: "6",
		},
	];


	const HamburgerItems = [
		{
			label: <h3>Acerca de</h3>,
			key: "0",
			disabled: true,
		},
		{
			label: <p>¿Qué es Saikit?</p>,
			key: "2",
			onClick: () => navigate(PATH_LIST.USER.COMMON)
		},
		{
			label: <p>Nosotros</p>,
			key: "3",
			onClick: () => navigate(PATH_LIST.USER.ABOUT_US)
		},
		{
			label: <p>Metodología</p>,
			key: "4",
			onClick: () => navigate(PATH_LIST.USER.METHOLOGY),
		},

		{
			label: <p>Anuncios</p>,
			key: "5",
			onClick: () => navigate(PATH_LIST.USER.UPDATES)
		},


		{
			type: "divider",
		},
		{
			label: <h3>Soporte</h3>,
			key: "6",
			disabled: true,
		},
		{
			label: <p>Material para profesores</p>,
			key: "8",
			onClick: () => navigate(PATH_LIST.USER.TEACHER_MATERIAL),
		},
		{
			label: <p>Preguntas frecuentes</p>,
			key: "9",
			onClick: () => navigate(PATH_LIST.USER.FAQ),
		},
		{
			type: "divider",
		},
		{
			label: <h3>Contáctanos</h3>,
			key: "10",
			disabled: true,
		},
		{
			label: <p>Contáctanos</p>,
			key: "12",
			onClick: () => setIsModalVisible(true)
		},

		{
			className: "hamburger_footer",
			label: <HamburgerFooter />,
			key: "13",
		}
	];
	return (
		<>
			<header className="header-container after_login">
				<div className="container">
					<div className="inner_wrap">
						<div className="left_col">
							<div className="logo">
								<Link to={PATH_LIST.USER.HOME}>
									<img src={logo} alt="logo" />
								</Link>
							</div>
						</div>

						<div className="center_col">
							<SearchItem />
						</div>

						<div className="right_col">
							<div className="navigation">
								<NavLink to={PATH_LIST.USER.HOME}>Mi “U”</NavLink>
								<NavLink to={PATH_LIST.USER.PROFESSOR_LIST}>Profesores</NavLink>
							</div>
							<div className="create-evolution">
								<Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>
									Crear evaluación
								</Button>
							</div>
							<div className="user-dropdown">
								<Dropdown
									overlayClassName="header-profile-dropdown"
									placement="bottomRight"
									menu={{ items }}
									trigger={["click"]}
								>
									<a onClick={(e) => e.preventDefault()}>
										<div className="dropdown_wrap">
											<span className="indicator has_notification"></span>
											{/* this class "has_notification" toggle when notification is there or not */}
											<div className="profile-icon">
												<div className="avatar">
													<img className="avatar" src={profile} alt="" />
												</div>
												<p className="user-name">Mario</p>
											</div>
											<div className="icon_box FaAngleDown">{dropdown_arrow}</div>
										</div>
									</a>
								</Dropdown>
							</div>
							{/* <div className="hamburger" >{hamburger}</div> */}
							<Dropdown
								overlayClassName="header-hamburger-dropdown"
								placement="bottomRight"
								menu={{ items: HamburgerItems }}
								trigger={["click"]}
							>
								<div className="hamburger">
									<a onClick={(e) => e.preventDefault()}>
										{hamburger}
									</a>
								</div>
							</Dropdown>
						</div>
					</div>
				</div>
			</header>

			{/* {isModalOpen && */}
			<AddEvolutionModal
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
			/>
			{/* } */}

			{/* {isModalVisible && */}
			<ContactUsFormModal
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
			/>
			{/* } */}
		</>
	);
};
export default Header;
