export const PATH_LIST = {
    // STATIC: { HOME: "/static/home" },
    USER: {
        COMMON: "/",
        ABOUT_US: "/about-us",
        HOME: "/home",
        UPDATES: "/updates",
        UPDATE_ITEM: "/update/:id",
        LOGIN: "/login",
        ONBOARD: `/onboard`,
        PROFILE_UPLOADATION: '/user-profile-upload',
        EMAIL_NOTIFICATION: '/email-notification',
        USER_PROFILE: '/user-profile',
        PROFESSOR_LIST: '/professor-list',
        REGISTER: "/register",
        VERIFY_EMAIL: "/verify-email",
        FORGOT_PASSWORD: "/forgotPassword",
        PROFILE: "/profile",
        PROFESSOR: "/professor/:id",
        COURSE: "/course/:id",
        COURSE_PROFESSOR: "/course/:id/professor/:key",
        USER: "/user/:id",
        METHOLOGY: '/methology',
        EVALUATION_FACTORS: '/evaluation-factors',
        TEACHER_MATERIAL: '/teacher-material',
        FAQ: '/faq',
        PAGE_NOT_FOUND: "*",
    }
}