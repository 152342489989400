import React from 'react'
import FormButton from '../../../components/Button/FormButton';

const CommunitySection = () => {
    return (
        <div className='container semi_container'>
            <div className='cta_wrap'>
                <div className='sec_head'>
                    <h2 className='title'>Anuncio o artículo destacado</h2>
                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
                <div className='btn_wrap'>
                    <FormButton
                    className='primary_alt'
                        type='primary'
                        title="Leer más"
                       
                    />
                </div>
            </div>
        </div>
    );
}

export default CommunitySection