import { Button } from 'antd';
import React from 'react'
import { useDispatch } from 'react-redux';
import { addEvolution } from '../../../../store/slices/evolutionSlice';

const SixthPhase = ({ next, starIcon, emoji_five_star, edit_icon, emoji_four_star, anonymous_primary, setThankYouModal, opinion, selectedLabels, setCurrent, setSelectedLabels, setOpinion }) => {
    const dispatch = useDispatch()
    const handleSubmitCall = ()=> {
        const payload= {

        }

        dispatch(addEvolution({data : payload}))
        setThankYouModal(true)
        setCurrent(0)
        setSelectedLabels([])
        setOpinion('')
    }

    return (
        <div className="playarea_modal filter_evolution">
            <div className="playarea_inner ">
                <div className="comm_filter_area top_filter_bar">
                    <div className="top_filter_inner">
                        <div className="sec_head">
                            <h2 className="title">
                                Resumen de <br /> evaluación
                            </h2>
                        </div>
                        <div className="filter_rating_wrapper">
                            <div className="rating_wrap">
                                <span className="rat_box">4.5</span>
                                <span className="star_box">
                                    <img src={starIcon} alt="" />
                                </span>
                            </div>
                            <div className="result">
                                <p>Calificación promedio</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="comm_filter_area filter_data_list">
                    <div className="inner_container">
                        <div className="data_list_wrapper">
                            <ul className="data_list">
                                <li className="data_list_item">
                                    <div className="filter_info">
                                        <p>Calidad de enseñanza</p>
                                    </div>
                                    <div className="filter_widgets">
                                        <div className="emoji_icon_wrapper">
                                            <div className="emoji_wrap">
                                                {emoji_five_star}
                                            </div>
                                            <p>Excelente</p>
                                        </div>
                                        <div className="filter_edit">
                                            <div className="edit_icon">
                                                {edit_icon}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="data_list_item">
                                    <div className="filter_info">
                                        <p>Criterio de calificación</p>
                                    </div>
                                    <div className="filter_widgets">
                                        <div className="emoji_icon_wrapper">
                                            <div className="emoji_wrap">
                                                {emoji_four_star}
                                            </div>
                                            <p>Equilibrado</p>
                                        </div>
                                        <div className="filter_edit">
                                            <div className="edit_icon">
                                                {edit_icon}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="data_list_item">
                                    <div className="filter_info">
                                        <p>Nivel de dificultad</p>
                                    </div>
                                    <div className="filter_widgets">
                                        <div className="emoji_icon_wrapper">
                                            <div className="emoji_wrap">
                                                {emoji_four_star}
                                            </div>
                                            <p>Equilibrado</p>
                                        </div>
                                        <div className="filter_edit">
                                            <div className="edit_icon">
                                                {edit_icon}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="data_list_item">
                                    <div className="filter_info">
                                        <p>Accesibilidad</p>
                                    </div>
                                    <div className="filter_widgets">
                                        <div className="emoji_icon_wrapper">
                                            <div className="emoji_wrap">
                                                {emoji_four_star}
                                            </div>
                                            <p>Equilibrado</p>
                                        </div>
                                        <div className="filter_edit">
                                            <div className="edit_icon">
                                                {edit_icon}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="data_list_item">
                                    <div className="filter_info">
                                        <p>Capacidad de mentoría e inspiración</p>
                                    </div>
                                    <div className="filter_widgets">
                                        <div className="emoji_icon_wrapper">
                                            <div className="emoji_wrap">
                                                {emoji_five_star}
                                            </div>
                                            <p>Excelente</p>
                                        </div>
                                        <div className="filter_edit">
                                            <div className="edit_icon">
                                                {edit_icon}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="option_wrapper">
                            <div className="top_row ">
                                <div className="title_edit">
                                    <div className="sec_head">
                                        <h4 className="title">Opinión</h4>
                                    </div>
                                    <div className="filter_edit">
                                        <div className="edit_icon">
                                            {edit_icon}
                                        </div>
                                    </div>
                                </div>

                                <div className="about_title">
                                    <div className="anonymous_comment">
                                        <div className="anonymous_icon">
                                            {anonymous_primary}
                                        </div>
                                        <h6 className="title">Comentario anónimo</h6>
                                    </div>
                                </div>

                            </div>

                            <div className="comment_area">
                                <p className="box">{opinion}</p>
                            </div>
                        </div>

                        <div className="option_wrapper">
                            <div className="top_row ">
                                <div className="title_edit">
                                    <div className="sec_head">
                                        <h4 className="title">Etiquetas</h4>
                                    </div>
                                    <div className="filter_edit">
                                        <div className="edit_icon">
                                            {edit_icon}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="selected_list_wrap">
                                {
                                    selectedLabels?.length > 0 && selectedLabels?.map((label, index)=> {
                                        return(
                                            <p className={`item ${label?.category === "POSITIVE" ? 'positive' : 'nagative'}`} key={index}>{label?.name}</p>
                                        )
                                    })
                                }
                            </div>

                        </div>


                        <div className="r_side btn_wrap btn_grid_2">
                            <Button className="primary_v2" type="primary">
                                Cancelar
                            </Button>
                            <Button className="primary" type="primary" onClick={() => handleSubmitCall()}>
                                Enviar evaluación
                            </Button>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    )
}

export default SixthPhase;
