import footerLogo from '../../assets/icons/footer-logo.svg'
import facebookIcon from '../../assets/icons/footer-facebook-icon.svg'
import twitterIcon from '../../assets/icons/footer-twitter-icon.svg'
import instaIcon from '../../assets/icons/footer-insta-icon.svg'
import './style.scss'
import { Link } from 'react-router-dom'
import { PATH_LIST } from '../../constants/paths'
import ResponsiveMenu from '../ResponsiveMenu'


const Footer = () => {
    return (
        <>
            <footer className="footer-container">
                <div className='container semi_container'>
                    <div className="footer-top">
                        <div className="logo">
                            <img src={footerLogo} alt="" />
                        </div>
                        <div className="right">
                            <div className="links">
                                <div className="link">
                                    <h6 className='title'>Acerca de</h6>
                                    <div className="nav">
                                        <Link to={PATH_LIST.USER.ABOUT_US}>Nosotros</Link>
                                        <Link to={PATH_LIST.USER.METHOLOGY}>Metodología</Link>
                                        <Link to={PATH_LIST.USER.UPDATES}>Anuncios</Link>
                                    </div>
                                </div>
                                <div className="link">
                                    <h6 className='title'>Soporte</h6>
                                    <div className="nav">
                                        <Link to={PATH_LIST.USER.TEACHER_MATERIAL}>Material para profesores</Link>
                                        <Link to ={PATH_LIST.USER.FAQ}>Preguntas frecuentes</Link>
                                    </div>
                                </div>
                                <div className="link">
                                    <h6 className='title'>Contacto</h6>
                                    <div className="nav">
                                        <p>info@saikit.com</p>
                                        <p>(502) 2343 - 2435</p>
                                    </div>

                                    <div className="icons">
                                        <div className='social_link'>
                                            <a href='#0' className='fb'>
                                                <img src={facebookIcon} alt="" />
                                            </a>
                                        </div>
                                        <div className='social_link'>
                                            <a href='#0' className='twitter'>
                                                <img src={twitterIcon} alt="" />
                                            </a>
                                        </div>
                                        <div className='social_link'>
                                            <a href='#0' className='insta'>
                                                <img src={instaIcon} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        
                            
                        </div>

                    </div>
                    <div className='footer-bottom'>
                        <div className="footer-right">
                            <p>Saikit ® Todos los derechos reservados | 2021</p>
                        </div>
                        <div className="footer-left"><p>Políticas de privacidad</p></div>
                    </div>
                </div>
            </footer>
            <ResponsiveMenu />

        </>
    );
};
export default Footer;
