// termsAndConditionsSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import executeHttp from "../../services/api";
import { API_URL } from "../../constants/api-url";

const initialState = {
	termsAndConditions: null,
	loading: false,
	error: null,
};

export const fetchTermsAndConditions = createAsyncThunk("termsAndConditions", async () => {
	try {
		const response = await executeHttp({
			method: "GET",
			endpoint: `${API_URL.GENERAL.TNC}`,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
});

const termsAndConditionsSlice = createSlice({
	name: "termsAndConditions",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTermsAndConditions.fulfilled, (state, action) => {
			state.termsAndConditions = action.payload.termsAndConditions;
		});
		builder.addCase(fetchTermsAndConditions.rejected, (state, action) => {
			state.User = action.payload;
		});
	},
});

export default termsAndConditionsSlice.reducer;
