import staticCard from "../../../assets/images/stratic-card.png";
import likeIcon from "../../../assets/icons/like-icon.svg";
import smileEmoji from "../../../assets/icons/smile-emoji.svg";
import { doodle_info_icon } from "../../../assets";
import gsap from 'gsap';
import react, { useEffect, useRef } from "react";

const RecommendedProfessor = () => {

    const btnRefs = useRef([]);
    const fadeIn = useRef();
 
   

    useEffect(() => {
        
        gsap.fromTo(".fade_in", {
            delay:2,
            y:200,
            opacity:0
        },{
            y:0,
            opacity:1,
            duration: 1,
        });
        
        gsap.to(btnRefs.current, {
            delay:2,
            y:0,
            opacity:1,
            stagger:0.5,
        });


    }, []);
    
    return (

        <div className="container">

            <div className="inner_wrap" >
                <div className="img_wrapper doodle_wrap fade_in">
                    <div className="img_wrap">
                        <img src={staticCard} alt="" />
                    </div>
                    <div className="doodle btn_doodle" ref={(el) => (btnRefs.current[0] = el)}>
                        <span>Profesor recomendado</span>
                    </div>
                    <div className="doodle emoji_doodle" ref={(el) => (btnRefs.current[1] = el)}>
                        <div className="emoji_doodle_img">
                            <img src={smileEmoji} alt="" />
                        </div>
                        <div className="doodle_info">
                            <h6>Es genial</h6>
                            <p> 
                                <span>
                                    {doodle_info_icon}
                                </span>
                                <span> 5 Estrellas</span>
                            </p>
                        </div>
                    </div>
                    <div className="doodle like_doodle" ref={(el) => (btnRefs.current[2] = el)}>
                      <div className="emoji_doodle_img">
                            <img src={likeIcon} alt="" />
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    );
};
export default RecommendedProfessor;
