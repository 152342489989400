import React from "react";
import { fb, instagram, twitter } from "../../../assets";

const HamburgerFooter = () => {
     return (
        <div className="hamburger_footer_wrapper">
            <div className="title_wrap">
                <p>info@saikit.com</p>
            </div>
            <div className="social_link_wrapper">
                <a
                    className="social_links"
                    href="https://www.facebook.com/"
                    target="_blank"
                    without
                    rel="noreferrer"
                >
                    {fb}
                </a>
                <a
                    className="social_links"
                    href="https://twitter.com/"
                    target="_blank"
                    without
                    rel="noreferrer"
                >
                    {twitter}
                </a>
                <a
                    className="social_links"
                    href="https://www.instagram.com/"
                    target="_blank"
                    without
                    rel="noreferrer"
                >
                   {instagram}
                </a>
            </div>
        </div>
    );
};

export default HamburgerFooter;
